import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_WORK_LIST, SAVE_UPDATE_INITIATOR } from "../../store/constant";
import {
  ButtonNextCreateinitiator,
  CheckboxInputLifting,
  CommentsStyle,
  LiftingShifting,
  WorkListStyle,
} from "../../styles/CreateInitiator.style";
import { Field, Form, Formik } from "formik";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  clearInitiatorData,
  createInitiatorToggle,
  saveInitiatorData,
  setCurrentStep,
} from "../../store/Slice/formStepperSlice";
import styled from "styled-components";

const checkboxData = [
  {
    id: 1,
    description: "Earthing is provided at site during Arc Welding?",
  },
  {
    id: 2,
    description: "Availability of necessary firefighting arrangement",
  },
  {
    id: 3,
    description: "Welding cable connection with lugs and insulated",
  },
  // ... other checkbox objects
];

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 8px;
`;

const CheckboxInput = styled.input`
  margin-right: 8px;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #2d3e50;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
  cursor: pointer;

  &:checked {
    border-color: #2d3e50;
    background-color: #2d3e50;
  }

  &:checked::before {
    content: "";
    display: block;
    width: 6px;
    height: 10px;
    border-right: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    transform: rotate(45deg);
    margin-left: 4px;
  }
`;

const CheckboxLabel = styled.label`
  font-weight: 500;
  cursor: pointer;
`;

const ColdWorkModel = ({
  isLifting,
  workListId,
  isSubmitBtn,
  selectedText,
}) => {
  const { workListLoading, workList, initiatorData, currentStep } = useSelector(
    (state) => state.formStepper
  );

  const dispatch = useDispatch();
  const [checkboxValues, setCheckboxValues] = useState(
    initiatorData ? initiatorData.workDescriptionRequests : []
  );
  const [comment, setComment] = useState([]);
  useEffect(() => {
    if (workListId) {
      if (selectedText === "Confined Space Entry (CSE)") {
        dispatch({ type: GET_WORK_LIST, payload: 2 });
      }
      if (selectedText === "Work at height (WAH)") {
        dispatch({ type: GET_WORK_LIST, payload: 3 });
      }
      if (selectedText === "Electrical Permit (EP)") {
        dispatch({ type: GET_WORK_LIST, payload: 4 });
      }
      if (selectedText === "Excavation Certificate (EC)") {
        dispatch({ type: GET_WORK_LIST, payload: 5 });
      }
    }
  }, [workListId]);

  const handleSubmit = (values) => {
    if (initiatorData) {
      // const updated = [...initiatorData.workDescriptionRequests];
      const updated = [];
      updated.push(...checkboxValues);
      const updatedInitiator = {
        ...initiatorData,
        workDescriptionRequests: updated,
      };
      dispatch(saveInitiatorData(updatedInitiator));

      if (isSubmitBtn.length === currentStep - 1) {
        dispatch({ type: SAVE_UPDATE_INITIATOR, payload: updatedInitiator });
        dispatch(clearInitiatorData());
        // dispatch(setCurrentStep(1))
      } else {
        dispatch(setCurrentStep(currentStep + 1));
      }
      setCheckboxValues([]);
    }
  };

  const initialValues = {
    // workDescriptionRequests: [],
  };

  const handleCheckboxChange = (id, answer) => {
    const existingValueIndex = checkboxValues.findIndex((val) => val.id === id);

    if (existingValueIndex !== -1) {
      // Toggle the checkbox value
      const existingValue = checkboxValues[existingValueIndex];
      if (existingValue.answer === answer) {
        // Uncheck the checkbox
        setCheckboxValues((prevValues) => [
          ...prevValues.slice(0, existingValueIndex),
          ...prevValues.slice(existingValueIndex + 1),
        ]);
      } else {
        // Update the checkbox value
        setCheckboxValues((prevValues) => [
          ...prevValues.slice(0, existingValueIndex),
          { id, answer },
          ...prevValues.slice(existingValueIndex + 1),
        ]);
      }
    } else {
      // Add new checkbox value
      setCheckboxValues((prevValues) => [...prevValues, { id, answer }]);
    }
  };

  return (
    <>
      <div>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ handleChange, values }) => (
            <Form>
              <Modal.Header className={"border-0 px-0"}>
                <Modal.Title className={"w-100 px-0 "}>
                  <div className={"hot_worl_heading d-block w-100 border-0"}>
                    {selectedText && selectedText
                      ? selectedText
                      : "please selected work"}
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {isLifting ? (
                  <>
                    <LiftingShifting>
                      <div className={"form_main_lifting"}>
                        <div className={"container"}>
                          <div className={"single_select"}>
                            <h5>1. Type of lifiting</h5>
                            <select
                              name="typeOfLifting"
                              onChange={handleChange}
                              value={values.typeOfLifting}
                              id="typeOfLifting"
                            >
                              <option value="1">select lifting</option>
                              <option value="2">Lifting 1</option>
                            </select>
                          </div>
                          <div className={"single_select comment"}>
                            <h5>2. Maximum Operating Radius</h5>
                            <input
                              type="text"
                              name={"operatingComments"}
                              value={values.operatingComments}
                              placeholder={"Enter your comments"}
                            />
                          </div>
                          <div className={"single_select comment mt-3"}>
                            <h5>3. Need more permit time?</h5>
                            <CheckboxInputLifting
                              className={"hb-colour-options"}
                            >
                              <div>
                                <input
                                  type="radio"
                                  name="radio-group"
                                  id="yes"
                                  value="Yes"
                                />
                                <label htmlFor="yes">Yes</label>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  name="radio-group"
                                  id="no"
                                  value="No"
                                />
                                <label htmlFor="no">No</label>
                              </div>
                            </CheckboxInputLifting>
                            <input
                              type="text"
                              name={"operatingComments"}
                              value={values.operatingComments}
                              placeholder={"Enter your comments"}
                            />
                          </div>
                        </div>
                        <div className={"container"}>
                          <div className={"update_request"}>
                            <button>Update Request</button>
                            <button>Permit increase request</button>
                          </div>
                        </div>
                      </div>
                    </LiftingShifting>
                  </>
                ) : (
                  <div>
                    {workListLoading && workListLoading ? (
                      "loading..."
                    ) : (
                      <WorkListStyle>
                        {workList &&
                          workList.map((item) => (
                            <div key={item.id}>
                              <div
                                className={
                                  "d-flex justify-content-between py-2"
                                }
                              >
                                <label
                                  className={"d-block"}
                                  htmlFor={`checkbox-${item.id}`}
                                >
                                  {item.id}) {item.description}
                                </label>
                                <div className={"d-flex"}>
                                  <CheckboxWrapper>
                                    <CheckboxInput
                                      type="checkbox"
                                      id={`checkbox-${item.id}-yes`}
                                      checked={checkboxValues.some(
                                        (val) =>
                                          val.id === item.id &&
                                          val.answer === "yes"
                                      )}
                                      onChange={() =>
                                        handleCheckboxChange(item.id, "yes")
                                      }
                                    />
                                    <CheckboxLabel
                                      htmlFor={`checkbox-${item.id}-yes`}
                                    >
                                      Yes
                                    </CheckboxLabel>
                                  </CheckboxWrapper>
                                  <CheckboxWrapper>
                                    <CheckboxInput
                                      type="checkbox"
                                      id={`checkbox-${item.id}-no`}
                                      checked={checkboxValues.some(
                                        (val) =>
                                          val.id === item.id &&
                                          val.answer === "no"
                                      )}
                                      onChange={() =>
                                        handleCheckboxChange(item.id, "no")
                                      }
                                    />
                                    <CheckboxLabel
                                      htmlFor={`checkbox-${item.id}-no`}
                                    >
                                      No
                                    </CheckboxLabel>
                                  </CheckboxWrapper>
                                </div>
                              </div>
                            </div>
                          ))}
                        <CommentsStyle>
                          <div>
                            <label htmlFor="comment">Additional Comments</label>
                            <input
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                              id={"comment"}
                              type="text"
                              placeholder={"Enter your comments"}
                            />
                          </div>
                        </CommentsStyle>
                      </WorkListStyle>
                    )}
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer className={"border-0"}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    const updated = [];
                    updated.push(...checkboxValues);
                    const updatedInitiator = {
                      ...initiatorData,
                      workDescriptionRequests: updated,
                    };
                    dispatch(saveInitiatorData(updatedInitiator));
                    dispatch(setCurrentStep(currentStep - 1));
                  }}
                >
                  Back
                </Button>
                <ButtonNextCreateinitiator type={"submit"}>
                  {isSubmitBtn.length === currentStep - 1
                    ? "Submit for Approval"
                    : "Next"}
                </ButtonNextCreateinitiator>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ColdWorkModel;
