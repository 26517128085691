import {keyframes, styled} from "styled-components";
import {Modal} from "react-bootstrap";

const slideIn = keyframes`
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  `;
export const CreatePermitStyle = styled(Modal)`
    .modal-dialog {
      margin-right: -50px;
      animation: ${slideIn} 0.3s forwards;
      margin-top:10px;
      margin-bottom:10px;
      .modal-content{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  ::-webkit-scrollbar-thumb{
    background-color: #2D3E50;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #CECECE;
    border-radius: 10px;
  }
  *::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
  }
  *{
    scrollbar-width: auto;
    scrollbar-color: #8f54a0 #ffffff;
  },
    .modal-content {
      opacity: 0;
      animation: fadeIn 0.3s forwards;
    }
    &.show .modal-dialog {
      margin-right: 0px;
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `;

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  //margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
  display: inline-block;
`;

export const CheckboxInput = styled.input`
  //display: none;
  
    position: relative;
    border: 2px solid #2D3E50;
    border-radius: 20px;
    background: none;
    cursor: pointer;
    line-height: 0;
    margin: -3px .4em 0 0;
    outline: 0;
    padding: 0 !important;
    vertical-align: text-top;
    height: 20px;
    width: 20px;
    -webkit-appearance: none;
    opacity: .5;

  &:hover {
    opacity: 1;
  }

  &:checked {
    background-color: #2D3E50;
    opacity: 1;
  }

  &:before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 5px;
    height: 10px;
    border: solid #FFF;
    border-width: 0 2px 2px 0;
    margin: -1px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%);
    z-index: 2;
  }
  &:disabled {
    opacity: 0.5;
  }
`;

export const Checkmark = styled.span`
  // display: inline-block;
  // width: 20px;
  // height: 20px;
  // border-radius: 50%;
  // border: 2px solid #ccc;
  // margin-right: 10px;
  // position: relative;
  // transition: background-color 0.3s, border-color 0.3s;
  //
  // &::after {
  //   content: '';
  //   display: ${({ isChecked }) => (isChecked ? 'block' : 'none')};
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  //   width: 10px;
  //   height: 10px;
  //   border-radius: 50%;
  //   background-color: #4caf50;
  }
`;


export const CheckboxLabel = styled.span``;
export const SelectDepartment = styled.div`
display: flex;
flex-direction: row;align-items: center;
`;


export const CheckboxInputDepart = styled.input`
  // Update the styling for radio buttons
  position: relative;
  border: 2px solid #2D3E50;
  border-radius: 50%; // Change to a circle shape
  background: none;
  cursor: pointer;
  line-height: 0;
  margin: -3px .4em 0 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  opacity: .5;

  &:hover {
    opacity: 1;
  }

  &:checked {
    background-color: #2D3E50;
    opacity: 1;
  }

  &:before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 10px; // Adjust the size of the radio dot
    height: 10px; // Adjust the size of the radio dot
    background-color: #FFF; // Color for the radio dot
    border-radius: 50%; // Make it circular
    transform: translate(-50%, -50%);
    z-index: 2;
  }
  &:disabled {
    opacity: 0.5;
  }
`;

export const RadioCheckmark = styled.span`
  // You can remove this styled component if not needed for radio buttons
`;

export const CheckboxLabelDepartment = styled.span`
  // Style for the label text for radio buttons
  margin-left: 10px; // Add some spacing between the radio button and label text
`;

// export const SelectDepartment = styled.div`
  // Style for the entire radio button group
  //display: flex;
  //flex-direction: row; // Arrange radio buttons horizontally
  //align-items: center; // Vertically align radio buttons and labels
// `;
