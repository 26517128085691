import styled from "styled-components"  

export const MainNav = styled.div`
    
  display: flex;
  justify-content: space-between;
  background: #FFFFFF;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.08);
  padding-left: 25px;
  padding-right: 25px;
  align-items: center;
  height: 80px;
`
export const NavIcon = styled.button`
  flex: 0 0 2%;
  padding:15px;
  background-color: transparent;
  border: 0;
`
export const NavUser = styled.div`
flex: 0 0 25%
`
export const UserWord = styled.div`
    color: #2D3E50;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    font-family: Josefin Sans;
`
export const UserSelect = styled.div`
  flex: 0 0 20%;
  text-align: end;
  .location_select{
    #react-select-2-listbox{
      overflow: hidden;
    }
  }
`

export const UserIcon = styled("div")({
    display: "flex",
    flex: "0 0 11%",
    alignItems: "center",
    justifyContent: "space-between",
    padding:"30px",
    "div":{
        margin: "0 8px",
    },
    ".notification":{
        ".link":{
            display: "block",
            background:"#F7F7F7",
            height: "45px",
            width: "45px",
            lineHeight: "45px",
            textAlign: "center",
            borderRadius: "45px",
        }
    }

})
