import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Avatar, UserTabelBtn } from '../../styles/UserTabel.style';

function ModalPerson(props) {
  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Body>
        <div className='container'>
          <div className='row'>
            <div className='col-md-3'>
              <Avatar>
              {/* <div className='main-body-avatar'></div> */}
              </Avatar>
            </div>
            <div className='col-md-9'>
              <div className='name-main'>
                <div className='name-art'>
                  <p>Name</p>
                </div>
                <div className='d-flex'>
                  <div>
                    <h4>Manas Bhowmik</h4>
                  </div>
                  <div className='btn-main mx-3'>
                  <UserTabelBtn className='btn'>Custodian </UserTabelBtn>
                  </div>
                </div>
              </div>
              <div className='bottom-text mt-5'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='text-filed mb-3'>
                      <p>Email Address</p>
                      <h6>manasbhowmik@hotmail.com</h6>
                    </div>
                    <div className='text-filed mb-3'>
                      <p>EID</p>
                      <h6>#125489625</h6>
                    </div>
                    <div className='text-filed mb-3'>
                      <p>Shift time</p>
                      <h6>6:00 AM - 2:00 PM</h6>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='text-filed mb-3'>
                      <p>Phone</p>
                      <h6>97892 98325</h6>
                    </div>
                    <div className='text-filed mb-3'>
                      <p>Department</p>
                      <h6>Mining</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalPerson;
