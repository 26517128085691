import React, {useEffect} from 'react'
import NavDashboard from '../components/Navbar'
import Drawer from '../components/Drawer'
import UserSearchBar from '../utils/UserSearchBar'
import {DetailsBtnPermit, ParmitTable, StatusPermit} from "../styles/Permit.style";
import {NotificationTable, StatusNotification} from "../styles/Notification.style";
import {useDispatch, useSelector} from "react-redux";
import {NOTIFICATION_REQUEST} from "../store/constant";

const Notification = () => {
    const dispatch = useDispatch();
    const not = useSelector((state)=>state.notification);
    const data = not?.notifications;
    const notification = Array.isArray(data) && [...data].reverse();

    useEffect(()=>{
        dispatch({type:NOTIFICATION_REQUEST});
    },[])
    return (
        <div className='d-flex'>
            <div>
                <Drawer/>
            </div>
            <div className={"w-100"} style={{background:"#F7F7F7"}}>
                <NavDashboard page={"Notification"}/>
                <div className={"container pt-5"}>
                    <NotificationTable>
                        <div className='notification-table'>
                            {!notification ?
                            <p className={"p-4"}>
                                Data Not Found
                            </p>:
                                <table className='table'>
                                    <tbody>
                                    {notification && 
                                        notification.map((res)=>{
                                            return(
                                                <tr key={res.id} className='content' >
                                                    <td>
                                                    <div className={"d-flex main_notification"}>
                                                        <div>
                                                            <StatusNotification/>
                                                        </div>
                                                        <div>
                                                            <h4 className={"mb-0"}>
                                                                {res.message}
                                                            </h4>

                                                        </div>
                                                    </div>
                                                    </td>
                                                </tr>
                                            )
                                        })

                                    }
                                    </tbody>
                                </table>
                            }

                        </div>
                    </NotificationTable>
                </div>
            </div>
        </div>
    );
}

export default Notification
