import React, { useEffect } from "react";
import {
  Avatar,
  UserTabelBtn,
  UsersTabel,
  ShifttypeLabelStyle,
} from "../styles/UserTabel.style";
import { SelectSearch, UserForm } from "../styles/UsersSearchTopBar";
import { ReactComponent as Pencil } from "../assets/pencil.svg";
import { ReactComponent as Delete } from "../assets/delete.svg";
import userProfile from "../assets/userProfile.jpg";
import MyVerticallyCenteredModal from "./models/AddUserModal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GET_USER_FOR_UPDATE } from "../store/constant";
import {
  deleteUserToggle,
  userAddUpdateToggle,
} from "../store/Slice/usersSlice";
import config from "../config";
import DeleteUserModel from "./models/DeleteUserModel";

const UserTabel = () => {
  const [value, setValue] = React.useState("");
  const [userId, setUserId] = React.useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const users = useSelector((state) => state.users.usersList);
  console.log(users, "users");
  const { upateUserGet, userModelToggle, deleteToggle } = useSelector(
    (state) => state.users
  );

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  // useEffect(() => {
  //     if (upateUserGet) {
  //
  //     }
  // }, [upateUserGet]);

  const handleDelete = (id) => {
    if (id) {
      setUserId(id);
      dispatch(deleteUserToggle());
    }
  };

  return (
    <div className="container">
      <UsersTabel>
        <div className="d-flex py-3 px-3">
          <h3 className="p-2">Users</h3>
          <div className="">
            {/* <UserForm>
              <SelectSearch
                value={value}
                onChange={handleChange}
                name="cars"
                id="cars"
              >
                <option value="">All Users View</option>
                <option value="One">One</option>
                <option value="Tow">Tow</option>
                <option value="Three">Three</option>
              </SelectSearch>
            </UserForm> */}
            {/* <option value="">Filter By</option>
                <option value="Issuer">Issuer</option>
                <option value="Co-ordinator">Co-ordinator</option>
                <option value="Custodian">Custodian</option> */}
          </div>
        </div>
        <div className="user-table">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Company</th>
                <th scope="col">Email</th>
                <th scope="col">Department</th>
                <th scope="col">Shifting Time</th>
              </tr>
            </thead>
            <tbody>
              <>
                {users && users.length > 0 ? (
                  users.map((i, index) => {
                    return (
                      <tr key={i.id} className="content">
                        <th className={"d-flex"}>
                          <Avatar>
                            <img
                              src={i.photo ? i.photo : userProfile}
                              alt="profile ptw"
                              srcSet=""
                            />
                          </Avatar>
                          <div className="main-username px-2">
                            <div className="main-text">
                              <h4 className={"mb-0 fs-6 fw-bold"}>
                                {i.name ? i.name : "-"}
                              </h4>
                            </div>
                            <div className="main-button">
                              <UserTabelBtn className="btn widthsame">
                                {config.role.issuerRole === i.roleId && (
                                  <>Issuer</>
                                )}
                                {config.role.custodianRole === i.roleId && (
                                  <>Custodian</>
                                )}
                                {config.role.adminRole === i.roleId && (
                                  <>Admin</>
                                )}
                                {config.role.coOrdinatorRole === i.roleId && (
                                  <>Co-Ordinator</>
                                )}
                              </UserTabelBtn>
                            </div>
                          </div>
                        </th>
                        <td>
                          <div>{i.emailId ? i.emailId : "-"}</div>
                        </td>
                        <td>{i.departmentName ? i.departmentName : "-"}</td>
                        {i.departmentShiftResponses &&
                        i.departmentShiftResponses.length > 0
                          ? i.departmentShiftResponses.map(
                              (department, key) => {
                                return (
                                  <>
                                    <td key={key + 1}>
                                      <div className="main-last-field d-flex align-items-center justify-content-between">
                                        <div className="box-details ">
                                          <div
                                            className={
                                              "d-flex align-items-center"
                                            }
                                          >
                                            {department && department.startTime
                                              ? department.startTime + " "
                                              : "-"}
                                            -
                                            {department && department.endTime
                                              ? " " + department.endTime
                                              : "-" + "_"}
                                            <>
                                              <ShifttypeLabelStyle>
                                                {department &&
                                                department.shiftType
                                                  ? " " + department.shiftType
                                                  : "-" + "_"}
                                              </ShifttypeLabelStyle>
                                            </>
                                          </div>
                                        </div>
                                        <div className="main-tool"></div>
                                        <div className="box-details box-btn d-flex align-items-center">
                                          <div className={"mx-3"}>
                                            <button
                                              className="btn-danger btn user_btn"
                                              onClick={() =>
                                                navigate(`/user/${i.id}`)
                                              }
                                            >
                                              Details
                                            </button>
                                          </div>
                                          <div>
                                            <button
                                              className="btn"
                                              onClick={() => {
                                                if (i.id) {
                                                  dispatch({
                                                    type: GET_USER_FOR_UPDATE,
                                                    payload: i.id,
                                                  });
                                                  dispatch(
                                                    userAddUpdateToggle()
                                                  );
                                                }
                                              }}
                                            >
                                              <Pencil />
                                            </button>
                                            <button
                                              className="btn"
                                              onClick={() => handleDelete(i.id)}
                                            >
                                              <Delete />
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </>
                                );
                              }
                            )
                          : ""}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="4" style={{ borderBottom: "none" }}>
                      No data Found
                    </td>
                  </tr>
                )}
              </>
            </tbody>
          </table>
        </div>
      </UsersTabel>
      {upateUserGet && <MyVerticallyCenteredModal />}
      {userId && <DeleteUserModel show={deleteToggle} id={userId} />}
    </div>
  );
};
export default UserTabel;
