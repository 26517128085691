import React from 'react';
import Select, { components } from 'react-select';
import locationIcon from "../assets/location-icon.svg"
import {useSelector} from "react-redux";

// const options = [
//     {
//         label: 'Option 1',
//         value: 0,
//         image: locationIcon,
//     },
//     {
//         label: 'Option 2',
//         value: 1,
//         image: locationIcon,
//     },
// ];

const { SingleValue, Option } = components;

const IconSingleValue = (props) => (
    <SingleValue {...props}>
        <img
            src={props.data.image}
            style={{ height: '16px', width: '16px', borderRadius: '50%', marginRight: '10px' }}
        />
        {props.data.label}
    </SingleValue>
);

const IconOption = (props) => (
    <Option {...props}>
        <img
            src={props.data.image}
            style={{ height: '16px', width: '16px', borderRadius: '50%', marginRight: '10px' }}
        />
        {props.data.label}
    </Option>
);


const customStyles = {
    option: (provided,state) => ({
        ...provided,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        // backgroundColor: state.isFocused ? '#F7F7F7' : 'transparent',
        // color:'#000',
    }),
    singleValue: (provided) => ({
        ...provided,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        // backgroundColor: '#F7F7F7',
        // color: '#000',
        margin:0,
    }),
};

const CustomSelect = ({handleLocationChange}) => {

    const {adminData} = useSelector(state => state.signIn)

    const options = adminData.adminLocations.map((location) => ({
        label: location.name,
        value: location.id,
        image: locationIcon,
    }));

    return (
        <Select
            className={"location_select"}
            styles={customStyles}
            components={{ SingleValue: IconSingleValue, Option: IconOption }}
            options={options}
            onChange={handleLocationChange}
            defaultValue={options[0]}
            theme={(theme) => ({
                ...theme,
                borderRadius: '10px',
                overflow:"hidden",
                borderColor:"transparent",
                colors: {
                    ...theme.colors,
                    primary25: `rgba(45, 62, 80, 0.30)`,
                    primary: '#2D3E50',
                },
            })}
        />
    );
};

export default CustomSelect;
