import {Button, Modal} from "react-bootstrap";
import React, {useContext, useState} from "react";
import {
    AlertCancelBtn,
    AlertMessage,
    AlertModelStyle,
    AlertSubmitBTN,
    CancelBtn,
    cancelBtn
} from "../../styles/AlertModel.style";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../Context/AuthContext";

const LogoutModel = ({show,handleClose}) => {
    const navigate = useNavigate();
    const { user, login, logout } = useContext(AuthContext);
    const clearAuth = () => {
        logout()
        handleClose()
        localStorage.clear()
    };
    return (
        <>
            <Modal show={show} style={{padding:"50px 0"}}>
                <Modal.Body >
                    {/*<div className={'text-center d-block'}>*/}
                        <AlertMessage>
                            Are you sure you want to log out?
                        </AlertMessage>
                    {/*</div>*/}
                </Modal.Body>
                <Modal.Footer className={"border-0 justify-content-between mx-3 mb-3 mt-2"}>
                    <AlertCancelBtn onClick={handleClose}>
                        Close
                    </AlertCancelBtn>
                    <AlertSubmitBTN onClick={()=>clearAuth()}>
                        Log Out
                    </AlertSubmitBTN>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default LogoutModel