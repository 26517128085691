import styled from "styled-components";

export const Base = styled.div`
  display: flex;
`;

export const SearchForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  margin:0 10px;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  background-color: ${(props) =>
    props.config?.backgroundColor || "transparent"};`;
    
export const SearchInput = styled.input`
    background-color: transparent;
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 17px;  
    border: none;
    outline: none;
    color: ${(props) => props.config?.color || "#ffffff"};
    font-size: 17px;
    width:100%;
    transition: all 0.3s ease-in-out;
    padding: 12px;
    padding-left: 16px;
    margin: 2px;
    font-weight: ${(props) =>
        props.config?.fontWeight ? props.config.fontWeight : "normal"};
        ::placeholder {
            color: ${(props) => props.config?.placeholderColor || "#ffffff"};
            font-size: 14px;
            font-family: 'Josefin Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 17px;
            font-weight: ${(props) =>
                props.config?.placeholderWeight
                ? props.config.placeholderWeight
                : "#fff"};
            }
`;

export const SVG = styled.svg`
fill: ${(props) => props.config?.searchButtonColor || "#000"};
`;

export const IconContainer = styled(Base)`
align-items: center;
justify-content: center;
background-color: ${(props) =>
    props.config?.searchButtonContainerColor || "#fff"};
    border-radius: 50px;
    padding-right: 16px;
  &:hover ${SVG} {
    fill: ${(props) => props.config?.searchButtonColorHover || "#000"};
  }
`;




