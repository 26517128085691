import styled, { css, keyframes } from "styled-components";
import FilteredPropsInputField from "../utils/FilteredPropsInputField"
import { ErrorMessage, Field } from "formik";
export const PageWrapper = styled.section`
  &,
  & * {
    box-sizing: border-box;
    display: block;
  }

  hr {
    display: block;
    border: none;
    border-top: 1px solid lightgrey;

    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  font-family: system-ui;
  font-size: 1rem;
  line-height: 1.5rem;
  max-width: 35em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5rem;
  padding: 1rem 0.75rem;
  border: 1px solid lightgrey;
  border-radius: 4px;
`;

export const CodeWrapper = styled.pre`
  font-family: monospace;
  font-size: 0.875rem;
  line-height: 1.25rem;
  background-color: hsl(210, 4%, 96%);
  overflow: auto;
  padding: 0.75rem;
  margin: 0;
  border-radius: 4px;

  & strong {
    margin-top: 1.5rem;

    &:first-child {
      margin-top: 0;
    }
  }
`;

export const Title = styled.h1`
  font-size: 1rem;
  line-height: 1.25rem;
  margin-top: 0;
`;

export const Label = styled.label`
  margin-top: 8px;
`;

export const Input = styled(FilteredPropsInputField)`
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  margin-top: 0.5rem;
  padding: 0.75rem 0.75rem;

  &:focus,
  &:active {
    box-shadow: rgb(210, 213, 217) 0px 0px 2px 1px,
      rgb(227, 230, 232) 0px 0px 0px 3px;
    border: 1px solid rgb(26, 33, 43);
    outline: none;
  }

  /* Autocomplete styles in Chrome*/
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: white;
    border: 1px solid lightgrey;
    box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: black;
  }

  ${({ valid }) =>
    valid &&
    css`
      border: 1px solid rgb(0, 156, 38);

      &:focus,
      &:active {
        border: 1px solid rgb(0, 156, 38);
        box-shadow: rgb(106, 237, 97) 0px 0px 2px 1px,
          rgb(177, 247, 160) 0px 0px 0px 3px;
        outline: none;
      }

      /* Autocomplete styles in Chrome*/
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: 1px solid rgb(0, 156, 38);
      }
    `}

  ${({ error }) =>
    error &&
    css`
      border: 1px solid rgb(191, 49, 12);
      outline: none;

      &:focus,
      &:active {
        box-shadow: rgb(244, 129, 116) 0px 0px 2px 1px,
          rgb(251, 178, 174) 0px 0px 0px 3px;
        border: 1px solid rgb(191, 49, 12);
        outline: none;
      }

      /* Autocomplete styles in Chrome*/
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: 1px solid rgb(191, 49, 12);
      }
    `}
`;

export const StyledInlineErrorMessage = styled.div`
    color: rgb(120, 27, 0);
    display: block;
    padding: 6px 0px;
    margin-top: 0px;
    white-space: pre-line;
    font-size: 14px;
`;

export const Submit = styled.button`
//   width: 100%;
//   margin-top: 1.5rem;

//   background-color: rgb(24, 81, 187);
//   display: block;
//   text-align: center;
//   font-size: 1rem;
//   line-height: 1.5rem;
//   font-style: normal;
//   font-weight: 700;
//   height: 3rem;
//   white-space: nowrap;
//   color: rgb(232, 243, 255) !important;
//   padding: 0.5rem 1rem;

  background: linear-gradient(118.68deg, #E4385E -20.39%, #FF9C61 94.52%);
  border-radius: 26.5px;
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  border:none;
  padding:12px 48px;
  display:block;

  &:active,
  &:focus,
  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: pointer;
    background-color: rgb(163, 168, 173);
    box-shadow: none;
    color: rgb(255, 255, 255) !important;

    &:hover,
    &:focus {
      cursor: not-allowed;
    }
  }
`;




// ADD EDIT Form Elements
export const FormMain = styled("div")({
  "form": {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "space-between",
    "div": {
      display: "block",
      "label": {
        display: "block",
        fontFamily: 'Josefin Sans',
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "12px",
        color: "#969FA7",
        paddingLeft: "4px",
      },
      "select":{
        width: "210px",
        display: "block",
        border: 0,
        borderBottom:"1px solid #2D3E50",
        "&:focus-visible":{
          outline: 0,
        },
      },
    },
    ".shift_type":{
        width:"100%",
        "div":{
          width:"100%",
          "label":{
            display: "block",
          },
          "select":{
            width: '100%',
          }
        }
    }
  },
});

export const InputContainer = styled.div`
  margin-bottom: 40px;
  display:block;
`;

export const Error = styled(ErrorMessage)`
  color: red;
  margin-top: 0.5rem;
`;

export const Select = styled(Field)`
  padding: 0.5rem 0;
  //border: 0;
  borderBottom:"1px solid #2D3E50",
`;

// Choose Color style
export const RadioGroup = styled.div`
  display: flex !important;
  flex-wrap: wrap;
`;

export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const ChooseColor = styled.div`
  font-family: 'Josefin Sans';
  font-weight: 600;
  font-size: 14px;
  color: #2D3E50;
  width: 100%;
  padding-bottom: 10px;
`;

export const RadioInput = styled.input`
appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 22px;
  height: 22px;
  border: 2px solid ${props => props.color};
  border-radius: 50%;
  margin-right: 0.5rem;
  position: relative;
  transition: all 0.1s ease-in-out;
  background-color: ${props => props.color};
  cursor: pointer;
  &:checked {
    border-color: #000;
  }

  &:checked::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1rem;
    height: 1rem;
    background-color: ${props => props.color};
    border-radius: 50%;
  }

`;



export const ShiftAddEditStyle = styled.div`
  padding: 25px 0px;
  padding-top: 15px;
  .heading_shift{
    padding-left: 8px;
  }
  .comman_input{
    div{
      
      label{
        display: block;
        color: rgba(45, 62, 80, 0.50);
        font-weight: 600;
      }
      select{
        border: 0;
        border-bottom: 1px solid #2D3E50;
        width: 100%;
        color: #2D3E50;
        font-weight: 600;
      }
        select:focus-visible {
          outline: none;
        }
      
    }
  }
  .two_box{
    display: flex;
    justify-content: space-between;
    width: 100%;
    div{
      padding: 0 8px;
      width: 100%;
    }
  }
`