import React, { useEffect, useState } from "react";
import NavDashboard from "../components/Navbar";
import Drawer from "../components/Drawer";
import {
  EmailAddress,
  NameMain,
  UpdateBtn,
  UserDetailsAvatar,
  UserInfo,
  UsersDetails,
} from "../styles/UserDetails.style";
import { ReactComponent as Pencil } from "../assets/pencil.svg";
import UserDetailsImg from "../assets/avatar_user_details.png";
import { UserTabelBtn } from "../styles/UserTabel.style";
import { ShiftBadge } from "../styles/Shift.style";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_USER_FOR_DETAILS,
  GET_USER_FOR_UPDATE,
  UPDATE_USER,
} from "../store/constant";
import { clearSetUser, userAddUpdateToggle } from "../store/Slice/usersSlice";
import MyVerticallyCenteredModal from "../utils/models/AddUserModal";
import config from "../config";

const UserDetails = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const { userLoading, userModelToggle, upateUserGet } = useSelector(
    (state) => state.users
  );
  const userDetails = upateUserGet;
  console.log(userDetails);
  //   useEffect(() => {
  //     console.log("new user", userId);
  //     dispatch({ type: GET_USER_FOR_DETAILS, payload: userId });
  //   }, [userId]);

  useEffect(() => {
    dispatch(clearSetUser());
  }, []);

  useEffect(() => {
    if (!userDetails) {
      dispatch({
        type: GET_USER_FOR_UPDATE,
        payload: userId,
      });
    }
  }, [userDetails]);

  return (
    <div className="d-flex">
      <div>
        <Drawer />
      </div>
      <div className={"w-100"}>
        <NavDashboard page={"User"} />
        <UserInfo>
          <>
            <div className={"text-end"}>
              <button
                className="btn me-3 mt-3"
                onClick={() => {
                  dispatch(userAddUpdateToggle());
                }}
              >
                <Pencil />
              </button>
            </div>
            <div className={"d-flex main_info"}>
              <div className={"p-0 m-0 avatar"}>
                <UserDetailsAvatar>
                  <img src={userDetails ? userDetails.photo : ""} alt="" />
                </UserDetailsAvatar>
              </div>
              <UsersDetails>
                <span>Name</span>
                <NameMain>
                  <h3>{userDetails ? userDetails.name : "-"}</h3>
                  <div className="btn-main m-0 p-0 mx-3">
                    {config.role.issuerRole === userDetails?.roleId && (
                      <UserTabelBtn className="btn px-3 py-2">
                        Issuer{" "}
                      </UserTabelBtn>
                    )}
                    {config.role.coOrdinatorRole === userDetails?.roleId && (
                      <UserTabelBtn className="btn px-3 py-2">
                        Co-Ordinator
                      </UserTabelBtn>
                    )}
                    {config.role.custodianRole === userDetails?.roleId && (
                      <UserTabelBtn className="btn px-3 py-2">
                        Custodian
                      </UserTabelBtn>
                    )}
                  </div>
                </NameMain>
                <div className={"d-flex"}>
                  <EmailAddress>
                    <div>
                      <span>Email Address</span>
                      <p>{userDetails ? userDetails.emailId : "-"}</p>
                    </div>
                    <div>
                      <span>EID</span>
                      <p>{userDetails ? userDetails.empId : "-"}</p>
                    </div>
                    {userDetails
                      ? userDetails.departmentShiftResponses &&
                        userDetails.departmentShiftResponses.length > 0 &&
                        userDetails.departmentShiftResponses.map(
                          (singleShift) => {
                            return (
                              <div>
                                <span className={"p-0 d-block"}>
                                  Shift time
                                </span>
                                <p className={"d-inline"}>
                                  {singleShift && singleShift.startTime
                                    ? `${singleShift.startTime} - ${singleShift.endTime}`
                                    : "-"}
                                </p>
                                <ShiftBadge color="#92FDFF">
                                  {singleShift ? singleShift.shiftType : "-"}
                                </ShiftBadge>
                              </div>
                            );
                          }
                        )
                      : "-"}
                  </EmailAddress>
                  <EmailAddress>
                    <div>
                      <span>Phone</span>
                      <p>{userDetails ? userDetails.phone : "-"}</p>
                    </div>
                    <div>
                      <span>Department</span>
                      <p>{userDetails ? userDetails.departmentName : "-"}</p>
                    </div>
                  </EmailAddress>
                </div>
              </UsersDetails>
            </div>
          </>
        </UserInfo>
      </div>
      {userModelToggle && <MyVerticallyCenteredModal />}
    </div>
  );
};

export default UserDetails;
