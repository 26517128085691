import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_WORK_LIST } from "../store/constant";
import {
  ButtonNextCreateinitiator,
  CheckboxInputLifting,
  CommentsStyle,
  LiftingShifting,
  WorkListStyle,
} from "../styles/CreateInitiator.style";
import { Field, Form, Formik } from "formik";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  createInitiatorToggle,
  saveInitiatorData,
  setCurrentStep,
} from "../store/Slice/formStepperSlice";
import styled from "styled-components";

const LiftingScreen = ({ isSubmitBtn }) => {
  const dispatch = useDispatch();
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [comment, setComment] = useState([]);
  const { initiatorData, currentStep } = useSelector(
    (state) => state.formStepper
  );

  const handleSubmit = (values) => {
    console.log(values);
    const liftingData = {
      typeOfLifting: parseInt(values.typeOfLifting),
      operatingRadius: values.operatingRadius,
      boomLength: values.boomLength,
      jibLength: values.jibLength,
      jibOffset: values.jibOffset,
      attachments: values.attachments,
      counterWeights: values.counterWeights,
      verticalClearance: values.verticalClearance,
      groundStability: values.groundStability,
      undergroundUtilities: values.undergroundUtilities,
      woodenStoppers: values.woodenStoppers,
      weightLoad: values.weightLoad,
      weightAccessories: values.weightAccessories,
    };
    const updatedInitiator = { ...initiatorData, ...liftingData };
    dispatch(saveInitiatorData(updatedInitiator));
    if (initiatorData && initiatorData.selectedTexts) {
      dispatch(setCurrentStep(currentStep + 1));
    }
  };

  const initialValues = {
    operatingRadius: initiatorData ? initiatorData.operatingRadius : "",
    typeOfLifting: initiatorData ? initiatorData.typeOfLifting : "",
    boomLength: initiatorData ? initiatorData.boomLength : "",
    jibLength: initiatorData ? initiatorData.jibLength : "",
    jibOffset: initiatorData ? initiatorData.jibOffset : "",
    attachments: initiatorData ? initiatorData.attachments : "",
    counterWeights: initiatorData ? initiatorData.counterWeights : "",
    verticalClearance: initiatorData ? initiatorData.verticalClearance : "",
    powerLines: initiatorData ? initiatorData.powerLines : "",
    groundStability: initiatorData ? initiatorData.groundStability : "",
    undergroundUtilities: initiatorData
      ? initiatorData.undergroundUtilities
      : "",
    woodenStoppers: initiatorData ? initiatorData.woodenStoppers : "",
    weightLoad: initiatorData ? initiatorData.weightLoad : "",
    weightAccessories: initiatorData ? initiatorData.weightAccessories : "",
  };

  return (
    <>
      <div>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ handleChange, values }) => (
            <Form>
              <Modal.Header className={"border-0 px-0"}>
                <Modal.Title className={"w-100 px-0 "}>
                  <div className={"hot_worl_heading d-block w-100 border-0"}>
                    Lifting and shifting
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <LiftingShifting>
                  <div className={"form_main_lifting"}>
                    <div className={"container"}>
                      <div className={"single_select"}>
                        <h5>1. Type of lifiting</h5>
                        <select
                          name="typeOfLifting"
                          onChange={handleChange}
                          value={values.typeOfLifting}
                          id="typeOfLifting"
                        >
                          <option value="1">select lifting</option>
                          <option value="2">Lifting 1</option>
                        </select>
                      </div>
                      <div className={"single_select comment"}>
                        <h5>2. Maximum Operating Radius</h5>
                        <input
                          type="text"
                          name={"operatingRadius"}
                          value={values.operatingRadius}
                          onChange={handleChange}
                          placeholder={"Enter Maximum Operating Radius"}
                        />
                      </div>
                      <div className={"single_select comment"}>
                        <h5>3. Main boom length</h5>
                        <input
                          type="text"
                          name={"boomLength"}
                          value={values.boomLength}
                          onChange={handleChange}
                          placeholder={"Enter Main boom length"}
                        />
                      </div>
                      <div className={"single_select comment"}>
                        <h5>4. Jib length</h5>
                        <input
                          type="text"
                          name={"jibLength"}
                          value={values.jibLength}
                          onChange={handleChange}
                          placeholder={"Enter Jib length"}
                        />
                      </div>
                      <div className={"single_select comment"}>
                        <h5>5. Jib Offset</h5>
                        <input
                          type="text"
                          name={"jibOffset"}
                          value={values.jibOffset}
                          onChange={handleChange}
                          placeholder={"Enter Jib Offset"}
                        />
                      </div>
                      <div className={"single_select comment"}>
                        <h5>6. Attachments (Luffing jib, Super lift)</h5>
                        <input
                          type="text"
                          name={"attachments"}
                          value={values.attachments}
                          onChange={handleChange}
                          placeholder={
                            "Enter Attachments (Luffing jib, Super lift)"
                          }
                        />
                      </div>
                      {/* <div className={"single_select comment"}>
                        <h5>7. Counterweights required</h5>
                        <input
                          type="text"
                          name={"counterWeights"}
                          value={values.counterWeights}
                          onChange={handleChange}
                          placeholder={"Enter Counterweights required"}
                        />
                      </div> */}
                      <div className={"single_select comment mt-3"}>
                        <h5>7. Counterweights required</h5>
                        <CheckboxInputLifting className={"hb-colour-options"}>
                          <div>
                            <input
                              type="radio"
                              onChange={handleChange}
                              name="counterWeights"
                              id="yes"
                              value="Yes"
                            />
                            <label htmlFor="yes">Yes</label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              onChange={handleChange}
                              name="counterWeights"
                              id="no"
                              value="No"
                            />
                            <label htmlFor="no">No</label>
                          </div>
                        </CheckboxInputLifting>
                      </div>
                      <div className={"single_select comment"}>
                        <h5>8. Vertical Clearance between boom Hook & load</h5>
                        <input
                          type="text"
                          name={"verticalClearance"}
                          value={values.verticalClearance}
                          onChange={handleChange}
                          placeholder={
                            "Enter Vertical Clearance between boom Hook & load"
                          }
                        />
                      </div>
                      <div className={"single_select comment"}>
                        <h5>9. Distance from power lines</h5>
                        <input
                          type="text"
                          name={"powerLines"}
                          value={values.powerLines}
                          onChange={handleChange}
                          placeholder={"Enter Distance from power lines"}
                        />
                      </div>
                      <div className={"single_select comment"}>
                        <h5>10. Ground Stability</h5>
                        <input
                          type="text"
                          name={"groundStability"}
                          value={values.groundStability}
                          onChange={handleChange}
                          placeholder={"Enter Ground Stability"}
                        />
                      </div>
                      <div className={"single_select comment"}>
                        <h5>11. Underground utilities</h5>
                        <input
                          type="text"
                          name={"undergroundUtilities"}
                          value={values.undergroundUtilities}
                          onChange={handleChange}
                          placeholder={"Enter Underground utilities"}
                        />
                      </div>
                      <div className={"single_select comment"}>
                        <h5>12. Wooden Stoppers or mats needed?</h5>
                        <input
                          type="text"
                          name={"woodenStoppers"}
                          value={values.woodenStoppers}
                          onChange={handleChange}
                          placeholder={"Enter Wooden Stoppers or mats"}
                        />
                      </div>
                      <div className={"single_select comment"}>
                        <h5>13. Weight of load</h5>
                        <input
                          type="text"
                          name={"weightLoad"}
                          value={values.weightLoad}
                          onChange={handleChange}
                          placeholder={"Enter Weight of load"}
                        />
                      </div>
                      <div className={"single_select comment"}>
                        <h5>14. Total weight of accessories</h5>
                        <input
                          type="text"
                          name={"weightAccessories"}
                          value={values.weightAccessories}
                          onChange={handleChange}
                          placeholder={"Enter Total weight of accessories"}
                        />
                      </div>
                    </div>
                    <div className={"container"}>
                      <div className={"update_request"}>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          Update Request
                        </button>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          Permit increase request
                        </button>
                      </div>
                    </div>
                  </div>
                </LiftingShifting>
              </Modal.Body>
              <Modal.Footer className={"border-0"}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    dispatch(setCurrentStep(currentStep - 1));
                  }}
                >
                  Back
                </Button>
                <ButtonNextCreateinitiator type={"submit"}>
                  {isSubmitBtn.length === currentStep - 1
                    ? "Submit for Approval"
                    : "Next"}
                </ButtonNextCreateinitiator>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
export default LiftingScreen;

const checkboxData = [
  {
    id: 1,
    description: "Earthing is provided at site during Arc Welding?",
  },
  {
    id: 2,
    description: "Availability of necessary firefighting arrangement",
  },
  {
    id: 3,
    description: "Welding cable connection with lugs and insulated",
  },
  // ... other checkbox objects
];

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 8px;
`;

const CheckboxInput = styled.input`
  margin-right: 8px;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #2d3e50;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
  cursor: pointer;

  &:checked {
    border-color: #2d3e50;
    background-color: #2d3e50;
  }

  &:checked::before {
    content: "";
    display: block;
    width: 6px;
    height: 10px;
    border-right: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    transform: rotate(45deg);
    margin-left: 4px;
  }
`;

const CheckboxLabel = styled.label`
  font-weight: 500;
  cursor: pointer;
`;

// const ColdWorkModel = ({ isLifting,workListId,isSubmitBtn }) => {
//     const dispatch = useDispatch();
//     const [checkboxValues, setCheckboxValues] = useState([]);
//     const [comment, setComment] = useState([]);
//     const { workListLoading, workList, initiatorData ,currentStep} = useSelector((state) => state.formStepper);
//     useEffect(() => {
//         if (workListId) {
//             dispatch({ type: GET_WORK_LIST, payload: workListId });
//         }
//     }, [workListId]);
//
//
//
//     return (
//         <>
//
//                             <Modal.Body>
//                                 {isLifting ?
//                                     <>
//
//                                     </>
//                                     :<div>
//                                         {workListLoading && workListLoading ? (
//                                             'loading...'
//                                         ) : (
//                                             <WorkListStyle>
//                                                 {workList && workList.map((item) => (
//                                                     <div key={item.id}>
//                                                         <div className={'d-flex justify-content-between py-2'}>
//                                                             <label className={"d-block"}
//                                                                    htmlFor={`checkbox-${item.id}`}>{item.id}) {item.description}</label>
//                                                             <div className={"d-flex"}>
//                                                                 <CheckboxWrapper>
//                                                                     <CheckboxInput
//                                                                         type="checkbox"
//                                                                         id={`checkbox-${item.id}-yes`}
//                                                                         checked={checkboxValues.some(
//                                                                             (val) => val.id === item.id && val.answer === 'yes'
//                                                                         )}
//                                                                         onChange={() => handleCheckboxChange(item.id, 'yes')}
//                                                                     />
//                                                                     <CheckboxLabel
//                                                                         htmlFor={`checkbox-${item.id}-yes`}>Yes</CheckboxLabel>
//                                                                 </CheckboxWrapper>
//                                                                 <CheckboxWrapper>
//                                                                     <CheckboxInput
//                                                                         type="checkbox"
//                                                                         id={`checkbox-${item.id}-no`}
//                                                                         checked={checkboxValues.some(
//                                                                             (val) => val.id === item.id && val.answer === 'no'
//                                                                         )}
//                                                                         onChange={() => handleCheckboxChange(item.id, 'no')}
//                                                                     />
//                                                                     <CheckboxLabel
//                                                                         htmlFor={`checkbox-${item.id}-no`}>No</CheckboxLabel>
//                                                                 </CheckboxWrapper>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 ))}
//                                                 <CommentsStyle>
//                                                     <div>
//                                                         <label htmlFor="comment">Comments</label>
//                                                         <input value={comment} onChange={(e) => setComment(e.target.value)}
//                                                                id={"comment"} type="text" placeholder={"Enter your comments"}/>
//                                                     </div>
//                                                 </CommentsStyle>
//                                             </WorkListStyle>
//                                         )}
//                                     </div>
//                                 }
//
//                             </Modal.Body>
//
//         </>
//     );
// };
//
//
// export default ColdWorkModel;
