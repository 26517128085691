import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
  body {
    font-family: 'Josefin Sans', sans-serif;
  }
  div#root {
  height: 100vh;
}

body.loader {
  background: linear-gradient(274.68deg, #e4385e 0%, #ff9c61 100%);
}

body.loader::after {
  content: '';
  background-image: url('./assets/bg-tree.png');
  position: absolute;
  width: 100%;
  height: 225px;
  background-size: contain;
  max-width: 525px;
  background-repeat: no-repeat;
  bottom: 0;
  right: 50%;
}
`;
 
export default GlobalStyle;