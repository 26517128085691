import React, {useEffect} from 'react'
import NavDashboard from '../components/Navbar'
import Drawer from '../components/Drawer'
import { Nav, Tab, Tabs } from 'react-bootstrap'
import DepartmentCompo from '../components/DepartmentCompo'
import Shift from "../components/Shift"
import { DepartmentTabs } from '../styles/Department.style'
import {useDispatch, useSelector} from "react-redux";
import {DEPARTMENT_REQUEST, GET_ALL_SHIFT} from "../store/constant";
const Department = () => {

  const dispatch = useDispatch();
  const {allShift} = useSelector(state => state.department);
  // useEffect(()=>{
  //   if(!allShift) {
  //     dispatch({type: GET_ALL_SHIFT})
  //   }
  // },[])
  return (
    <div className='d-flex'>
      <div>
        <Drawer />
      </div>
      <div className={"w-100"}>
        <NavDashboard page={"Department & Shift"}/>
        <div className={"w-100"}>
          <DepartmentTabs>
            <Tabs defaultActiveKey='first' style={{ border: "none" }}>
              <Tab eventKey='first' title='Department'>
                <DepartmentCompo />
              </Tab>
              <Tab eventKey='second' title='Shift'>
                <Shift />
              </Tab>
            </Tabs>
          </DepartmentTabs>

        </div>
      </div>
    </div>
  )
}

export default Department
