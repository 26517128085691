import React, {createContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {clearUserRoleData} from "../store/Slice/signInSlice";
import {useNavigate} from "react-router-dom";
import config from "../config";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const dispatch = useDispatch()
    const adminData = useSelector((state)=>state.signIn.adminData);

    const login = (userData) => {
        setUser(userData);
    };

    useEffect(()=>{
        if(adminData){
            setUser(adminData)
        }
    },[adminData])

    const logout = () => {
        setUser(null);
        dispatch(clearUserRoleData())
        localStorage.clear()
        window.location.replace(`/sign-in`);
    };

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
