import {
  CreateIntiatorStyle,
  DetailsBtnPermit,
  ParmitTable,
  PermitRaised,
  StatusPermit,
} from "../styles/Permit.style";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  GET_ALL_PERMIT,
  GET_INITIATOR_BYID,
  GET_PERMIT_BY_ID,
} from "../store/constant";
import { createInitiatorToggle } from "../store/Slice/formStepperSlice";
import CreateInitiatorModel from "./models/CreateInitiatorModel";
import config from "../config";

const CoAdPermitContent = (props) => {
  const [permitDetail, setPermitDetail] = useState(null);
  const { permitList, loading } = useSelector((state) => state.permits);
  const user = useSelector((state) => state.signIn.adminData);
  const { getInitiatorDataSuccessForDashboard } = useSelector(
    (state) => state.formStepper
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log(permitDetail);

  const filter = {
    filterBy: props && props.filter ? props.filter : "",
    searchText: "",
  };

  useEffect(() => {
    dispatch({ type: GET_ALL_PERMIT, payload: filter });
  }, [props.filter]);

  useEffect(() => {
    if (getInitiatorDataSuccessForDashboard) {
      navigate(`/initiator/${getInitiatorDataSuccessForDashboard.permitId}`);
    }
  }, [getInitiatorDataSuccessForDashboard]);

  return (
    <PermitRaised className={"container"}>
      <ParmitTable className={"row shadow-none"}>
        <div className="col-12 user-table co_ad_tabel">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Permit Number</th>
                <th scope="col">Status</th>
                <th scope="col">Authorised person name & Designation</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {/*{loading && loading && "loading..."*/}

              {/*}*/}
              {permitList && permitList.length > 0
                ? permitList.map((i) => {
                    // console.log(i, "i");
                    return (
                      <tr className="content" id={i + 1}>
                        <td>
                          {i.permitNumber && i.permitNumber
                            ? i.permitNumber
                            : "-"}
                        </td>
                        <td>
                          {i.status && i.status === "Approved" && (
                            <>
                              <StatusPermit status={"#30DBB2"} /> Approved
                            </>
                          )}
                          {i.status && i.status === "Rejected" && (
                            <>
                              <StatusPermit status={"#FF0000"} /> Rejected
                            </>
                          )}
                          {i.status && i.status === "Requested" && (
                            <>
                              <StatusPermit status={"#FF8946"} /> Requested
                            </>
                          )}
                          {i.status && i.status === "IssuerCompleted" && (
                            <>
                              <StatusPermit status={"#FF8946"} /> Issuer
                              Completed
                            </>
                          )}
                          {i.status && i.status === "CustodianApproved" && (
                            <>
                              <StatusPermit status={"#FF8946"} /> Custodian
                              Approved
                            </>
                          )}
                          {i.status && i.status === "CheckListCreated" && (
                            <>
                              <StatusPermit status={"#FF8946"} /> CheckList
                              Created
                            </>
                          )}
                          {i.status && i.status === "SubmiteForCompleted" && (
                            <>
                              <StatusPermit status={"#FF8946"} /> Submite For
                              Completed
                            </>
                          )}
                          {i.status && i.status === "In Progress" && (
                            <>
                              <StatusPermit status={"#FF8946"} /> In Progress
                            </>
                          )}
                        </td>
                        {/*<td>Mining</td>*/}
                        {/*<td>{i.dateTime && i.dateTime ? i.dateTime : '-'}</td>*/}
                        <td>
                          {i.lastUpdatedBy && i.lastUpdatedBy
                            ? i.lastUpdatedBy
                            : "-"}
                        </td>
                        <td className={"d-flex"}>
                          <DetailsBtnPermit
                            type="button"
                            onClick={() => {
                              // console.log(i.id,"ifejndsnksjmkd")
                              if (i.initiatorCreated) {
                                // navigate(`/initiator/${i.id}`)
                                dispatch({
                                  type: GET_INITIATOR_BYID,
                                  payload: i.id,
                                });
                                dispatch({
                                  type: GET_PERMIT_BY_ID,
                                  payload: i.id,
                                });
                              } else {
                                navigate(`/permit/${i.id}`);
                              }
                            }}
                          >
                            Details
                          </DetailsBtnPermit>
                          {/* {user && user.roleId === config.role.issuerRole && ( */}
                          {user &&
                            user.roleId === config.role.coOrdinatorRole && (
                              <>
                                {!i.initiatorCreated ? (
                                  <>
                                    {props.filter &&
                                      props.filter === "Requested" && (
                                        <CreateIntiatorStyle
                                          onClick={() => {
                                            setPermitDetail(i);
                                            dispatch(createInitiatorToggle());
                                          }}
                                        >
                                          Create Intiator
                                          <span>+</span>
                                        </CreateIntiatorStyle>
                                      )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                        </td>
                      </tr>
                    );
                  })
                : "No data Found"}
            </tbody>
          </table>
        </div>
      </ParmitTable>
      {permitDetail && <CreateInitiatorModel permitDetail={permitDetail} />}
    </PermitRaised>
  );
};
export default CoAdPermitContent;
