import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import createSagaMiddleware from 'redux-saga'
import usersSaga from './saga/usersSaga';
import signInSlice from "./Slice/signInSlice";
import usersSlice from "./Slice/usersSlice";
import departmentShiftSlice from "./Slice/departmentShiftSlice";
import notificationSlice from "./Slice/notificationSlice";
import permitSlice from "./Slice/permitSlice";
import helpSlice from "./Slice/helpSlice";
import issuerPermitSlice from "./Slice/issuerPermitSlice";
import formStepperSlice from "./Slice/formStepperSlice";
import departmentShiftSaga from "./saga/departmentShiftSaga";
import signInSaga from "./saga/signInSaga";
import notificationSaga from "./saga/notificationSaga";
import permitSaga from "./saga/parmitSaga";
import helpSaga from "./saga/helpSaga";
import issuerPermitSaga from "./saga/issuerPermitSaga";
import formStepperSaga from "./saga/formStepperSaga";

// Persist config for items slice
const itemsPersistConfig = {
    key: 'items',
    storage,
};
  
  // Persist config for users slice
  const usersPersistConfig = {
    key: 'signIn',
    storage,
  };

const sagaMiddleware = createSagaMiddleware()

// Create the persisted reducers here
// const persistedUsersReducer = persistReducer(usersPersistConfig, usersSlice);
// const persistedItemsReducer = persistReducer(itemsPersistConfig, itemsSlice.reducer);

const persistedUsersReducer = persistReducer(usersPersistConfig, signInSlice);
const store = configureStore({
    reducer: {
        // users: persistedUsersReducer,
        // items: persistedItemsReducer,
        signIn:persistedUsersReducer,
        users:usersSlice,
        department:departmentShiftSlice,
        notification:notificationSlice,
        permits:permitSlice,
        help:helpSlice,
        issuerPermit:issuerPermitSlice,
        formStepper:formStepperSlice,
    },
      middleware: [sagaMiddleware],
})
export default store;
export const persistor = persistStore(store)

// function* rootSaga() {
//     yield all([
//         signInSaga(),
//         usersSaga(),
//         departmentShiftSaga(),
//     ]);
// }
// sagaMiddleware.run(rootSaga)


sagaMiddleware.run(signInSaga)
sagaMiddleware.run(usersSaga)
sagaMiddleware.run(departmentShiftSaga)
sagaMiddleware.run(notificationSaga)
sagaMiddleware.run(permitSaga)
sagaMiddleware.run(helpSaga)
sagaMiddleware.run(issuerPermitSaga)
sagaMiddleware.run(formStepperSaga)
