import { createSlice } from "@reduxjs/toolkit";

const formSlice = createSlice({
  name: "formStepper",
  initialState: {
    currentStep: 1,
    steps: [
      { id: 1, label: "Step 1" },
      { id: 2, label: "Step 2" },
      { id: 3, label: "Step 3" },
    ],
    createInitiatorModel: false,
    workList: null,
    workListLoading: false,
    workListError: null,
    initiatorData: null,
    // Initiator Save Update
    initiatorLoading: false,
    initiatorError: null,
    initiatorDataSuccess: null,

    // get Initiator by id
    getInitiatorLoading: false,
    getInitiatorError: null,
    getInitiatorDataSuccess: null,
    getInitiatorDataSuccessForDashboard: null,

    // update Initiator status
    updateInitiatorLoading: false,
    updateInitiatorError: null,
    updateInitiatorData: null,
    clearInitiatorData: null,

    // Check List model
    showCheckListModel: false,
    initiatorDataForCheckList: null,
  },
  reducers: {
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    createInitiatorToggle: (state, action) => {
      console.log(state.createInitiatorModel, "initiator");
      state.createInitiatorModel = !state.createInitiatorModel;
    },

    // get List for work
    getWorkListByIdRequest: (state) => {
      state.workListLoading = !state.workListLoading;
    },
    getWorkListByIdSuccess: (state, action) => {
      state.workListLoading = !state.workListLoading;
      state.workList = action.payload;
    },
    getWorkListByIdFaield: (state, action) => {
      state.workListLoading = false;
      state.workListError = action.payload;
    },

    saveInitiatorData: (state, action) => {
      state.initiatorData = action.payload;
    },
    clearInitiatorData: (state, action) => {
      state.initiatorData = null;
    },

    // get List for work
    saveUpdateInitiatorRequest: (state) => {
      console.log(state.createInitiatorModel);
      state.initiatorLoading = !state.initiatorLoading;
    },
    saveUpdateInitiatorSuccess: (state, action) => {
      console.log(state.createInitiatorModel);
      state.initiatorLoading = !state.initiatorLoading;
      state.initiatorDataSuccess = action.payload;
      state.createInitiatorModel = false;
      state.currentStep = 1;
    },
    saveUpdateInitiatorFaield: (state, action) => {
      console.log(state.createInitiatorModel);
      state.initiatorLoading = false;
      state.initiatorError = action.payload;
      state.createInitiatorModel = false;
      state.currentStep = 1;
    },

    // get initiator By Permit ID
    getInitiatorByIdRequest: (state) => {
      state.getInitiatorLoading = !state.getInitiatorLoading;
    },
    getInitiatorByIdSuccess: (state, action) => {
      state.getInitiatorLoading = !state.getInitiatorLoading;
      state.getInitiatorDataSuccess = action.payload;
      state.getInitiatorDataSuccessForDashboard = action.payload;
    },
    getInitiatorByIdFaield: (state, action) => {
      state.getInitiatorLoading = !state.getInitiatorLoading;
      state.getInitiatorError = action.payload;
    },
    clearGetInitiatorByIdSuccess: (state, action) => {
      state.getInitiatorDataSuccessForDashboard = null;
      state.getInitiatorError = null;
    },

    // Update initiator Status
    updateInitiatorStatusRequest: (state) => {
      state.updateInitiatorLoading = !state.updateInitiatorLoading;
    },
    updateInitiatorStatusSuccess: (state, action) => {
      state.updateInitiatorLoading = !state.updateInitiatorLoading;
      state.updateInitiatorData = action.payload;
    },
    updateInitiatorStatusFaield: (state, action) => {
      state.updateInitiatorLoading = false;
      state.updateInitiatorError = action.payload;
    },
    clearInitiatorStatusData: (state) => {
      state.updateInitiatorLoading = false;
      state.updateInitiatorError = null;
      state.updateInitiatorData = null;
    },

    //check List Toggle
    createCheckListTogle: (state, action) => {
      state.showCheckListModel = !state.showCheckListModel;
    },
    //store initiaror data for Check list
    initiatorForCheckList: (state, action) => {
      state.initiatorDataForCheckList = action.payload;
    },

    // get Check List
    getCheckListRequest: (state) => {
      state.getInitiatorLoading = !state.getInitiatorLoading;
    },
    getCheckListSuccess: (state, action) => {
      state.getInitiatorLoading = !state.getInitiatorLoading;
      state.getInitiatorDataSuccess = action.payload;
    },
    getCheckListFaield: (state, action) => {
      state.getInitiatorLoading = false;
      state.getInitiatorError = action.payload;
    },
  },
});

export const {
  setCurrentStep,
  createInitiatorToggle,
  getWorkListByIdRequest,
  getWorkListByIdSuccess,
  getWorkListByIdFaield,
  saveInitiatorData,
  saveUpdateInitiatorRequest,
  saveUpdateInitiatorSuccess,
  saveUpdateInitiatorFaield,
  getInitiatorByIdRequest,
  getInitiatorByIdSuccess,
  getInitiatorByIdFaield,
  updateInitiatorStatusRequest,
  updateInitiatorStatusSuccess,
  updateInitiatorStatusFaield,
  createCheckListTogle,
  getCheckListRequest,
  getCheckListSuccess,
  getCheckListFaield,
  initiatorForCheckList,
  clearGetInitiatorByIdSuccess,
  clearInitiatorStatusData,
  clearInitiatorData,
} = formSlice.actions;
export default formSlice.reducer;
