import {ADD_UPDATE_SHIFT, DEPARTMENT_REQUEST, GET_ALL_SHIFT, GET_ROLES} from "../constant";
import {takeLatest,call, put} from "redux-saga/effects";
import {
    addUpdateShiftFailure,
    addUpdateShiftRequest, addUpdateShiftSuccess,
    departmentRequest, getAllShiftFailure,
    getAllShiftRequest, getAllShiftSuccess,
    getDepartmentFailure,
    getDepartmentSuccess, getRolesFailure, getRolesRequest, getRolesSuccess
} from "../Slice/departmentShiftSlice";
import Api from "../../api/api";
import toast from "react-hot-toast";
function* getDepartment(action){
    try {
        yield put(departmentRequest());
        const response = yield call(Api.get,"/api/getDepartments")
        console.log(response,'Department');
        yield put(getDepartmentSuccess(response && response.data))
    }catch (error){
        console.log(error)
        yield put(getDepartmentFailure(error))
    }
}

function* getAllShift(action) {
    try {
        yield put(getAllShiftRequest());
        const response = yield call(Api.get, "/api/shift/getAllShifts");
        yield put(getAllShiftSuccess(response && response.data))
    } catch (error) {
        console.log(error)
        yield put(getAllShiftFailure(error))
    }
}

function* getRoles(action) {
    try {
        yield put(getRolesRequest());
        const response = yield call(Api.get, "/api/getRoles");
        // console.log(response.data, 'response ==> api/getRoles')
        yield put(getRolesSuccess(response && response.data))
    } catch (error) {
        console.log(error)
        yield put(getRolesFailure(error))
    }
}

function* createShift(action) {
    try {
        yield put(addUpdateShiftRequest());
        const response = yield call(Api.post, "api/shift/saveShift",action.payload);
        if (response && response.status.status === 200){
            toast.success(response.status.message);
            yield put(addUpdateShiftSuccess(response))
            yield call(getAllShift)
        }else{
            yield put(addUpdateShiftFailure(response.status.message))
            toast.error( "Error saving or updating")
        }

    } catch (error) {
        toast.error( "Error saving or updating Shift");
        yield put(addUpdateShiftFailure("Error saving or updating"))
    }
}
function* departmentShiftSaga(){
    yield takeLatest(DEPARTMENT_REQUEST,getDepartment)
    yield takeLatest(GET_ALL_SHIFT,getAllShift)
    yield takeLatest(GET_ROLES,getRoles)
    yield takeLatest(ADD_UPDATE_SHIFT,createShift)
}
export default departmentShiftSaga;
