import React, { useState } from "react";
import NavDashboard from "../components/Navbar";
import Drawer from "../components/Drawer";
import {
  HelpBtn,
  HelpError,
  HelpField,
  HelpStyledForm,
  HelpTextarea,
  Message,
} from "../styles/Help.style";
import {
  ProfileAvatar,
  ProfileCamera,
  ProfileStyle,
} from "../styles/ProfileStyle.style";
import Avaar from "../assets/avatar_user_details.png";
import Camera from "../assets/profile_camera.svg";
import { useDispatch, useSelector } from "react-redux";
import config from "../config";

const Profile = () => {
  const [Img, setImg] = useState("");
  const hiddenFileInput = React.useRef(null);
  const { adminData } = useSelector((state) => state.signIn);
  console.log(adminData, "adminData");
  const handleSelectPhoto = () => {
    hiddenFileInput.current.click();
  };
  const handleChange = (e) => {
    setImg(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <div className="d-flex">
      <div>
        <Drawer />
      </div>
      <div className={"w-100"}>
        <NavDashboard page={"Profile"} />

        <div className={"container pt-3"}>
          <ProfileStyle>
            <div className={"profile_main"}>
              <div className={"avatar"}>
                <div className={"profile_upload_main"}>
                  <ProfileAvatar
                    src={Img ? Img : Avaar}
                    alt=""
                    srcSet={Img ? Img : Avaar}
                  ></ProfileAvatar>
                  <div className={"select_photo"} onClick={handleSelectPhoto}>
                    <ProfileCamera
                      src={Camera}
                      alt=""
                      srcSet={Camera}
                    ></ProfileCamera>
                    <input
                      accept="image/x-png,image/jpeg"
                      type="file"
                      className={"d-none"}
                      ref={hiddenFileInput}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <h2>{adminData.empName ? adminData.empName : "-"}</h2>
                <button>
                  {adminData && adminData ? adminData.roleName : ""}
                  {/*{adminData && adminData.roleId === config.role.adminRole &&*/}
                  {/*    <>Admin</>*/}
                  {/*}*/}
                  {/*{adminData && adminData.roleId === config.role.issuerRole &&*/}
                  {/*    <>Issuer</>*/}
                  {/*}*/}
                  {/*{adminData && adminData.roleId === config.role.custodianRole &&*/}
                  {/*    <>Custodian</>*/}
                  {/*}*/}
                  {/*{adminData && adminData.roleId === config.role.coOrdinatorRole &&*/}
                  {/*    <>Co-ordinator</>*/}
                  {/*}*/}
                </button>
              </div>
              <div className={"profileDetails"}>
                <div>
                  <p>Email</p>
                  <span>{adminData.emailId ? adminData.emailId : "-"}</span>
                </div>
                <div>
                  <p>Mobile No</p>
                  <span>
                    {adminData.empMobileNumber
                      ? adminData.empMobileNumber
                      : "-"}
                  </span>
                </div>
                <div>
                  <p>EID</p>
                  <span>{adminData.empId ? adminData.empId : "-"}</span>
                </div>
              </div>
              <div className={"description"}>
                <h4>Project Description</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  semper, nibh eget egestas feugiat, ipsum augue commodo mi, ut
                  viverra eros arcu egestas tortor.Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit. Nunc semper, nibh eget egestas
                  feugiat, ipsum augue commodo mi, ut viverra eros arcu egestas
                  tortor.Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit.
                </p>
              </div>
            </div>
          </ProfileStyle>
        </div>
      </div>
    </div>
  );
};

export default Profile;
