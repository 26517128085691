import {
  getPermiByIdtFaield,
  getPermitByIdRequest,
  getPermitByIdSuccess,
} from "../Slice/issuerPermitSlice";
import Api from "../../api/api";
import {
  GET_ALL_PERMIT,
  GET_DEPARTMENT_REQUEST_FOR_ISSUER,
  GET_INITIATOR_BYID,
  GET_PERMIT_BY_ID,
  GET_WORK_LIST,
  POST_ISSUER_PERMIT_REQUEST,
  SAVE_UPDATE_INITIATOR,
  UPDATE_INITIATOR_STATUS,
} from "../constant";
import { takeLatest, call, put } from "redux-saga/effects";
import {
  getCheckListFaield,
  getCheckListSuccess,
  getInitiatorByIdFaield,
  getInitiatorByIdRequest,
  getInitiatorByIdSuccess,
  getWorkListByIdFaield,
  getWorkListByIdRequest,
  getWorkListByIdSuccess,
  saveUpdateInitiatorFaield,
  saveUpdateInitiatorRequest,
  saveUpdateInitiatorSuccess,
  updateInitiatorStatusFaield,
  updateInitiatorStatusRequest,
  updateInitiatorStatusSuccess,
} from "../Slice/formStepperSlice";
import toast from "react-hot-toast";
import { getAllPermitRequest } from "../Slice/permitSlice";

function* getWorkList(action) {
  try {
    yield put(getWorkListByIdRequest());
    const response = yield call(
      Api.get,
      `api/initiator/getWorkDescriptionByType/${action.payload}`
    );
    // console.log(response.data,'response ==> api/initiator/getWorkDescriptionByType')
    if (response && response.data) {
      yield put(getWorkListByIdSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    yield put(getWorkListByIdFaield(error));
  }
}
function* saveUpdateInitiator(action) {
  try {
    yield put(saveUpdateInitiatorRequest());
    const response = yield call(
      Api.post,
      `api/initiator/saveOrUpdate`,
      action.payload
    );
    // console.log(response.data,'response ==> api/initiator/saveOrUpdate')
    if (response.status && response.status.status === 500) {
      toast.error("Failed to save initiator");
      yield put(saveUpdateInitiatorFaield("Failed to save initiator"));
    }
    if (response && response.status.status === 200) {
      yield put(saveUpdateInitiatorSuccess(response));
      toast.success("Initiator saved successfully");
      yield put(getAllPermitRequest("Requested"));
    }
  } catch (error) {
    console.log(error);
    yield put(saveUpdateInitiatorFaield(error));
    toast.error("Failed to save initiator");
  }
}

function* getInitiatorById(action) {
  try {
    yield put(getInitiatorByIdRequest());
    const response = yield call(Api.get, `api/initiator/get/${action.payload}`);
    // console.log(response.data,'response ==> api/initiator/get/')
    if (response && response.data) {
      yield put(getInitiatorByIdSuccess(response.data.initiatorResponse));
    }
  } catch (error) {
    console.log(error);
    yield put(getInitiatorByIdFaield(error));
  }
}

function* updateInitiatorStatus(action) {
  try {
    yield put(updateInitiatorStatusRequest());
    const response = yield call(
      Api.post,
      `api/permit/updatePermitStatus`,
      action.payload
    );
    // console.log(response.status,'response ==> api/initiator/get/')
    yield put(updateInitiatorStatusSuccess(response.status));
    if (response.status.status === 200) {
      toast.success(`${response.status.message}`);
    } else {
      toast.error(`${response.status.message}`);
    }
  } catch (error) {
    console.log(error);
    yield put(updateInitiatorStatusFaield(error));
  }
}

function* formStepperSaga() {
  yield takeLatest(GET_WORK_LIST, getWorkList);
  yield takeLatest(SAVE_UPDATE_INITIATOR, saveUpdateInitiator);
  yield takeLatest(GET_INITIATOR_BYID, getInitiatorById);
  yield takeLatest(UPDATE_INITIATOR_STATUS, updateInitiatorStatus);
}
export default formStepperSaga;
