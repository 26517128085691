const config = {
  apiUrl: process.env.REACT_APP_API_URL,
  baseUrl: process.env.REACT_BASE_URL,

  //Role
  role: {
    // IssuerADRole:4,
    // coOrdinatorAD:3,
    // custodianAD:6,
    // IssuerPdRole:2,
    // custodianPD:0,

    adminRole: 1,
    issuerRole: 2,
    coOrdinatorRole: 3,
    custodianRole: 4,
  },
};

export default config;
