import {Modal} from "react-bootstrap";
import React from "react";
import {
    AlertCancelBtn,
    AlertMessage,
    AlertSubmitBTN,
} from "../../styles/AlertModel.style";
import {useDispatch, useSelector} from "react-redux";
import {deleteUserToggle} from "../../store/Slice/usersSlice";
import {DELETE_USER} from "../../store/constant";

const DeleteUserModel = ({handleClose,id}) => {
    const dispatch = useDispatch();
    const {deleteToggle} = useSelector(state => state.users)
    const handleDelete = () => {
            if(id) {
                dispatch({type:DELETE_USER, payload: id})
            }
    };
    return (
        <>
            <Modal show={deleteToggle} style={{padding:"50px 0"}}>
                <Modal.Body >
                    {/*<div className={'text-center d-block'}>*/}
                    <AlertMessage>
                        Are you sure you want to Delete this User?
                    </AlertMessage>
                    {/*</div>*/}
                </Modal.Body>
                <Modal.Footer className={"border-0 justify-content-between mx-3 mb-3 mt-2"}>
                    <AlertCancelBtn onClick={()=>{
                        dispatch(deleteUserToggle())
                    }}>
                        Close
                    </AlertCancelBtn>
                    <AlertSubmitBTN onClick={()=>handleDelete()}>
                        Delete
                    </AlertSubmitBTN>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteUserModel