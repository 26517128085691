import {createSlice} from "@reduxjs/toolkit";

export const notificationSlice = createSlice({
    name:"notification",
    initialState:{
        loading:false,
        error:null,
        notifications:null,
    },
    reducers:{
        // notification request
        notificationRequest:(state) => {
            state.loading = true;
        },
        notificationSuccess:(state,action) => {
            state.loading = false;
            state.notifications = action.payload;
        },
        notificationFaield:(state,message)=>{
            state.loading = false;
            state.error = message
        }
    }
})

export const {
    notificationRequest,
    notificationSuccess,
    notificationFaield,
} =  notificationSlice.actions;

export default notificationSlice.reducer