import { takeLatest, call, put } from 'redux-saga/effects';
import axios from 'axios';
import {
    otpFailure,
    otpSuccess,
    signInFailure,
    signInRequest,
    signInSuccess,
} from '../Slice/signInSlice';
import {OTP_REQUEST, SIGN_IN_REQUEST} from "../constant";
import Api from "../../api/api";
import config from "../../config";

// Sign in User
function* signInRequestWacher(action) {
    try{
        yield put(signInRequest())
        const response = yield call(axios.post, `${config.apiUrl}/api/generateOTP`, action.payload);
        console.log(response);
        let otpDataRes = {...action.payload,response: response.data.data};
        yield put(signInSuccess(otpDataRes));
    }catch(error){
        console.error('Failed to add user:', error);
        yield put(signInFailure(error.response.data.message));
    }
}

// Handle OTP
function* otpWacher(action) {
    try{
        const response = yield call(Api.post, '/api/signIn', action.payload);
        if(response.data){
            localStorage.setItem("access_token",response.data.token)
        }
        yield put(otpSuccess(response.data));

    }catch(error){
        console.error('Failed to add user:', error);
        yield put(otpFailure(error.response.data.message));
    }
}


function* signInSaga() {
    yield takeLatest(SIGN_IN_REQUEST, signInRequestWacher);
    yield takeLatest(OTP_REQUEST,otpWacher)
}
export default signInSaga;