import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";

export const MainDrawer = styled.div`
  display: block;
  background: linear-gradient(118.68deg, #e4385e -20.39%, #ff9c61 94.52%);
  width: ${(props) => (props.isOpen ? "300px" : "80px")};
  position: relative;
  box-sizing: border-box;
  height: 100vh;
  z-index: 1;
`;

export const DrawerIcon = styled.img`
  width: 100%;
  padding: ${(props) => (props.isOpen ? "20px" : "8px")};
`;

export const MenuItem = styled(Link)({
  /* your menu item styles */
  /* e.g., padding, font-size, etc. */
  //d-flex text-decoration-none w-100
  /* add styles for the active menu item */
  display: "flex",
  textDecoration: " none",
  width: "100%",
  color: "#000",
  "&.active": {
    backgroundColor: "#6495f2",
    padding: "50px",
    color: "#000 !important",
    a: {
      color: "#000 !important",
    },
  },
  /* e.g., background-color, text color, etc. */
});

export const DrawerUl = styled.ul`
  list-style: none;
  margin: 10px;
  padding: 0;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-right: 0;
  z-index: 55;
`;
export const DrawerLi = styled.li`
  align-items: center;
  position: relative;
  display: flex;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: ${(props) => (props.isOpen ? "16px" : "0")};
`;
export const DrawerIcons = styled("div")({
  background: "#FFFFFF",
  borderRadius: "15px",
  width: "50px",
  height: "38px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  svg: {
    path: {
      fill: "#AD383C",
    },
  },
  ".active": {
    svg: {
      path: {
        fill: "#AD383C",
      },
    },
  },
});

export const DrawerIconName = styled.div`
  flex: 1 1 auto;
  min-width: 0;
  margin-top: 12px;
  margin-bottom: 4px;
  margin-left: 20px;
  display: flex;
  font-family: Josefin Sans;
  line-height: 100%;
`;

export const DrawerButton = styled.div`
  background: #fff;
  border-radius: ${(props) => (props.isOpen ? "60px" : "30px")};

  color: #333;
  width: ${(props) => (props.isOpen ? "90%" : "55px")};
  height: ${(props) => (props.isOpen ? "57px" : "55px")};
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
export const DrawerWord = styled.div`
  font-family: Josefin Sans;
  margin-top: 0;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color, inherit);
  margin-left: 15px;
`;
export const DrawerImg = styled.div`
  background: pink;
  border-radius: 50px;
  color: #333;
  width: 35px;
  height: 35px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -20px;
`;

export const DrawerImgIcon = styled.img`
  width: 100%;
  //margin-left:25px;
  cursor: pointer;
  height: auto;
`;
export const MainDrawerButton = styled("div")({
  position: "absolute",
  width: "100%",
  bottom: "5px",
  zIndex: "15",
  ".profile_drawer": {
    display: "flex",
    alignItems: "center",
    color: "#2D3E50",
    textDecoration: "none",
    margin: "0 25px",
    ".logOut": {
      img: {
        marginLeft: "25px",
      },
    },
    ".isOpen": {},
  },
});

// export const NavLinkStyled = styled(NavLink)`
//     display: flex;
//     width: ${props => props.isOpen ? "100%" : "auto"};
//     text-decoration: none;
//     color: #fff !important;
//     "&.active" {
//       background-color: ${props => props.isOpen ? "#F7F7F7" : "transparent"};
//       color: #000 !important;
//       border-bottom-left-radius: 15px;
//       border-top-left-radius: 15px;
//     }
//   `;

export const NavLinkStyled = styled(NavLink)`
  display: flex;
  width: ${(props) => (props.isOpen ? "100%" : "auto")};
  text-decoration: none;
  color: #fff !important;
  &.active {
    background-color: ${(props) => (props.isOpen ? "#F7F7F7" : "transparent")};
    color: #000 !important;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
  }
`;

export const BottomImgDrawer = styled.div`
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  z-index: -1;
  @media (max-width: 1440px) {
    width: ${(props) => (!props.isOpen ? "80px" : "200px")};
    margin: auto;
  }
`;
