import React, { Suspense, useContext } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import LazyLoad from "./LazyLoad";
import Dashboard from "../pages/Dashboard";
import Department from "../pages/Department";
import Users from "../pages/Users";
import { ProtectedRoute } from "./ProtectedRoute";
import UserDetails from "../pages/UserDetails";
import Permit from "../pages/Permit";
import Notification from "../pages/Notification";
import Help from "../pages/Help";
import Profile from "../pages/Profile";
import { AuthContext } from "../Context/AuthContext";
import config from "../config";
import { useSelector } from "react-redux";
import IssuerPermit from "../pages/IssuerPermit";
import IssuerPermitDetails from "../pages/IssuerPermitDetails";
import Report from "../pages/Report";
import CoOrdinatorAdPermit from "../pages/CoOrdinatorAdPermit";
import InitiatorDetails from "../pages/InitiatorDetails";

const Loader = LazyLoad(import("../components/Loader.jsx"));
const SignIn = LazyLoad(import("../pages/SignIn.jsx"));
const Otp = LazyLoad(import("../pages/Otp"));

const Routers = () => {
  const user = useSelector((state) => state.signIn.adminData);
  const nav = user && user.token;
  return (
    <>
      {/*<Suspense>*/}
      <Router>
        <Routes>
          <Route exact path="/sign-in" element={<SignIn />} />
          <Route exact path="/otp" element={<Otp />} />
          <Route
            path="*"
            element={<Navigate to={!nav ? "/sign-in" : "/dashboard"} replace />}
          />
          <Route exact path={"/"} element={<ProtectedRoute />}>
            {/*comman*/}
            <Route exact path="/" element={<Loader />} />
            <Route exact path="/notification" element={<Notification />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/help" element={<Help />} />
            {/* Admin Role Route */}
            {user && user.roleId === config.role.adminRole && (
              <>
                <Route exact path="/dashboard" element={<Dashboard />} />
                <Route
                  exact
                  path="/dashboard/:userId"
                  element={<UserDetails />}
                />

                <Route exact path="/user" element={<Users />} />
                <Route exact path="/user/:userId" element={<UserDetails />} />

                <Route exact path="/permit" element={<Permit />} />
                <Route
                  exact
                  path="/permit/:permitId"
                  element={<IssuerPermitDetails />}
                />
                <Route
                  exact
                  path="/initiator/:initiatorPermitId"
                  element={<InitiatorDetails />}
                />
                <Route exact path="/department" element={<Department />} />
              </>
            )}

            {/* Issuer Role Route */}
            {user && user.roleId === config.role.issuerRole && (
              <>
                <Route exact path="/dashboard" element={<IssuerPermit />} />
                <Route exact path="/user/:userId" element={<UserDetails />} />
                <Route
                  exact
                  path="/permit/:permitId"
                  element={<IssuerPermitDetails />}
                />
                <Route
                  exact
                  path="/initiator/:initiatorPermitId"
                  element={<InitiatorDetails />}
                />
                <Route exact path="/report" element={<Report />} />
              </>
            )}
            {/* Custodian Role Route */}
            {user && user.roleId === config.role.custodianRole && (
              <>
                <Route
                  exact
                  path="/dashboard"
                  element={<CoOrdinatorAdPermit />}
                />
                <Route
                  exact
                  path="/permit/:permitId"
                  element={<IssuerPermitDetails />}
                />
                <Route
                  exact
                  path="/initiator/:initiatorPermitId"
                  element={<InitiatorDetails />}
                />
                <Route exact path="/report" element={<Report />} />
              </>
            )}
            {/* Cordinator Role Route */}
            {user && user.roleId === config.role.coOrdinatorRole && (
              <>
                <Route
                  exact
                  path="/dashboard"
                  element={<CoOrdinatorAdPermit />}
                />
                <Route
                  exact
                  path="/initiator/:initiatorPermitId"
                  element={<InitiatorDetails />}
                />
                <Route
                  exact
                  path="/permit/:permitId"
                  element={<IssuerPermitDetails />}
                />
                <Route exact path="/report" element={<Report />} />
              </>
            )}
          </Route>
        </Routes>
      </Router>
      {/*</Suspense>*/}
    </>
  );
};

export default Routers;
