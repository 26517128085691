import React from "react";
import { ReactComponent as Pencil } from "../assets/pencil.svg";
import { ReactComponent as Delete } from "../assets/delete.svg";
import {
  Boxes,
  ShiftBadge,
  ShiftboxMain,
  SingleShiftBtn,
  TitleShift,
} from "../styles/Shift.style";
import { Badge } from "react-bootstrap";
import DepartmentAddEdit from "../utils/models/DepartmentAddEdit";
import { CreateUserButton } from "../styles/UsersSearchTopBar";
import { useDispatch, useSelector } from "react-redux";
import {
  clearShiftId,
  getShiftId,
  toggleAddUpdateShift,
} from "../store/Slice/departmentShiftSlice";

const Shift = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const dispatch = useDispatch();
  const { allShift, addUpdateShift } = useSelector((state) => state.department);
  return (
    <div className="mt-4">
      <ShiftboxMain>
        <CreateUserButton
          type={"button"}
          style={{ marginBottom: "16px" }}
          onClick={() => {
            dispatch(toggleAddUpdateShift());
            dispatch(clearShiftId());
          }}
        >
          <b
            style={{
              fontSize: "30px",
              fontWeight: "normal",
              paddingRight: "18px",
            }}
          >
            +
          </b>{" "}
          Create Shift
        </CreateUserButton>
        <Boxes>
          {allShift &&
            allShift.length > 0 &&
            allShift.map((item, i) => {
              return (
                <SingleShiftBtn
                  key={i + 1}
                  onClick={() => {
                    dispatch(toggleAddUpdateShift());
                    dispatch(getShiftId(item.id));
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className={"d-inline-block"}>
                      <TitleShift>
                        {item.startTime} - {item.endTime}
                      </TitleShift>
                    </div>
                    <div>
                      <ShiftBadge color="#92FDFF">{item.shiftType}</ShiftBadge>
                      <div className={"d-inline-block"}>
                        <div className="btn-main">
                          <button className="btn">
                            <Pencil />
                          </button>
                          <button className="btn">
                            <Delete />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </SingleShiftBtn>
              );
            })}
          {addUpdateShift && (
            <DepartmentAddEdit
              show={addUpdateShift}
              onHide={() => dispatch(toggleAddUpdateShift())}
            />
          )}
        </Boxes>
      </ShiftboxMain>
    </div>
  );
};

export default Shift;
