import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import uploadIcon from "../../assets/upload_icon.svg";
import { ReactComponent as Close } from "../../assets/close_icon.svg";
import {
  AddAdminModel,
  AddUpdateBtn,
  ButtonDropzone,
  Dropzone,
  InputMain,
  SelectMain,
  selectMain,
  StyledSVG,
  UserBoxCheck,
  UserFormCheck,
} from "../../styles/AddUserModel.style";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { ADD_USER, UPDATE_USER, USERS_REQUEST } from "../../store/constant";
import Button from "react-bootstrap/Button";
import {
  clearSetUser,
  userAddUpdateToggle,
} from "../../store/Slice/usersSlice";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object().shape({
  name: yup.string().required("This field is required"),
  emailId: yup.string().email().required("This field is required"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Phone number is too short")
    .max(10, "Phone number is too long")
    .required("This field is required"),
  empId: yup.string().required("This field is required"),
  departmentId: yup.string().required("This field is required"),
  roleId: yup.string().required("This field is required"),
  shiftIds: yup.string().required("This field is required"),
  photo: yup.mixed().required("please select your photo"),
});

function MyVerticallyCenteredModal(props) {
  const dispatch = useDispatch();
  const {
    addUser,
    upateUserGet,
    singleUserGetLoading,
    userModelToggle,
    updateLoading,
  } = useSelector((state) => state.users);
  const { department, allShift, roles } = useSelector(
    (state) => state.department
  );
  const { adminData } = useSelector((state) => state.signIn);

  const {
    handleSubmit,
    handleChange,
    errors,
    isSubmitting,
    setFieldValue,
    handleReset,
    values,
    touched,
  } = useFormik({
    initialValues: upateUserGet?.id
      ? {
          ...upateUserGet,
          shiftIds: upateUserGet && upateUserGet.departmentShiftResponses[0].id,
        }
      : {
          name: "",
          emailId: "",
          phone: "",
          empId: "",
          departmentId: "",
          roleId: "",
          shiftIds: "",
          photo: "",
        },
    validationSchema,
    onSubmit(values) {
      let updatedUser = new FormData();
      updatedUser.append("photo", values.photo);
      let tmpDatauser = {
        name: values.name,
        emailId: values.emailId,
        phone: values.phone,
        empId: values.empId,
        departmentId: parseInt(values.departmentId),
        roleId: values.roleId,
        shiftIds: [parseInt(values.shiftIds)],
        active: true,
        delete: false,
        locationId: 1,
      };
      if (upateUserGet && upateUserGet?.id) {
        updatedUser.append(
          "userDetail",
          JSON.stringify({ ...tmpDatauser, id: upateUserGet?.id })
        );
        dispatch({ type: UPDATE_USER, payload: updatedUser });
      } else {
        updatedUser.append("userDetail", JSON.stringify(tmpDatauser));
        dispatch({ type: UPDATE_USER, payload: updatedUser });
      }
      handleReset();
      removeAll();
    },
  });

  const { acceptedFiles, getRootProps, getInputProps, inputRef } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop: (acceptedFiles) => {
      setFieldValue("photo", acceptedFiles[0]);
    },
  });

  let acceptedFileItems = acceptedFiles.map((file) => {
    return (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    );
  });

  const removeAll = () => {
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    inputRef.current.value = "";
    setFieldValue("photo", "");
    acceptedFileItems = [];
  };

  // useEffect(()=>{
  //   if(addUser){
  //     props.handleCloseModel();
  //   }
  // },[addUser])

  return (
    <div>
      <AddAdminModel
        backdrop="static"
        keyboard={false}
        show={userModelToggle}
        onHide={() => {
          dispatch(userAddUpdateToggle());
          // dispatch(clearSetUser())
        }}
      >
        <div
          className={"add_admin"}
          style={{ height: "96vh", overflowY: "scroll" }}
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {upateUserGet && upateUserGet.id ? "Edit User" : "Add User"}
            </Modal.Title>
            <Button
              variant="secondary"
              style={{ background: "transparent", border: "0" }}
              onClick={() => {
                dispatch(userAddUpdateToggle());
                dispatch(clearSetUser());
                handleReset();
                removeAll();
              }}
            >
              <Close />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="main-modal">
              {updateLoading && updateLoading ? (
                <>Loading...</>
              ) : (
                <form onSubmit={handleSubmit}>
                  <Dropzone {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <StyledSVG src={uploadIcon} alt="" srcSet={uploadIcon} />
                    <h4>Drag & Drop Your Photo Here</h4>
                    <em>(Only *.jpeg and *.png images will be accepted)</em>
                    <p></p>
                    <ButtonDropzone>Browse for file</ButtonDropzone>
                    <ul className={"pt-3"}>{acceptedFileItems}</ul>
                  </Dropzone>
                  {touched.photo && errors.photo && (
                    <p className={"text-danger d-block pt-2"}>{errors.photo}</p>
                  )}

                  <div className="main-details">
                    <div className="row mt-5">
                      <div className="col-md-12">
                        <InputMain className="input-group d-block mb-3">
                          <input
                            type="text"
                            name={"name"}
                            onChange={handleChange}
                            value={values.name}
                            className="form-control w-100 mb-2 "
                            placeholder="Name"
                          />
                          {touched.name && errors.name && (
                            <p className={"text-danger d-block"}>
                              {errors.name}
                            </p>
                          )}
                        </InputMain>
                        <InputMain className="input-group mb-3">
                          <input
                            type="text"
                            name={"emailId"}
                            onChange={handleChange}
                            value={values.emailId}
                            className="form-control w-100 mb-2"
                            placeholder="Email Address "
                          />
                          {touched.emailId && errors.emailId && (
                            <p className={"text-danger d-block"}>
                              {errors.emailId}
                            </p>
                          )}
                        </InputMain>
                      </div>
                      <div className="col-md-6">
                        <InputMain className="input-group mb-3">
                          <input
                            type="text"
                            name={"phone"}
                            onChange={handleChange}
                            value={values.phone}
                            className="form-control w-100 mb-2"
                            placeholder="Phone"
                          />
                          {touched.phone && errors.phone && (
                            <p className={"text-danger d-block"}>
                              {errors.phone}
                            </p>
                          )}
                        </InputMain>
                      </div>
                      <div className="col-md-6">
                        <InputMain className="input-group mb-3">
                          <input
                            type="text"
                            id={"empId"}
                            name={"empId"}
                            onChange={(e) =>
                              setFieldValue("empId", e.target.value)
                            }
                            value={values.empId}
                            className="form-control w-100 mb-2"
                            placeholder="EmpId"
                          />
                          {touched.empId && errors.empId && (
                            <p className={"text-danger d-block"}>
                              {errors.empId}
                            </p>
                          )}
                        </InputMain>
                      </div>
                      <SelectMain className="col-md-6 select_text">
                        <select
                          className="form-select"
                          name={"departmentId"}
                          onChange={(e) =>
                            setFieldValue("departmentId", e.target.value)
                          }
                          value={values.departmentId}
                          aria-label="Default select example"
                        >
                          <option value={""} selected>
                            Department
                          </option>
                          {department && department.length > 0 ? (
                            department.map((option) => {
                              return (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              );
                            })
                          ) : (
                            <option value={"1"} selected>
                              option not found
                            </option>
                          )}
                        </select>
                        {touched.departmentId && errors.departmentId && (
                          <p className={"text-danger d-block"}>
                            {errors.departmentId}
                          </p>
                        )}
                      </SelectMain>
                      {/*</div>*/}
                      <SelectMain className="col-md-6 select_text">
                        <select
                          name={"roleId"}
                          onChange={handleChange}
                          value={values.roleId}
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option value={""} selected>
                            Role type
                          </option>
                          {roles && roles.length > 0 ? (
                            roles.map((el) => {
                              return (
                                <option
                                  key={el.id}
                                  value={el.id}
                                  selected={el.id === values.roleId}
                                >
                                  {el.displayName}
                                </option>
                              );
                            })
                          ) : (
                            <option value={""} selected>
                              No data found
                            </option>
                          )}
                        </select>
                        {touched.roleId && errors.roleId && (
                          <p className={"text-danger d-block"}>
                            {errors.roleId}
                          </p>
                        )}
                      </SelectMain>
                      <div className="choose-shift mt-5">
                        <p>Choose Shift</p>
                        <div className="row">
                          {allShift && allShift.length > 0
                            ? allShift.map((checkbox, i) => {
                                return (
                                  <div className="col-md-6" key={i}>
                                    <UserFormCheck>
                                      <label
                                        className="form-check-label"
                                        htmlFor={`shift${checkbox.id}`}
                                      >
                                        <UserBoxCheck className="abc">
                                          <span>A</span>
                                        </UserBoxCheck>
                                        {`${checkbox.startTime}  - ${checkbox.endTime}`}
                                        <input
                                          name={"shiftIds"}
                                          onChange={handleChange}
                                          className="form-check-input"
                                          type="radio"
                                          checked={
                                            values &&
                                            values.shiftIds.toString() ===
                                              checkbox.id.toString()
                                          }
                                          value={`${checkbox.id}`}
                                          id={`shift${checkbox.id}`}
                                        />
                                      </label>
                                    </UserFormCheck>
                                  </div>
                                );
                              })
                            : "Shift not found"}
                          {touched.shiftIds && errors.shiftIds && (
                            <p className={"text-danger d-block"}>
                              {errors.shiftIds}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={"text-center py-5"}>
                      <AddUpdateBtn type={"submit"}>
                        {upateUserGet && upateUserGet.id
                          ? "Update User"
                          : "Add User"}
                      </AddUpdateBtn>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </Modal.Body>
        </div>
      </AddAdminModel>
    </div>
  );
}

export default MyVerticallyCenteredModal;
