import React from "react";
import Routers from "./routers/Routers";
import GlobalStyle from "./styles/GlobalStyle";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthProvider } from "./Context/AuthContext";

import "./App.css";

function App() {
  return (
    <>
      <AuthProvider>
        <GlobalStyle />
        <Routers />
      </AuthProvider>
    </>
  );
}

export default App;
