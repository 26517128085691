import {styled} from "styled-components";

export const UserInfo = styled("div")({
    margin:"25px",
    background: "#FFFFFF",
    boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    height:"75vh",
    ".main_info":{
        padding:"0 35px",
        ".avatar":{
         padding:"25px 35px"
        }
    }
})

export  const  UserDetailsAvatar = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  overflow: hidden;
  background-color: #f57660;
  background-image: ${props => props.img};
  img{
    width: 100%;
  }
`
export const UsersDetails = styled("div")({
    padding: "15px 45px",
    color:"#2D3E50",
})

export const NameMain = styled("div")({
    display:"flex",
    padding:"0px !important",
    alignItems:"center",
    "h3":{
        fontFamily: 'Josefin Sans',
        fontSize: "24px",
        marginTop:"8px",
        lineHeight: "21px",
        color: "#2D3E50",
        padding:"0",
        fontWeight:"bold",
}
})

export const EmailAddress = styled("div")({
    // display:"flex",
    paddingTop:"20px",
    ":first-child": {
        padding:"0 60px 0 0"
    },
    "p":{
        fontWeight:"500",
        fontSize:"14px",
    },
    "span":{
        fontFamily: 'Josefin Sans',
        fontSize:"10px",
        lineHeight:"12px",
        color:"#2D3E50",
    }
})
export const UpdateBtn = styled.button`
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #EBEBEB;
  border-radius: 26.5px;
  border: 0;
  width: 246px;
  height: 53px;
  margin-top: 25px;
`;
