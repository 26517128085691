import React, { useEffect } from "react";
import NavDashboard from "../components/Navbar";
import Drawer from "../components/Drawer";
import { Nav } from "react-bootstrap";
import DepartmentCompo from "../components/DepartmentCompo";
import UserSearchBar from "../utils/UserSearchBar";
import UserTabel from "../utils/UserTabel";
import {
  DEPARTMENT_REQUEST,
  GET_ALL_SHIFT,
  GET_ROLES,
  USERS_REQUEST,
} from "../store/constant";
import { useDispatch, useSelector } from "react-redux";
import MyVerticallyCenteredModal from "../utils/models/AddUserModal";

const Users = () => {
  const dispatch = useDispatch();
  const { department, allShift, roles } = useSelector(
    (state) => state.department
  );
  const { addUser, userModelToggle, upateUserGet } = useSelector(
    (state) => state.users
  );

  const body = {
    locationId: 1,
    filterBy: "",
    searchText: "",
  };

  useEffect(() => {
    dispatch({ type: USERS_REQUEST, payload: body });
  }, [addUser]);

  return (
    <div className="d-flex">
      <div>
        <Drawer />
      </div>
      <div className={"w-100"}>
        <NavDashboard page={"Users"} />
        <UserSearchBar user={true} />
        <UserTabel />
        {userModelToggle && <MyVerticallyCenteredModal />}
      </div>
    </div>
  );
};

export default Users;
