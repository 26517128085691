import { takeLatest, call, put } from 'redux-saga/effects';
import {NOTIFICATION_REQUEST} from "../constant";
import {notificationFaield, notificationRequest, notificationSuccess} from "../Slice/notificationSlice";
import Api from "../../api/api";

function* getNotification(action){
    try {
        yield put(notificationRequest());
        const response = yield call(Api.get,'/api/notifications/list')
        // console.log(response,"notifications/list============")
        yield put(notificationSuccess(response.data))
    }catch (error){
        console.log(error)
        yield put(notificationFaield(error))
    }
}

function* notificationSaga(){
    yield takeLatest(NOTIFICATION_REQUEST,getNotification)
}
export default notificationSaga;