import { createSlice } from "@reduxjs/toolkit";

export const signInSlice = createSlice({
    name: 'signIn',
    initialState: {
        loading:false,
        error:null,
        otp:null,
        drawer:false,
        adminData:null,
        sendOtp:null,
        emailId:null,
        emailError:null,
        otpError:null,
    },
    reducers: {
        // Sign In User
        signInRequest:(state) => {
            state.loading = true;
        },
        signInSuccess:(state,action) => {
            state.sendOtp = action.payload;
            state.emailId = action.payload.emailId;
            state.loading = false;
            state.error = null;
            state.emailError = null;
        },
        signInFailure:(state,action)=>{
            state.loading = false;
            state.emailError = action.payload;
        },
        clearUserData:(state,action)=>{
            state.userData = null;
            state.loading = false;
            state.emailError = null;
        },
        // OTP Success
        otpSuccess:(state,action) => {
            state.adminData = action.payload;
            state.loading = false;
            state.error = null;
        },
        otpFailure:(state,action)=>{
            state.loading = false;
            state.otpError = action.payload;
        },
        clearUserRoleData:(state,action)=>{
            state.adminData = null;
        },

        // Drawer
        drawerToggle:(state,action)=>{
            state.drawer = !state.drawer;
        },
        sendOtpClear:(state)=>{
          state.sendOtp = null
          state.otpError = null
        }
    },
});

// Action creators
export const {
    fetchUsersSuccess,
    addUserSuccess,
    updateUserSuccess,
    deleteUserSuccess,
    // Sign in Actions
    signInRequest,
    signInFailure,
    signInSuccess,
    clearUserData,
    otpSuccess,
    otpFailure,
    drawerToggle,
    sendOtpClear,
    clearUserRoleData,
} = signInSlice.actions;

export default signInSlice.reducer

