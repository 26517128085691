import { MainNav, NavIcon, NavUser, UserIcon, UserSelect, UserWord } from '../styles/Navbar.style';
import { ReactComponent as Icon } from '../assets/Dashboard/icon.svg';
import { ReactComponent as HeaderProfile } from '../assets/Dashboard/HeaderProfile.svg';
import { ReactComponent as Headerfaq } from '../assets/Dashboard/HeaderFaq.svg';
import { ReactComponent as HeaderNotification } from '../assets/Dashboard/HeaderNotification.svg';
import { Form } from 'react-bootstrap';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {drawerToggle} from "../store/Slice/signInSlice";
import SearchLocation from "./SearchLocation";

function NavDashboard({page}) {
    const dispatch = useDispatch()
    const {adminData} = useSelector(state => state.signIn)
    const handleDrawer = () => {
        dispatch(drawerToggle())
    };
    const handleLocationChange = (selectedOption) => {
        // Handle the selected location as needed
        console.log(selectedOption,"selectedOption")
    };

    return (
        <MainNav>
            <NavIcon onClick={handleDrawer}>
                <Icon/>
            </NavIcon>
            <NavUser>
                <div>
                    <UserWord>
                        {page ? page : "Dashboard"}
                    </UserWord>
                </div>
                <div>
                    <Link to={"/dashboard"}
                          style={{textDecoration: "none", color: "#FE6C4C\n"}}>Dashboard {page ? ">" : ""} </Link>
                    {page ? `${page}` : ""}
                </div>
            </NavUser>
            <h5 style={{fontSize:"12px"}}>
                {adminData && adminData.roleName ? adminData.roleName :'' }
            </h5>
            <UserSelect>
                {adminData && adminData.adminLocations && <SearchLocation handleLocationChange={handleLocationChange} />}
            </UserSelect>
            <UserIcon>
                <div className={"profile"}>
                    <Link className={"link"} to={"/profile"}>
                        <HeaderProfile/>
                    </Link>
                </div>
                <div className={"help"}>
                    <Link className={"link"} to={"/help "}>
                        <Headerfaq/>
                    </Link>
                </div>
                <div className={"notification"}>
                    <Link className={"link"} to={"/notification"}>
                        <HeaderNotification/>
                    </Link>
                </div>
            </UserIcon>
        </MainNav>
    );
}

export default NavDashboard
