import { takeLatest, call, put } from "redux-saga/effects";
import {
  GET_DEPARTMENT_REQUEST_FOR_ISSUER,
  GET_PERMIT_BY_ID,
  POST_ISSUER_PERMIT_REQUEST,
} from "../constant";
import Api from "../../api/api";
import {
  createPermitFaield,
  createPermitRequest,
  createPermitSuccess,
  getPermiByIdtFaield,
  getPermitByIdRequest,
  getPermitByIdSuccess,
  issuerDepartmentFailed,
  issuerDepartmentRequest,
  issuerDepartmentSuccess,
} from "../Slice/issuerPermitSlice";
import toast from "react-hot-toast";

// function* postIssuerPermit(action){
//     console.log(action.payload,"action.payload issuer")
//     try {
//         // yield put(createPermitRequest());
//         const response = yield call(Api.post,"/api/permit/saveOrUpdate",action.payload)
//         console.log(response,"Permit_Number:============")
//         yield put(createPermitSuccess(response))
//     }catch (error){
//         console.log(error.message)
//         toast.error(error.message, {
//             duration: 4000,
//             position: 'top-right',
//
//             // Change colors of success/error/loading icon
//             iconTheme: {
//                 primary: '#f00',
//                 secondary: '#fff',
//             },
//             // styling
//             style: {
//                 border: '1px solid #713200',
//                 padding: '5px 10px',
//                 color: '#713200',
//                 // minWidth: '300px'
//             },
//             // Aria
//             ariaProps: {
//                 role: 'status',
//                 'aria-live': 'polite',
//             },
//         });
//         yield put(createPermitFaield(error && error.message))
//     }
// }

function* getDepartment(action) {
  try {
    yield put(issuerDepartmentRequest());
    const response = yield call(Api.get, "/api/getDepartments");
    // console.log(response,'response ==> api/getDepartments')
    yield put(issuerDepartmentSuccess(response && response.data));
  } catch (error) {
    console.log(error);
    yield put(issuerDepartmentFailed(error));
  }
}

function* getPermitById(action) {
  try {
    yield put(getPermitByIdRequest());
    const response = yield call(Api.get, `/api/permit/get/${action.payload}`);
    // console.log(response.data,'response ==> /api/permit/get/id')
    console.log(response, "response");
    if (response && response.data) {
      yield put(getPermitByIdSuccess(response.data.permitResponse));
    }
  } catch (error) {
    console.log(error);
    yield put(getPermiByIdtFaield(error));
  }
}

function* postIssuerPermit(action) {
  try {
    yield put(createPermitRequest());
    const response = yield call(
      Api.post,
      "/api/permit/saveOrUpdate",
      action.payload
    );
    yield put(createPermitSuccess(response));
    if (response.status.status === 200) {
      toast.success(`${response.status.message}`);
    } else {
      toast.error(`${response.status.message}`);
    }
  } catch (error) {
    toast.error(error.message, {
      duration: 4000,
      position: "top-right",

      // Change colors of success/error/loading icon
      iconTheme: {
        primary: "#f00",
        secondary: "#fff",
      },
      // styling
      style: {
        border: "1px solid #713200",
        padding: "5px 10px",
        color: "#713200",
        // minWidth: '300px'
      },
      // Aria
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
    yield put(createPermitFaield(error && error.message));
  }
}

function* issuerPermitSaga() {
  yield takeLatest(POST_ISSUER_PERMIT_REQUEST, postIssuerPermit);
  yield takeLatest(GET_DEPARTMENT_REQUEST_FOR_ISSUER, getDepartment);
  yield takeLatest(GET_PERMIT_BY_ID, getPermitById);
}
export default issuerPermitSaga;
