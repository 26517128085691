import {createSlice} from "@reduxjs/toolkit";

export const permitSlice = createSlice({
    name:"permits",
    initialState:{
        loading:false,
        error:null,
        permitList:null,
        isShiftPerson:null,
    },
    reducers:{
        // notification request
        getAllPermitRequest:(state) => {
            state.loading = !state.loading;
        },
        getAllPermitSuccess:(state,action) => {
            state.loading = !state.loading;
            state.permitList = action.payload.parmits;
            state.isShiftPerson = action.payload.isShiftPerson;
        },
        getAllPermitFaield:(state,message)=>{
            state.loading = !state.loading;
            state.error = message
            state.isShiftPerson = null
            state.permitList = null

        }
    }
})

export const {
    getAllPermitRequest,
    getAllPermitSuccess,
    getAllPermitFaield,
} =  permitSlice.actions;

export default permitSlice.reducer