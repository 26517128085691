import {styled} from "styled-components";

export const IssuerDetails = styled.div`
  .pencil{
    cursor: pointer;
    padding: 0 8px 10px;
  }
  background: #FFFFFF;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 30px;
  .pencil {
    text-align: end;
    padding-right: 20px;
  } 
  .permit_info {
    display: flex;
    color: #2D3E50;
    .role_box{
      border: 1px solid #2D3E50;
      border-radius: 5px;
      font-size: 10px;
      text-align: center;
      padding: 4px 16px;
      display: inline-block;
      margin-left: 10px;
    }
    .status_round{
      height: 10px;
      width: 10px;
      border-radius: 5px;
      background-color: #FF8946;
      display: inline-block;
    }
    span{
      font-family: 'Josefin Sans';
      font-size: 12px;
      line-height: 12px;
    }
    p {
      font-size: 15px;
      font-weight: 500;
    }
    .font_size_12{
      font-size: 14px;
    }
    .left_side{
      padding-right: 60px;
    }
  }
  .department_list{
    color: #2D3E50;
    padding-top: 35px;
    .department_title{
      font-size: 10px;
      line-height: 12px;
    }
  }
  .comment_info{
    padding-top: 25px;
    .comment_title{
      font-weight: 600;
      font-size: 14px;
      color: #2D3E50;
    }
    p{
      font-size: 12px;
      width: 35%;
      color: #6E7A86;
    }
  }
  .update_btn {
    text-align: end;
    button {
      //background: #EBEBEB;
      border-radius: 26.5px;
      background: linear-gradient(118.68deg, #E4385E -20.39%, #FF9C61 94.52%);
      padding: 12px 65px;
      border: 0;
      color: #fff;
      text-transform: uppercase;
    }
  }
`;