import { styled } from "styled-components";

export const ShiftboxMain = styled.div`
background: #ffffff;
box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
border-radius: 10px;
padding: 25px;
margin: 0 25px;
  padding-right: 250px;
  @media (max-width: 1366px) {
    padding-right: 50px;
  }
`

export const Boxes = styled.div`
display: flex;
flex-wrap: wrap;
`;
export const SingleShiftBtn = styled.button`
  border: 1px solid #2d3e50;
  border-radius: 5px;
  margin: 10px;
  background: transparent;
  width: calc(33.33% - 50px);
  .btn-main{
   padding-left: 10px;
    .btn{
      padding: 0 !important;
    }
  }
`;

export const TitleShift = styled.h4`
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  @media (max-width: 1366px) {
    font-size: 16px;
  }
  @media (max-width: 1200px) {
    font-size: 13px;
    padding:8px 0;
  }
 
  line-height: 100%;
  color: #2D3E50;
  margin: 0;
  display: inline-block;
  padding:10px 0;
`;

export const ShiftBadge = styled.span`
background: #92FDFF;
border-radius: 8px;
font-family: 'Josefin Sans';
font-size: 13px;
background: ${props => props.color || "#2D3E50"};
color:"#2D3E50";
padding: 5px 8px;
margin-left:12px;
  @media (max-width: 1200px) {
    margin-left:5px;
    padding: 4px 6px;
    font-size: 11px;
  }
`;
