import {styled} from "styled-components";
import {ErrorMessage, Field, Form} from "formik";

export const Message = styled("div")({
    background: "#FFFFFF",
    boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    padding:"25px 35px",
    ".message_main":{
        display:"flex",
        justifyContent: "space-between",
        "div":{
            "h3": {
                fontFamily: 'Josefin Sans',
                fontSize: "36px",
                color: "#333333",
            },
            "p": {
                color: " rgba(45, 62, 80, 0.6)",
                fontSize: "14px",
                lineHeight: "19px",
            }
        }
    },
})

// Help Form Styled
export const HelpStyledForm = styled(Form)({
    fontSize: "14px",
    paddingTop: "45px",
    ".single_field": {
        // marginBottom:"25px"
    },
    ".tow_part": {
        display: "flex",
        justifyContent: "space-between",
        "div": {
            width: "calc(100% - 30px)",
            // marginBottom: "25px",

            "label": {
                display: "block"
            },
            "input": {
                // width: "100%",
                width: "calc(100% - 30px)",
                // margin: "0 10px",
            },
            ".phone": {
                width: "100%",
            }

        }

    }
});

export const HelpField = styled(Field)`
  padding: 5px;
  border: 0;
  border-bottom: 1px solid #2D3E50;
  width: 100%;
  //margin-bottom: 35px;
  &:focus {
    outline: none;
    box-shadow: 0px;
  }
`;

export const HelpError = styled(ErrorMessage)`
  color: red;
  font-size: 14px;
  margin-top: 5px;
`;

export const HelpBtn = styled("button")`
  background: linear-gradient(118.68deg, #E4385E -20.39%, #FF9C61 94.52%);
  border-radius: 26.5px;
  font-family: 'Josefin Sans';
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  border: 0;
  width: 246px;
  height: 53px;
`;


export const HelpTextarea = styled.textarea`
  margin-bottom: 10px;
  padding: 5px;
  padding: 5px;
  border: 0;
  border-bottom: 1px solid #2D3E50;
  width: 100%;
  &:focus{
    outline: 0;
  }
`;
