import React, {useEffect} from 'react'
import NavDashboard from '../components/Navbar'
import Drawer from '../components/Drawer'
import { Nav } from 'react-bootstrap'
import DepartmentCompo from '../components/DepartmentCompo'
import UserSearchBar from '../utils/UserSearchBar'
import UserTabel from '../utils/UserTabel'
import {Avatar, UserTabelBtn} from "../styles/UserTabel.style";
import ModalPerson from "../utils/models/ModalPerson";
import {DetailsBtnPermit, ParmitTable, StatusPermit} from "../styles/Permit.style";
import {useDispatch, useSelector} from "react-redux";
import {DEPARTMENT_REQUEST, GET_ALL_PERMIT, GET_INITIATOR_BYID, GET_PERMIT_BY_ID} from "../store/constant";
import {useNavigate} from "react-router-dom";
import {cleanUpPermit, clearPermitById} from "../store/Slice/issuerPermitSlice";

const Permit = () => {
    const filter = {
        filterBy: "",
        searchText:"",
    }

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const {permitList} = useSelector(state => state.permits)
   
    const {getPermitByIdSuccessDataForPermitDetails,getPermitByIdSuccessData,getPermitByIdLoading,permitSuccess} = useSelector(state => state.issuerPermit);
    const {getInitiatorDataSuccessForDashboard} = useSelector(state => state.formStepper);


    useEffect(() => {
        dispatch({type: GET_ALL_PERMIT, payload: filter})
        dispatch(cleanUpPermit())
    }, []);

    const handlePermitDetails = (i) =>{
        // dispatch(clearPermitById());
        // if(!getPermitByIdSuccessDataForPermitDetails){
        //     dispatch({type:GET_PERMIT_BY_ID,payload:id})
        // }
        // navigate(`/permit/${id}`)
        if (i.initiatorCreated) {
            dispatch({
                type: GET_INITIATOR_BYID,
                payload: i.id
            })
            // dispatch({type: GET_PERMIT_BY_ID, payload: i.id})
        } else {
            dispatch({type:GET_PERMIT_BY_ID,payload:i.id})
            navigate(`/permit/${i.id}`)
            // dispatch(clearPermitById());
            // if (!getPermitByIdSuccessData) {
            //     dispatch({
            //         type: GET_PERMIT_BY_ID,
            //         payload: i.id
            //     })
            // }
            // navigate(`/permit/${i.id}`)
        }
    }
    useEffect(()=>{
        if(getInitiatorDataSuccessForDashboard){
            navigate(`/initiator/${getInitiatorDataSuccessForDashboard.id}`)
        }
    },[getInitiatorDataSuccessForDashboard])


    // useEffect(()=>{
    //     if(getPermitByIdSuccessDataForPermitDetails){
    //         navigate(`/permit/${getPermitByIdSuccessDataForPermitDetails.id}`)
    //     }
    // },[getPermitByIdSuccessDataForPermitDetails])

    return (
        <div className='d-flex'>
            <div>
                <Drawer/>
            </div>
            <div className={"w-100"}>
                <NavDashboard page={"Permit"}/>
                <UserSearchBar permit={true} isNotBut={true}/>
                <div className={"container"}>
                    <ParmitTable>
                        <div className='user-table'>
                            <table className='table'>
                                <thead>
                                <tr>
                                    <th scope='col'>Permit Number</th>
                                    <th scope='col'>Department</th>
                                    <th scope='col'>Date</th>
                                    <th scope='col'>Status</th>
                                    <th scope='col'></th>
                                </tr>
                                </thead>
                                <tbody>
                                {permitList && permitList.length > 0 ?
                                    permitList.map((i)=>{
                                        return(
                                            <tr key={i.id} className='content' id={i + 1}>
                                                <td>{i.permitNumber && i.permitNumber ? i.permitNumber : "-" }</td>
                                                <td>Mining</td>
                                                <td>{i.dateTime && i.dateTime ? i.dateTime : '-'}</td>
                                                <td>
                                                    {i.status && i.status === "Approved" && <><StatusPermit status={"#30DBB2"}/> Approved</>}
                                                    {i.status && i.status === "Rejected" && <><StatusPermit status={"#FF0000"}/> Rejected</>}
                                                    {i.status && i.status === "Requested" && <><StatusPermit status={"#FF8946"}/> Requested</>}
                                                    {i.status && i.status === "In Progress" && <><StatusPermit status={"#E4385E"}/> In Progress</>}
                                                </td>
                                                <td>
                                                    <DetailsBtnPermit onClick={()=>handlePermitDetails(i)} type="button">Details</DetailsBtnPermit>
                                                </td>
                                            </tr>
                                        )
                                    }) :  (
                                        <tr>
                                          <td colSpan="4" style={{borderBottom: 'none'}}>No data Found</td>
                                        </tr>
                                      )
                                }
                                </tbody>
                            </table>
                        </div>
                    </ParmitTable>
                </div>

            </div>
        </div>
    );
};

export default Permit
