import { createSlice } from "@reduxjs/toolkit";

export const issuerPermitSlice = createSlice({
  name: "issuerPermit",
  initialState: {
    loading: false,
    getPermitByIdLoading: false,
    getPermitByIdSuccessData: null,
    getPermitByIdError: null,
    error: null,
    createPermitToggle: false,
    permitRaised: null,
    departmentList: null,
    permitSuccess: null,
    createPostError: null,
  },
  reducers: {
    // notification request
    createPermitRequest: (state) => {
      state.loading = !state.loading;
    },
    createPermitSuccess: (state, action) => {
      state.loading = !state.loading;
      state.permitSuccess = action.payload;
      state.createPermitToggle = false;
    },
    createPermitFaield: (state, action) => {
      state.loading = false;
      state.createPostError = action.payload;
      state.createPermitToggle = false;
    },
    clearUserPermit: (state) => {
      state.permitSuccess = null;
    },

    // Toggle Create New Issuer Permit
    newPermitToggle: (state) => {
      console.log(state, "permit toggle");
      state.createPermitToggle = !state.createPermitToggle;
      state.createPostError = null;
    },
    //  Get department for create issuer
    issuerDepartmentRequest: (state) => {
      state.loading = !state.loading;
    },
    issuerDepartmentSuccess: (state, action) => {
      state.loading = !state.loading;
      state.departmentList = action.payload;
    },
    issuerDepartmentFailed: (state, action) => {
      state.loading = !state.loading;
      state.error = action.payload;
    },

    // notification request
    getPermitByIdRequest: (state) => {
      state.getPermitByIdLoading = true;
    },
    getPermitByIdSuccess: (state, action) => {
      state.getPermitByIdLoading = false;
      state.getPermitByIdSuccessData = action.payload;
      state.getPermitByIdSuccessDataForPermitDetails = action.payload;
    },
    getPermiByIdtFaield: (state, action) => {
      state.getPermitByIdLoading = false;
      state.getPermitByIdError = action.payload;
    },
    clearPermitById: (state) => {
      // state.getPermitByIdSuccessData = null;
      state.getPermitByIdError = null;
      state.getPermitByIdLoading = false;
      state.getPermitByIdSuccessDataForPermitDetails = null;
    },
    cleanUpPermit: (state, action) => {
      state.getPermitByIdLoading = false;
      state.getPermitByIdError = null;
    },
  },
});

export const {
  createPermitRequest,
  createPermitSuccess,
  createPermitFaield,
  newPermitToggle,
  issuerDepartmentRequest,
  issuerDepartmentSuccess,
  issuerDepartmentFailed,
  getPermitByIdRequest,
  getPermitByIdSuccess,
  getPermiByIdtFaield,
  cleanUpPermit,
  clearUserPermit,
  clearPermitById,
} = issuerPermitSlice.actions;

export default issuerPermitSlice.reducer;
