import styled from "styled-components"  

export const UserForm = styled.form`
  width: 100%;
  display: flex;
  margin: 0 auto 0 auto;
  border-radius: 8px;
  align-items: baseline;
  h4{
    font-size: 16px;
    color: #88919A;
  }
  `;
export const SelectSearch = styled.select`
  +.issuerPd{
    padding:6px 12px;
  }
  //flex: 1;
  padding: 12px;
  border: none;
  font-family: "Lato", sans-serif;
  text-align: start;
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  justify-content: start;

  &:hover {
    cursor: pointer;
  }
`;
export const SelectIssuer = styled.div`
  select{
    padding: 8px 18px;
  }
`

export const CreateUserButton = styled.button`
  background: #2D3E50;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-family: 'Josefin Sans';
  font-size: 16px;
  line-height: 14px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 14px 20px;
  border: none;
  margin-left: 10px;
`;
export const SearchBar = styled.div`
box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
border-radius: 10px;
margin: 16px 0;
padding: 16px;
`;

