import { styled } from "styled-components";

export const UsersTabel = styled("div")({
    background:" #FFFFFF",
    boxShadow:" 0px 2px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",

    "::-webkit-scrollbar-thumb": {
        backgroundColor: "#2D3E50",
        borderRadius: "10px",
        // border: "3px solid #ffffff",
    },
    "::-webkit-scrollbar-track": {
        background: "#CECECE",
        margin:"30px 0 15px 0",
        borderRadius: "10px",
    },
    "*::-webkit-scrollbar": {
        width: "4px",
        borderRadius: "10px",
    },
    "*": {
        scrollbarWidth: "auto",
        scrollbarColor: "#8f54a0 #ffffff",
    },
    ".user-table": {
        overflow: "auto",
        height: "50vh",
        marginRight:"5px",
        "table": {
            color:"#2D3E50",
            "thead": {
                "tr": {
                    "th": {
                        position: "sticky",
                        top: "0",
                        zIndex: "1",
                        backgroundColor: "#fff",
                        color:"#7C858F",
                        fontWeight:"500"
                    },
                },
            },
            "tbody": {
                "tr": {
                    "&:hover":{
                      background:"#F4F4F4"
                    },
                    "td": {
                        fontFamily: 'Josefin Sans',
                        fontStyle: "normal",
                        fontWeight:" 600",
                        fontSize: "15px",
                        // textAlign:" center",
                        color: "#2D3E50",
                        ".user_btn":{
                            lineHeight:"100%"
                        }
                    }
                }
            }
        }
    }
})
export const ShifttypeLabelStyle = styled.div`
  background-color: #92FDFF;
  padding: 6px 8px;
  border-radius: 18px;
  text-align: center;
  margin-left: 6px;
  line-height: 100%;
`












export const UserTabelBtn = styled.button`
  width: 120px;
  border: 1px solid #2d3e50;
  border-radius: 5px;
  font-size: 12px;
  line-height: 12px;

  &:hover {
    background: #2D3E50;
    color: #fff;
  }
`;

export const Avatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
  background-color: #f57660;
  background-image: ${props => props.img};
  img{
    width: 100%;
  }
`;

