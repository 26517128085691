import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_WORK_LIST, SAVE_UPDATE_INITIATOR } from "../../store/constant";
import {
  ButtonNextCreateinitiator,
  CommentsStyle,
  WorkListStyle,
} from "../../styles/CreateInitiator.style";
import { Field, Form, Formik } from "formik";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  clearInitiatorData,
  createInitiatorToggle,
  saveInitiatorData,
  setCurrentStep,
} from "../../store/Slice/formStepperSlice";
import styled from "styled-components";

const checkboxData = [
  {
    id: 1,
    description: "Earthing is provided at site during Arc Welding?",
  },
  {
    id: 2,
    description: "Availability of necessary firefighting arrangement",
  },
  {
    id: 3,
    description: "Welding cable connection with lugs and insulated",
  },
  // ... other checkbox objects
];

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 8px;
`;

const CheckboxInput = styled.input`
  margin-right: 8px;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #2d3e50;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
  cursor: pointer;

  &:checked {
    border-color: #2d3e50;
    background-color: #2d3e50;
  }

  &:checked::before {
    content: "";
    display: block;
    width: 6px;
    height: 10px;
    border-right: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    transform: rotate(45deg);
    margin-left: 4px;
  }
`;

const CheckboxLabel = styled.label`
  font-weight: 500;
  cursor: pointer;
`;

const WorkListModelPage = ({ workListId }) => {
  const dispatch = useDispatch();
  const {
    workListLoading,
    workList,
    workListError,
    initiatorData,
    currentStep,
    initiatorLoading,
  } = useSelector((state) => state.formStepper);
  const [checkboxValues, setCheckboxValues] = useState(
    initiatorData ? initiatorData.workDescriptionRequests : []
  );
  const [comment, setComment] = useState([]);
  useEffect(() => {
    if (workListId) {
      dispatch({ type: GET_WORK_LIST, payload: workListId });
    }
  }, [workListId]);

  const handleSubmit = (values) => {
    const updatedInitiator = {
      ...initiatorData,
      workDescriptionRequests: checkboxValues,
    };
    dispatch(saveInitiatorData(updatedInitiator));
    dispatch({ type: SAVE_UPDATE_INITIATOR, payload: updatedInitiator });
    dispatch(clearInitiatorData());
    // dispatch(setCurrentStep(1))
  };

  const initialValues = {
    // workDescriptionRequests: [],
  };

  const handleCheckboxChange = (id, answer) => {
    const existingValueIndex = checkboxValues.findIndex((val) => val.id === id);

    if (existingValueIndex !== -1) {
      // Toggle the checkbox value
      const existingValue = checkboxValues[existingValueIndex];
      if (existingValue.answer === answer) {
        // Uncheck the checkbox
        setCheckboxValues((prevValues) => [
          ...prevValues.slice(0, existingValueIndex),
          ...prevValues.slice(existingValueIndex + 1),
        ]);
      } else {
        // Update the checkbox value
        setCheckboxValues((prevValues) => [
          ...prevValues.slice(0, existingValueIndex),
          { id, answer },
          ...prevValues.slice(existingValueIndex + 1),
        ]);
      }
    } else {
      // Add new checkbox value
      setCheckboxValues((prevValues) => [...prevValues, { id, answer }]);
    }
  };

  return (
    <>
      <div>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ handleChange }) => (
            <Form>
              <Modal.Header className={"border-0 px-0"}>
                <Modal.Title className={"w-100 px-0 "}>
                  <div className={"hot_worl_heading d-block w-100 border-0"}>
                    Hot Work
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  {workListLoading && workListLoading ? (
                    "loading..."
                  ) : (
                    <WorkListStyle>
                      {workList &&
                        workList.map((item) => (
                          <div key={item.id}>
                            <div
                              className={"d-flex justify-content-between py-2"}
                            >
                              <label
                                className={"d-block"}
                                htmlFor={`checkbox-${item.id}`}
                              >
                                {item.id}) {item.description}
                              </label>
                              <div className={"d-flex"}>
                                <CheckboxWrapper>
                                  <CheckboxInput
                                    type="checkbox"
                                    id={`checkbox-${item.id}-yes`}
                                    checked={checkboxValues.some(
                                      (val) =>
                                        val.id === item.id &&
                                        val.answer === "yes"
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(item.id, "yes")
                                    }
                                  />
                                  <CheckboxLabel
                                    htmlFor={`checkbox-${item.id}-yes`}
                                  >
                                    Yes
                                  </CheckboxLabel>
                                </CheckboxWrapper>
                                <CheckboxWrapper>
                                  <CheckboxInput
                                    type="checkbox"
                                    id={`checkbox-${item.id}-no`}
                                    checked={checkboxValues.some(
                                      (val) =>
                                        val.id === item.id &&
                                        val.answer === "no"
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(item.id, "no")
                                    }
                                  />
                                  <CheckboxLabel
                                    htmlFor={`checkbox-${item.id}-no`}
                                  >
                                    No
                                  </CheckboxLabel>
                                </CheckboxWrapper>
                              </div>
                            </div>
                          </div>
                        ))}
                      <CommentsStyle>
                        <div>
                          <label htmlFor="comment">Additional Comments</label>
                          <input
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            id={"comment"}
                            type="text"
                            placeholder={"Enter your comments"}
                          />
                        </div>
                      </CommentsStyle>
                    </WorkListStyle>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer className={"border-0"}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    dispatch(createInitiatorToggle());
                    dispatch(setCurrentStep(1));
                  }}
                >
                  Close
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    const updated = [];
                    updated.push(...checkboxValues);
                    const updatedInitiator = {
                      ...initiatorData,
                      workDescriptionRequests: updated,
                    };
                    dispatch(saveInitiatorData(updatedInitiator));
                    dispatch(setCurrentStep(currentStep - 1));
                  }}
                >
                  Back
                </Button>
                <ButtonNextCreateinitiator type={"submit"}>
                  Submit for Approval
                </ButtonNextCreateinitiator>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default WorkListModelPage;
