import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreateinitiatorModelStyle } from "../../styles/CreateInitiator.style";
import WorkListModelPage from "./WorkListModelPage";
import CreateInitiatorScreen1 from "../CreateInitiatorScreen1";
import ColdWorkModel from "./ColdWorkModel";
import LiftingScreen from "../LiftingScreen";

const CreateInitiatorModel = ({ permitDetail }) => {
  console.log(permitDetail, "initiator screen open");

  const { createInitiatorModel, currentStep, initiatorData } = useSelector(
    (state) => state.formStepper
  );

  const x = useSelector((state) => state);
  console.log(x);

  console.log(initiatorData, "initiatorData");

  const isLifting =
    initiatorData &&
    initiatorData.selectedTexts.find(
      (el) => el === "Lifting and shifting (L&S)"
    );

  let selectedTexts = [];
  if (initiatorData && initiatorData.selectedTexts) {
    selectedTexts = initiatorData && [...initiatorData.selectedTexts];
    const targetItem = "Lifting and shifting (L&S)";
    const targetIndex = selectedTexts.indexOf(targetItem);

    if (targetIndex !== -1) {
      selectedTexts.splice(targetIndex, 1);
      selectedTexts.splice(0, 0, targetItem);
    }
  }

  console.log(createInitiatorModel);
  return (
    <>
      <CreateinitiatorModelStyle show={createInitiatorModel}>
        {currentStep && currentStep === 1 && (
          <CreateInitiatorScreen1 permitDetail={permitDetail} />
        )}

        {initiatorData &&
          initiatorData.typeOfWork === 1 &&
          currentStep === 2 && (
            <WorkListModelPage workListId={1} permitDetail={permitDetail} />
          )}

        {initiatorData && initiatorData.typeOfWork === 2 && (
          <>
            {isLifting ? (
              <>
                {currentStep && currentStep === 2 && (
                  <LiftingScreen isSubmitBtn={initiatorData.selectedTexts} />
                )}
                {currentStep && currentStep === 3 && (
                  <ColdWorkModel
                    isSubmitBtn={initiatorData.selectedTexts}
                    workListId={currentStep}
                    selectedText={selectedTexts[1]}
                  />
                )}
                {currentStep && currentStep === 4 && (
                  <ColdWorkModel
                    isSubmitBtn={initiatorData.selectedTexts}
                    workListId={currentStep}
                    selectedText={selectedTexts[2]}
                  />
                )}
                {currentStep && currentStep === 5 && (
                  <ColdWorkModel
                    isSubmitBtn={initiatorData.selectedTexts}
                    workListId={currentStep}
                    selectedText={selectedTexts[3]}
                  />
                )}
                {currentStep && currentStep === 6 && (
                  <ColdWorkModel
                    isSubmitBtn={initiatorData.selectedTexts}
                    workListId={currentStep}
                    selectedText={selectedTexts[4]}
                  />
                )}
              </>
            ) : (
              <>
                {currentStep && currentStep === 2 && (
                  <ColdWorkModel
                    isSubmitBtn={initiatorData.selectedTexts}
                    workListId={currentStep}
                    selectedText={initiatorData.selectedTexts[0]}
                  />
                )}
                {currentStep && currentStep === 3 && (
                  <ColdWorkModel
                    isSubmitBtn={initiatorData.selectedTexts}
                    workListId={currentStep}
                    selectedText={initiatorData.selectedTexts[1]}
                  />
                )}
                {currentStep && currentStep === 4 && (
                  <ColdWorkModel
                    isSubmitBtn={initiatorData.selectedTexts}
                    workListId={currentStep}
                    selectedText={initiatorData.selectedTexts[2]}
                  />
                )}
                {currentStep && currentStep === 5 && (
                  <ColdWorkModel
                    isSubmitBtn={initiatorData.selectedTexts}
                    workListId={currentStep}
                    selectedText={initiatorData.selectedTexts[3]}
                  />
                )}
              </>
            )}
          </>
        )}
      </CreateinitiatorModelStyle>
    </>
  );
};

export default CreateInitiatorModel;
