import axios from 'axios';
import config from "../config";


// // const { REACT_APP_BASE_URL } = process.env;
// //
// let token= localStorage.getItem("access_token")
// console.log(token,"token")
// const Api = axios.create({
//   // baseURL: `${REACT_APP_BASE_URL || 'http://65.0.242.35:21000'}`,
//   baseURL: `${config.apiUrl || 'http://65.0.242.35:21000'}`,
//   headers: {
//     Accept: 'application/json',
//     'Content-Type': 'application/json',
//     Authorization: `Bearer ${token}`
//   },
// });
//
// Api.interceptors.response.use(
//   function (response) {
//     return response.data;
//   },
//   function (error) {
//     if (error?.response?.status === 401 || error?.response?.status === 500) {
//       // localStorage.clear();
//     }
//     return Promise.reject(error);
//   }
// );
//
// export default Api;


const Api = axios.create({
  baseURL: `${config.apiUrl || 'http://65.0.242.35:21000'}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// Add an Axios interceptor for requests
Api.interceptors.request.use(
    function (config) {
      const token = localStorage.getItem('access_token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
);

// Add an Axios interceptor for responses
Api.interceptors.response.use(
    function (response) {
      return response.data;
    },
    function (error) {
      if (error?.response?.status === 401) {
        // Handle unauthorized or server error
        localStorage.clear();
        window.location = '/sign-in'
      }
      return Promise.reject(error);
    }
);

export default Api;
