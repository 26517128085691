import React, { useState } from "react";
import { ReactComponent as DashBoard } from "../assets/Dashboard/Dashboard.svg";
import { ReactComponent as Depart } from "../assets/Dashboard/Department.svg";
import { ReactComponent as Notification } from "../assets/Dashboard/Notification.svg";
import { ReactComponent as Permit } from "../assets/Dashboard/Permit.svg";
import { ReactComponent as Profile } from "../assets/Dashboard/Profile.svg";
import { ReactComponent as Report } from "../assets/download_report_icon.svg";
import {
  MenuItem,
  DrawerButton,
  DrawerIcon,
  DrawerIconName,
  DrawerIcons,
  DrawerImg,
  DrawerImgIcon,
  DrawerLi,
  DrawerUl,
  DrawerWord,
  MainDrawer,
  MainDrawerButton,
  NavLinkStyled,
  BottomImgDrawer,
} from "../styles/Drawer.style";
import Logo from "../assets/full-logo.png";
import RedBtn from "../assets/RedBtn.png";
import { Link, useLocation, useMatch, NavLink } from "react-router-dom";
import LogoutModel from "../utils/models/LogoutModel";
import { useSelector } from "react-redux";
import userIcon from "../assets/userProfile.jpg";
import BottomImgTree from "../assets/drawer_img.svg";
import config from "../config";

const Drawer = () => {
  const [open, setOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const location = useLocation();
  const isOpen = useSelector((state) => state.signIn.drawer);
  // console.log(isOpen,"isOpen")
  const user = useSelector((state) => state.signIn.adminData);

  const handleOpen = () => {
    setOpen(true);
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  let data = [];

  if (user && user.roleId === config.role.adminRole) {
    data.push(
      {
        name: "Dashboard",
        icon: <DashBoard />,
        path: "/dashboard",
      },
      {
        name: "Users",
        icon: <Profile />,
        path: "/user",
      },
      {
        name: "Department & Shift",
        icon: <Depart />,
        path: "/department",
      },
      {
        name: "Permit",
        icon: <Permit />,
        path: "/permit",
      },

      {
        name: "Notification",
        icon: <Notification />,
        path: "/notification",
      }
    );
  }
  if (user && user.roleId === config.role.issuerRole) {
    data.push(
      // {
      //     name: 'Dashboard',
      //     icon: <DashBoard/>,
      //     path: '/dashboard'
      // },
      // {
      //     name: 'Users',
      //     icon:  <Profile />,
      //     path:'/user'
      // },
      // {
      //     name: 'Department & Shift',
      //     icon: <Depart/>,
      //     path: '/department'
      // },
      {
        name: "Permit",
        icon: <Permit />,
        path: "/dashboard",
      },

      {
        name: "Notification",
        icon: <Notification />,
        path: "/notification",
      },
      {
        name: "Report",
        icon: <Report />,
        path: "/report",
      }
    );
  }

  if (user && user.roleId === config.role.custodianRole) {
    data.push(
      {
        name: "Permit",
        icon: <Permit />,
        path: "/dashboard",
      },

      {
        name: "Notification",
        icon: <Notification />,
        path: "/notification",
      },
      {
        name: "Report",
        icon: <Report />,
        path: "/report",
      }
    );
  }

  if (user && user.roleId === config.role.coOrdinatorRole) {
    data.push(
      {
        name: "Permit",
        icon: <Permit />,
        path: "/dashboard",
      },

      {
        name: "Notification",
        icon: <Notification />,
        path: "/notification",
      },
      {
        name: "Report",
        icon: <Report />,
        path: "/report",
      }
    );
  }

  return (
    <MainDrawer isOpen={isOpen}>
      <div>
        <Link to={"/dashboard"}>
          <DrawerIcon isOpen={isOpen} src={Logo} alt="" srcSet={Logo} />
        </Link>
      </div>
      <div>
        <DrawerUl>
          {data?.map((item, index) => {
            return (
              <DrawerLi
                isOpen={isOpen}
                key={index + 1}
                $isActive={isActive(item.to)}
              >
                <NavLinkStyled isOpen={isOpen} to={item.path}>
                  <DrawerIcons $isActive={isActive(item.to)}>
                    {item.icon}
                  </DrawerIcons>
                  {isOpen && <DrawerIconName>{item.name}</DrawerIconName>}
                </NavLinkStyled>
              </DrawerLi>
            );
          })}
        </DrawerUl>
      </div>
      <MainDrawerButton>
        <DrawerButton isOpen={isOpen}>
          {isOpen ? (
            <>
              <Link to={"/profile"} className={"profile_drawer"}>
                <DrawerImg>
                  <DrawerImgIcon src={userIcon} alt="Logo" srcSet={userIcon} />
                </DrawerImg>
                <DrawerWord>{user.empName}</DrawerWord>
              </Link>
              <div
                className={"logOut"}
                onClick={() => handleShow()}
                style={{ cursor: "pointer" }}
              >
                <DrawerImgIcon src={RedBtn} alt="Logo" srcSet="" />
              </div>
            </>
          ) : (
            <div>
              <DrawerImgIcon
                onClick={() => handleShow()}
                src={RedBtn}
                alt="Logo"
                srcSet=""
              />
            </div>
          )}
        </DrawerButton>
        <p
          className={"text-light text-center pt-2 m-0"}
          style={{ fontSize: isOpen ? "16px" : "8px" }}
        >
          PTW © 2022. All Rights Reserved
        </p>
        <BottomImgDrawer isOpen={isOpen}>
          <img src={BottomImgTree} alt="" srcSet="" className="img-fluid" />
        </BottomImgDrawer>
      </MainDrawerButton>
      <LogoutModel show={show} handleClose={handleClose} />
    </MainDrawer>
  );
};

export default Drawer;
