import { takeLatest, call, put } from 'redux-saga/effects';
import {GET_ALL_PERMIT} from "../constant";
import Api from "../../api/api";
import {getAllPermitFaield, getAllPermitRequest, getAllPermitSuccess} from "../Slice/permitSlice";

function* getAllPermit(action){
    try {
        yield put(getAllPermitRequest());
        console.log(action.payload,'action.payload');
        const response = yield call(Api.post,"/api/permit/getAllPermits",action.payload)
        // console.log(response,"getAllPermits/list============")
        const data= {
            parmits:response.data.permitResponses,
                isShiftPerson:response.data.isShiftPerson
        }
        yield put(getAllPermitSuccess(data))
    }catch (error){
        console.log(error)
        yield put(getAllPermitFaield(error))
    }
}

function* permitSaga(){
    yield takeLatest(GET_ALL_PERMIT,getAllPermit)
}
export default permitSaga;