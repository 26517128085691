import {styled} from "styled-components";

export const ProfileStyle = styled.div`
  .profile_main {
    overflow-y: auto;
    height: 80vh;
    background: #FFFFFF;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    &::-webkit-scrollbar-thumb {
      background-color: #2D3E50;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #CECECE;
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 10px;
    }
    * {
      scrollbar-width: auto;
      scrollbar-color: #8f54a0 #ffffff;
    }
  }

  .avatar {
    padding-top: 25px;
    text-align: center;

    h2 {
      color: #2D3E50;
      font-size: 24px;
      padding: 6px 0;
      padding-bottom: 0;
    }

    button {
      color: #2D3E50;
      padding: 4px 15px;
      border: 1px solid #2D3E50;
      border-radius: 5px;
      background: transparent;
    }

    .profile_upload_main {
      display: inline-block;
      position: relative;

      .select_photo {
        position: absolute;
        bottom: 4px;
        right: -20px;
        cursor: pointer;
      }
    }
  }

  .profileDetails {
    width: 100%;
    display: flex;
    padding-top: 25px;
    color: #2D3E50;

    div {
      width: 30%;
      background: #FFFFFF;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      margin: 12px;
      padding: 25px;
      position: relative;

      p {
        font-weight: bold;
        margin-bottom: 10px;
        margin-top: 5px;
      }

      &::before {
        content: '';
        border-left: 2px solid #2D3E50;
        height: 85px;
        width: 100%;
        left: 0px;
        position: absolute;
        z-index: 1;
        top: 15px;
      }
    }
  }

  .description {
    padding: 15px 45px;
    color: #2D3E50;

    h4 {
      font-size: 18px;
    }

    span {
      font-size: 14px;
    }
  }
`;

export const ProfileAvatar= styled("img")`
  width: 100%;;
  max-width:120px;
  border-radius: 150px;
  
`
export const ProfileCamera = styled("img")({

})