import {createSlice} from "@reduxjs/toolkit";


export const helpSlice = createSlice({
    name:'help',
    initialState:{
        loading:false,
        error:null,
        help:null,
    },
    reducers:{
        // notification request
        postHelpRequest:(state) => {
            state.loading = !state.loading;
        },
        postHelpSuccess:(state,action) => {
            state.loading = !state.loading;
            state.help = action.payload;
        },
        postHelpFaield:(state,message)=>{
            state.loading = !state.loading;
            state.error = message
        },
        helpClear:(state)=>{
            state.help = null;
        },
    }
})

export const {
    postHelpRequest,
    postHelpSuccess,
    postHelpFaield,
    helpClear,
} = helpSlice.actions

export default helpSlice.reducer