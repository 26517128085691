import Api from "../../api/api";
import {POST_HELP} from "../constant";
import {takeLatest,call, put} from "redux-saga/effects";
import {postHelpFaield, postHelpRequest, postHelpSuccess} from "../Slice/helpSlice";

function* postHelp(action){
    try {
        yield put(postHelpRequest());
        const response = yield call(Api.post,`/api/saveHelp`,action.payload)
        // console.log(response,"/api/saveHelp <== response")
        yield put(postHelpSuccess(response?.status))
    }catch (error){
        console.log(error)
        yield put(postHelpFaield(error))
    }
}

function* helpSaga(){
    yield  takeLatest(POST_HELP,postHelp)
}
export default helpSaga;