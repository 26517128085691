import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import {
  ButtonNextCreateinitiator,
  CheckboxInputLifting,
  CheckListForPermitStyle,
  ColdWorkOptionContainer,
  CreateInitiatorBodyStyle,
  CreateInitiatorFormStyle,
  CreateInitiatorScreen,
  LiftingShifting,
  PermitValidity,
  ToggleButtonCould,
  ToggleButtonStyle,
} from "../styles/CreateInitiator.style";
import { SelectMain } from "../styles/AddUserModel.style";
import { ButtonGroup } from "react-bootstrap";
import WorkListModelPage from "./models/WorkListModelPage";
import Button from "react-bootstrap/Button";
import {
  createInitiatorToggle,
  saveInitiatorData,
  setCurrentStep,
} from "../store/Slice/formStepperSlice";
import { useFormik } from "formik";
import { POST_ISSUER_PERMIT_REQUEST } from "../store/constant";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  permitId: yup.string().required("This field is required"),
  coordinatorName: yup.string().required("This field is required"),

  dateTime: yup.string().required("This field is required"),
  manPower: yup.string().required("This field is required"),
  areaLocation: yup.string().required("This field is required"),

  descriptionOfWork: yup.string().required("This field is required"),
  // typeOfWork: yup.array().min(1, 'At least select one department is required').required('At least select one department is required'),
  // departmentId:yup.array().min(1, 'At least select one department is required').required('At least select one department is required'),
  departmentId: yup.string().required("This field is required"),
  // selectedTexts:,

  // userId: yup.string().required("This field is required"),
  // emailId: yup
  //     .string()
  //     .email("Please enter valid email")
  //     .required("This field is required"),
  // comments
  // permitValidityHr:yup.string().required("This field is required"),

  // Lifting and shifting
  // typeOfLifting:yup.string().required("This field is required"),

  // check list for permit
  // operatingComments:yup.string().required("This field is required"),
  // typeOfGasTesting:yup.string().required("This field is required"),
});

const radios = [
  { name: "Hot work", value: "1" },
  { name: "Cold work", value: "2" },
];
const checkboxes = [
  { id: "1", label: "Yes" },
  { id: "0", label: "No" },
];

const coldWorkOptions = [
  { name: "Confined Space Entry (CSE)", value: "Confined Space Entry (CSE)" },
  { name: "Work at height (WAH)", value: "Work at height (WAH)" },
  { name: "Electrical Permit (EP)", value: "Electrical Permit (EP)" },
  { name: "Lifting and shifting (L&S)", value: "Lifting and shifting (L&S)" },
  { name: "Excavation Certificate (EC)", value: "Excavation Certificate (EC)" },
];
function formatDateToInputValue(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

  return formattedDate;
}

const CreateInitiatorScreen1 = ({ permitDetail }) => {
  const [show, setShow] = useState(false);
  const [radioValue, setRadioValue] = useState("1");

  const dispatch = useDispatch();
  const { createInitiatorModel, initiatorData, currentStep } = useSelector(
    (state) => state.formStepper
  );

  const { departmentList } = useSelector((state) => state.issuerPermit);
  const { adminData } = useSelector((state) => state.signIn);

  const {
    handleSubmit,
    handleChange,
    errors,
    isSubmitting,
    setFieldValue,
    handleReset,
    values,
    touched,
  } = useFormik({
    initialValues: {
      permitId: permitDetail ? permitDetail.permitNumber : "",
      coordinatorName:
        permitDetail && permitDetail.lastUpdatedBy
          ? permitDetail.lastUpdatedBy
          : "",
      dateTime: permitDetail
        ? formatDateToInputValue(permitDetail.dateTime)
        : "",
      manPower: initiatorData ? initiatorData.manPower : "",
      areaLocation: permitDetail ? permitDetail.areaLocation : "",
      departmentId: permitDetail ? permitDetail.departmentIds[0] : "",
      descriptionOfWork: permitDetail ? permitDetail.comment : "",
      typeOfWork: initiatorData
        ? JSON.stringify(initiatorData.typeOfWork)
        : "2",
      selectedTexts: initiatorData
        ? initiatorData.selectedTexts
        : ["Confined Space Entry (CSE)", "Work at height (WAH)"],
    },
    validationSchema,
    onSubmit(values) {
      if (values) {
        // dispatch({type: POST_ISSUER_PERMIT_REQUEST, payload: values})
        const saveInitiatorFullData = {
          ...values,
          manPower: parseInt(values.manPower),
          departmentId: parseInt(values.departmentId),
          typeOfWork: parseInt(values.typeOfWork),
          workDescriptionRequests: initiatorData
            ? initiatorData.workDescriptionRequests
            : [],
          permitId: permitDetail.id,
          userId: adminData && adminData.id ? adminData.id : "",
        };
        dispatch(saveInitiatorData(saveInitiatorFullData));

        dispatch(setCurrentStep(currentStep + 1));
      }
    },
  });

  const handleToggle = (value) => {
    const { selectedTexts } = values;
    if (selectedTexts && selectedTexts.includes(value)) {
      setFieldValue(
        "selectedTexts",
        selectedTexts.filter((selected) => selected !== value)
      );
    } else {
      setFieldValue("selectedTexts", [...selectedTexts, value]);
    }
  };

  return (
    <>
      {currentStep && currentStep === 1 && (
        <form onSubmit={handleSubmit} name={"create_initiator"}>
          <Modal.Header className={"border-0"}>
            <Modal.Title>
              {" "}
              <span style={{ fontSize: "36px", lineHeight: "1.5rem" }}>
                +
              </span>{" "}
              Create Intiator
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreateInitiatorBodyStyle>
              <CreateInitiatorScreen>
                <CreateInitiatorFormStyle>
                  <div>
                    <div className={"container"}>
                      <div className={"row input_FIeld_main"}>
                        <div className={"col-6"}>
                          <div className={"input_main"}>
                            <label htmlFor="permit_number">Permit Number</label>
                            <input
                              onChange={handleChange}
                              name={"permitId"}
                              disabled={true}
                              value={values.permitId}
                              type="text"
                              placeholder={"A158-5849-8078"}
                              id={"permit_number"}
                            />
                            {touched.permitId && errors.permitId && (
                              <>
                                <br />
                                <p className={"text-danger d-block"}>
                                  {errors.permitId}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                        <div className={"col-6"}>
                          <div className={"input_main"}>
                            <label htmlFor="coordinatorName">
                              Coordinator (Ancillary) Name
                            </label>
                            <input
                              name={"coordinatorName"}
                              onChange={handleChange}
                              value={values.coordinatorName}
                              type="text"
                              disabled={true}
                              placeholder={"Coordinator (Ancillary) Name"}
                              id={"coordinatorName"}
                            />
                            {touched.coordinatorName &&
                              errors.coordinatorName && (
                                <>
                                  <br />
                                  <p className={"text-danger d-block"}>
                                    {errors.coordinatorName}
                                  </p>
                                </>
                              )}
                          </div>
                        </div>
                        <div className={"col-6"}>
                          <div className={"input_main"}>
                            <label htmlFor="date_time">Date and time</label>
                            <input
                              type="datetime-local"
                              placeholder={"04 April, 2022   11:29:55 PM"}
                              disabled={true}
                              id={"date_time"}
                              name={"dateTime"}
                              value={values.dateTime}
                              onChange={handleChange}
                            />
                            {touched.dateTime && errors.dateTime && (
                              <>
                                <br />
                                <p className={"text-danger d-block"}>
                                  {errors.dateTime}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                        <div className={"col-6"}>
                          <div className={"input_main"}>
                            <label htmlFor="manPower">Required Manpower</label>
                            <input
                              type="text"
                              placeholder={"40"}
                              id={"manPower"}
                              name={"manPower"}
                              value={values.manPower}
                              onChange={handleChange}
                            />
                            {touched.manPower && errors.manPower && (
                              <>
                                <br />
                                <p className={"text-danger d-block"}>
                                  {errors.manPower}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                        <div className={"col-6"}>
                          <div className={"input_main"}>
                            <label htmlFor="area_local">
                              Area . Location of work
                            </label>
                            <input
                              type="text"
                              placeholder={"Bidhan Nagar, Kolkata"}
                              id={"area_local"}
                              disabled={true}
                              name={"areaLocation"}
                              value={values.areaLocation}
                              onChange={handleChange}
                            />
                            {touched.areaLocation && errors.areaLocation && (
                              <>
                                <br />
                                <p className={"text-danger d-block"}>
                                  {errors.areaLocation}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                        <div className={"col-6"}>
                          <div className={"input_main"}>
                            <label htmlFor="department">Department</label>
                            <SelectMain className="col-md-6 select_text w-100">
                              <select
                                className="form-select p-0"
                                name={"departmentId"}
                                onChange={(e) =>
                                  setFieldValue("departmentId", e.target.value)
                                }
                                value={values.departmentId}
                                disabled={true}
                                aria-label="Default select example"
                              >
                                {departmentList &&
                                  departmentList.map((el) => {
                                    return (
                                      <option
                                        key={el.id}
                                        value={el.id}
                                        disabled
                                      >
                                        {el.name}
                                      </option>
                                    );
                                  })}
                                {/* <option value="5">Enviroment</option>
                                <option value="6">Electricity</option> */}
                              </select>
                              {touched.departmentId && errors.departmentId && (
                                <p className={"text-danger d-block"}>
                                  {errors.departmentId}
                                </p>
                              )}
                            </SelectMain>
                          </div>
                        </div>
                        <div className={"col-12"}>
                          <div className={"input_main"}>
                            <label htmlFor="descriptionOfWork">comments</label>
                            <input
                              type="text"
                              placeholder={"comments "}
                              id={"descriptionOfWork"}
                              name={"descriptionOfWork"}
                              disabled={true}
                              value={values.descriptionOfWork}
                              onChange={handleChange}
                            />
                            {touched.descriptionOfWork &&
                              errors.descriptionOfWork && (
                                <>
                                  <br />
                                  <p className={"text-danger d-block"}>
                                    {errors.descriptionOfWork}
                                  </p>
                                </>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"container my-3"}>
                    <div className={"col-12"}>
                      <div className={"d-flex align-items-baseline"}>
                        <div>
                          <h4 className={"m-0"}>Does the work involve?</h4>
                        </div>
                        <ButtonGroup className={"ms-4"}>
                          {radios &&
                            radios.map((radio, idx) => (
                              <ToggleButtonStyle
                                key={idx}
                                id={`radio-${idx}`}
                                type="radio"
                                name="typeOfWork"
                                value={radio.value}
                                checked={
                                  values.typeOfWork &&
                                  values.typeOfWork === radio.value
                                }
                                onChange={(e) =>
                                  setFieldValue(
                                    "typeOfWork",
                                    e.currentTarget.value
                                  )
                                }
                              >
                                {radio.name}
                              </ToggleButtonStyle>
                            ))}
                        </ButtonGroup>
                      </div>
                    </div>

                    {values && values.typeOfWork === "2" && (
                      <div className={"col-12 mt-3"}>
                        <ColdWorkOptionContainer>
                          {coldWorkOptions.map((option, index) => (
                            <ToggleButtonCould
                              type={"button"}
                              key={index}
                              selected={
                                values.selectedTexts &&
                                values.selectedTexts.includes(option.value)
                              }
                              onClick={() => handleToggle(option.value)}
                            >
                              {option.name}
                            </ToggleButtonCould>
                          ))}
                        </ColdWorkOptionContainer>
                        {touched.coldWorkList && errors.coldWorkList && (
                          <p className={"text-danger d-block"}>
                            {errors.coldWorkList}
                          </p>
                        )}
                      </div>
                    )}
                    <div className={"col-12"}>
                      <div className="col-12 mt-3">
                        <PermitValidity>
                          <label htmlFor="permitValidityHr">
                            Permit Validity (Hrs.)
                          </label>
                          <select
                            id="permitValidityHr"
                            name="permitValidityHr"
                            onChange={handleChange}
                            value={values.permitValidityHr}
                          >
                            <option value="8">8</option>
                            <option value="16">16</option>
                            <option value="24" selected={true}>
                              24
                            </option>
                          </select>
                        </PermitValidity>
                        {touched.permitValidityHr && errors.permitValidityHr ? (
                          <div>{errors.permitValidityHr}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </CreateInitiatorFormStyle>
              </CreateInitiatorScreen>
              {/*{currentStep && currentStep === 2 && values && values.typeOfWork === "1" &&*/}
              {/*    <WorkListModelPage workListId={1}/>}*/}
              {/*{currentStep && currentStep === 2 && values && values.typeOfWork === "2" && <>*/}
              {/*    <LiftingShifting>*/}
              {/*        <div className={"form_main_lifting"}>*/}
              {/*            <div className={"container"}>*/}
              {/*                <div className={"single_select"}>*/}
              {/*                    <h5>1. Type of lifiting</h5>*/}
              {/*                    <select name="typeOfLifting" onChange={handleChange}*/}
              {/*                            value={values.typeOfLifting} id="typeOfLifting">*/}
              {/*                        <option value="1">select lifting</option>*/}
              {/*                        <option value="2">Lifting 1</option>*/}
              {/*                    </select>*/}
              {/*                </div>*/}
              {/*                <div className={"single_select comment"}>*/}
              {/*                    <h5>2. Maximum Operating Radius</h5>*/}
              {/*                    <input type="text" name={"operatingComments"}*/}
              {/*                           value={values.operatingComments}*/}
              {/*                           placeholder={"Enter your comments"}/>*/}
              {/*                </div>*/}
              {/*                <div className={"single_select comment mt-3"}>*/}
              {/*                    <h5>3. Need more permit time?</h5>*/}
              {/*                    <CheckboxInputLifting className={"hb-colour-options"}>*/}
              {/*                        <div>*/}
              {/*                            <input type="radio" name="radio-group" id="yes" value="Yes"/>*/}
              {/*                            <label htmlFor="yes">*/}
              {/*                                Yes*/}
              {/*                            </label>*/}
              {/*                        </div>*/}
              {/*                        <div>*/}
              {/*                            <input type="radio" name="radio-group" id="no" value="No"/>*/}
              {/*                            <label htmlFor="no">*/}
              {/*                                No*/}
              {/*                            </label>*/}
              {/*                        </div>*/}
              {/*                    </CheckboxInputLifting>*/}
              {/*                    <input type="text" name={"operatingComments"}*/}
              {/*                           value={values.operatingComments}*/}
              {/*                           placeholder={"Enter your comments"}/>*/}
              {/*                </div>*/}
              {/*            </div>*/}
              {/*            <div className={"container"}>*/}
              {/*                <div className={"update_request"}>*/}
              {/*                    <button>*/}
              {/*                        Update Request*/}
              {/*                    </button>*/}
              {/*                    <button>*/}
              {/*                        Permit increase request*/}
              {/*                    </button>*/}
              {/*                </div>*/}
              {/*            </div>*/}
              {/*        </div>*/}
              {/*    </LiftingShifting>*/}
              {/*</>*/}
              {/*}*/}
              {currentStep && currentStep === 3 && (
                <>
                  <CheckListForPermitStyle>
                    <div className={"container"}>
                      <ul>
                        <li>
                          <div>
                            <p>
                              1. Is Risk Assessment being been carried out for
                              the job?
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </CheckListForPermitStyle>
                </>
              )}
            </CreateInitiatorBodyStyle>
          </Modal.Body>
          <Modal.Footer className={"border-0"}>
            <Button
              variant="secondary"
              onClick={() => {
                dispatch(createInitiatorToggle());
                dispatch(setCurrentStep(1));
              }}
            >
              Close
            </Button>
            {/*{currentStep >= 2 ?*/}
            {/*    <Button variant="secondary" onClick={() => dispatch(setCurrentStep(currentStep - 1))}>*/}
            {/*        Back*/}
            {/*    </Button> : ''*/}
            {/*}*/}
            <ButtonNextCreateinitiator type={"submit"}>
              Next
            </ButtonNextCreateinitiator>
          </Modal.Footer>
        </form>
      )}
    </>
  );
};

export default CreateInitiatorScreen1;
