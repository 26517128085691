import {styled} from "styled-components";

export const SingleBoxMain = styled("div")({
    "p":{
        margin:"0",
    }
})
export const SingleBoxStyle = styled.div`
  background-color: ${props => props.color};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  color: #fff;
  text-align: center;
  padding:15px 45px;
  
`

export const TabelDashboard = styled.div`
  .tabel_main_dashborad {
    .users_head {
      align-items: center;
      h3{
        margin-bottom: 0;
        padding-bottom: 0 !important;
      }
      .custodian{
      padding: 2px 8px;
        form{
          select{
            font-size: 15px;
            color: #2D3E50;
            padding: 8px 12px;
            border: none;
            background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
          }
        }
      }
      div {
        .users_head {
          h3 {
            color: #6495f2 !important;
          }
        }
      }
    }
  }
`;