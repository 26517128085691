import React from 'react';
import {Navigate, Outlet, Route} from 'react-router-dom';
import {useSelector} from "react-redux";

export const ProtectedRoute =()=> {
    // const adminData = useSelector((state)=>state.signIn.adminData);
    const token = localStorage.getItem("access_token")
    const nav = token && token
    return !nav ? <Navigate to="/sign-in" replace/> : <Outlet/>
};
