import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as Close } from "../../assets/close_icon.svg";
import { AddUpdateBtn, InputMain } from "../../styles/AddUserModel.style";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_DEPARTMENT_REQUEST_FOR_ISSUER,
  GET_PERMIT_BY_ID,
  POST_ISSUER_PERMIT_REQUEST,
} from "../../store/constant";
import Button from "react-bootstrap/Button";
import {
  CheckboxContainer,
  CheckboxInput,
  CheckboxInputDepart,
  CheckboxLabel,
  CreatePermitStyle,
  SelectDepartment,
} from "../../styles/CreatePermitModel.style";

const today = new Date();
// today.setHours(0, 0, 0, 0);

const tagCollection = [
  { value: 1, label: "Mining" },
  { value: 2, label: "Two" },
  { value: 3, label: "Three" },
];

function CreatePermitModel(props) {
  const dispatch = useDispatch();
  const { permitSuccess, loading, departmentList } = useSelector(
    (state) => state.issuerPermit
  );
  const { adminData } = useSelector((state) => state.signIn);

  const isUpdate = !!props.permit; // Check if it's an update
  console.log(isUpdate, "isUpdate");

  const validationSchema = yup.object().shape({
    permitNumber: yup.string().required("This field is required"),
    emailId: yup
      .string()
      .email("Please enter valid email")
      .required("This field is required"),
    empId: yup.string().required("This field is required"),
    dateTime: yup.date().when("isUpdate", {
      is: false, // Apply the validation when isUpdate is false
      then: yup
        .date()
        .required("This field is required")
        .min(today, "Date cannot be in the past"),
    }),
    // dateTime: yup
    //   .string()
    //   .required("This field is required")
    //   .test(
    //     "is-future-date",
    //     "Please select a future date and time",
    //     function (value) {
    //       if (!value) {
    //         // If no value provided, the validation is considered successful
    //         return true;
    //       }

    //       const selectedDate = new Date(value);
    //       const currentDate = new Date();

    //       // Compare the selected date with the current date
    //       if (selectedDate > currentDate) {
    //         return true; // Validation successful
    //       }

    //       return false; // Validation failed
    //     }
    //   ),
    areaLocation: yup.string().required("This field is required"),

    departmentIds: yup
      .array()
      .min(1, "At least select one department is required")
      .required("At least select one department is required"),
  });

  const {
    handleSubmit,
    handleChange,
    errors,
    isSubmitting,
    setFieldValue,
    handleReset,
    values,
    touched,
  } = useFormik({
    initialValues: isUpdate
      ? { ...props.permit, departmentIds: props.permit.departmentIds || [] }
      : {
          permitNumber: "",
          emailId: adminData.emailId ? adminData.emailId : "",
          empId: "",
          dateTime: "",
          areaLocation: "",
          comment: "",
          departmentIds: [],
        },

    validationSchema,
    onSubmit: (formValues) => {
      // Create and Update Permit
      dispatch({ type: POST_ISSUER_PERMIT_REQUEST, payload: formValues });
      console.log("save permit");
      if (isUpdate && props.permit.id) {
        console.log("get permit");
        dispatch({ type: GET_PERMIT_BY_ID, payload: props.permit.id });
      }
    },
  });

  // const  permitdata = props.permit
  // console.log(permitdata,"permitdata")

  // useEffect(()=>{
  //     if(permitSuccess){
  //         props.handleClose()
  //     }
  // },[permitSuccess])

  const handleCheckboxChange = (tagValue, isChecked) => {
    let updatedTags;

    if (isChecked) {
      updatedTags = [...values.departmentIds, tagValue];
    } else {
      updatedTags = values.departmentIds.filter((tag) => tag !== tagValue);
    }
    setFieldValue("departmentIds", updatedTags);
  };

  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    if (isUpdate) {
      // Format the date and time for the 'datetime-local' input type
      const formattedDateTime = new Date(values.dateTime)
        .toISOString()
        .substr(0, 16);
      // setFieldValue("dateTime", formattedDateTime);
    } else {
      // Generate a unique permit number when the modal is opened

      const generateDateAndTime = () => {
        const DAT = new Date();
        let dd = DAT.getDate();
        let mm = DAT.getMonth() + 1;
        let yyyy = DAT.getFullYear();
        let hours = DAT.getHours();
        let min = DAT.getMinutes();

        if (dd < 10) {
          dd = "0" + dd;
        }

        if (mm < 10) {
          mm = "0" + mm;
        }
        if (min < 10) {
          min = "0" + min;
        }
        console.log(`${yyyy}-${mm}-${dd}T${hours}:${min}`);
        setFieldValue("dateTime", `${yyyy}-${mm}-${dd}T${hours}:${min}`);
      };

      const generatePermitNumber = () => {
        const prefix = "A"; // Customize the prefix as needed
        const randomNumber1 = getRandomInt(1000, 9999);
        const randomNumber2 = getRandomInt(1000, 9999);
        const randomNumber3 = getRandomInt(1000, 9999);
        const permitNumber = `${prefix}${randomNumber1}-${randomNumber2}-${randomNumber3}`;
        setFieldValue("permitNumber", permitNumber);
      };
      setFieldValue("empId", adminData.empId);
      generatePermitNumber(); // Call the function when the modal is opened
      generateDateAndTime();
    }
  }, []);

  const handleRadioChange = (tagValue) => {
    setFieldValue("departmentIds", [tagValue]); // Use 'departmentId' instead of 'departmentIds'
  };

  let dateToday = new Date().toISOString().slice(0, 16);

  return (
    <div>
      <CreatePermitStyle backdrop="static" keyboard={false} {...props}>
        <div style={{ height: "96vh", overflowY: "scroll" }}>
          {loading && loading ? (
            <>
              <p className={"p-5"}>loading...</p>
            </>
          ) : (
            <>
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  <span style={{ fontSize: "30px" }}>+</span> Create Permit
                </Modal.Title>
                <Button
                  variant="secondary"
                  style={{ background: "transparent", border: "0" }}
                  onClick={props.onHide}
                >
                  <Close />
                </Button>
              </Modal.Header>
              <Modal.Body>
                <div className="main-modal">
                  <form onSubmit={handleSubmit}>
                    <div className="main-details">
                      <div className="row">
                        <div className="col-md-12">
                          <InputMain className="input-group d-block mb-3">
                            <label htmlFor="permitNumber">Permit Number</label>
                            <input
                              type="text"
                              name={"permitNumber"}
                              disabled={true}
                              onChange={handleChange}
                              value={values.permitNumber}
                              className="form-control w-100 mb-2 "
                              placeholder="A158-5849-8078"
                              id={"permitNumber"}
                            />
                            {touched.permitNumber && errors.permitNumber && (
                              <p className={"text-danger d-block"}>
                                {errors.permitNumber}
                              </p>
                            )}
                          </InputMain>
                          <InputMain className="input-group d-block mb-3">
                            <label htmlFor="permitNumber">Email Id</label>
                            <input
                              type="email"
                              name={"emailId"}
                              onChange={handleChange}
                              value={values.emailId}
                              className="form-control w-100 mb-2 "
                              placeholder="amitsingh2022@hotmail.com"
                              id={"emailId"}
                            />
                            {touched.emailId && errors.emailId && (
                              <p className={"text-danger d-block"}>
                                {errors.emailId}
                              </p>
                            )}
                          </InputMain>
                          <InputMain className="input-group d-block mb-3">
                            <label htmlFor="permitNumber">Employee Id</label>
                            <input
                              type="text"
                              name={"empId"}
                              disabled={true}
                              onChange={handleChange}
                              value={values.empId}
                              className="form-control w-100 mb-2 "
                              placeholder="2022/N1000"
                              id={"empId"}
                            />
                            {touched.empId && errors.empId && (
                              <p className={"text-danger d-block"}>
                                {errors.empId}
                              </p>
                            )}
                          </InputMain>
                          {isUpdate ? (
                            <InputMain className="input-group d-block mb-3">
                              <label htmlFor="permitNumber">
                                Date and Time
                              </label>
                              <input
                                type="datetime-local"
                                name="dateTime"
                                onChange={handleChange}
                                value={values.dateTime}
                                className="form-control w-100 mb-2 "
                                placeholder="amitsingh2022@hotmail.com"
                                id="dateTime"
                                // disabled={true}
                                // min={dateToday}
                              />
                              {/*<input*/}
                              {/*    type='datetime-local'*/}
                              {/*    name={"dateTime"}*/}
                              {/*    onChange={handleChange}*/}
                              {/*    value={values.dateTime ? new Date(values.dateTime).toISOString().substr(0, 16) : ""} // Convert Date to ISO string format*/}
                              {/*    className='form-control w-100 mb-2 '*/}
                              {/*    placeholder='amitsingh2022@hotmail.com'*/}
                              {/*    id={"dateTime"}*/}
                              {/*/>*/}
                              {touched.dateTime && errors.dateTime && (
                                <p className={"text-danger d-block"}>
                                  {errors.dateTime}
                                </p>
                              )}
                            </InputMain>
                          ) : (
                            <InputMain className="input-group d-block mb-3">
                              <label htmlFor="permitNumber">
                                Date and Time
                              </label>
                              <input
                                type="datetime-local"
                                name="dateTime"
                                onChange={handleChange}
                                value={values.dateTime}
                                className="form-control w-100 mb-2 "
                                placeholder="amitsingh2022@hotmail.com"
                                id="dateTime"
                                min={dateToday}
                                disabled={true}
                              />
                              {/*<input*/}
                              {/*    type='datetime-local'*/}
                              {/*    name={"dateTime"}*/}
                              {/*    onChange={handleChange}*/}
                              {/*    value={values.dateTime ? new Date(values.dateTime).toISOString().substr(0, 16) : ""} // Convert Date to ISO string format*/}
                              {/*    className='form-control w-100 mb-2 '*/}
                              {/*    placeholder='amitsingh2022@hotmail.com'*/}
                              {/*    id={"dateTime"}*/}
                              {/*/>*/}
                              {touched.dateTime && errors.dateTime && (
                                <p className={"text-danger d-block"}>
                                  {errors.dateTime}
                                </p>
                              )}
                            </InputMain>
                          )}
                          <InputMain className="input-group d-block mb-3">
                            <label htmlFor="permitNumber">Area/Location </label>
                            <input
                              type="text"
                              name={"areaLocation"}
                              onChange={handleChange}
                              value={values.areaLocation}
                              className="form-control w-100 mb-2 "
                              placeholder="Bidhan Nagar, Kolkata"
                              id={"areaLocation"}
                            />
                            {touched.areaLocation && errors.areaLocation && (
                              <p className={"text-danger d-block"}>
                                {errors.areaLocation}
                              </p>
                            )}
                          </InputMain>
                          <InputMain className="input-group d-block mb-3">
                            <label htmlFor="permitNumber">
                              Description of Requirement
                            </label>
                            {/* <span
                              style={{
                                fontSize: "12px",
                                color: "#6E7A86",
                                display: "block",
                              }}
                            >
                              Is Risk Assessment being been carried out for the
                              jobIs Risk Assessment being been carried out for
                              the job
                            </span> */}
                            <input
                              type="text"
                              name={"comment"}
                              onChange={handleChange}
                              value={values.comment}
                              className="form-control w-100 mb-2"
                              placeholder="Is Risk Assessment being been carried out for the
                              jobIs Risk Assessment being been carried out for
                              the job"
                              id={"comment"}
                            />
                            {touched.comment && errors.comment && (
                              <p className={"text-danger d-block"}>
                                {errors.comment}
                              </p>
                            )}
                          </InputMain>
                        </div>
                        <div className={"col-12"}>
                          <p>Ancillary Department</p>
                          <SelectDepartment>
                            {departmentList &&
                              departmentList.map((tag) => (
                                <CheckboxContainer key={tag.id}>
                                  <CheckboxInputDepart
                                    // name="departmentIds"
                                    // type="checkbox"
                                    // id={`checkbox-${tag.id && tag.id}`}
                                    //
                                    // checked={values.departmentIds && values.departmentIds.includes(tag.id)}
                                    // value={tag.id}
                                    // onChange={e => handleCheckboxChange(tag.id, e.target.checked)}
                                    name="departmentIds" // Use 'departmentId' instead of 'departmentIds'
                                    type="radio" // Change the input type to 'radio'
                                    id={`radio-${tag.id}`}
                                    checked={
                                      values.departmentIds &&
                                      values.departmentIds.includes(tag.id)
                                    } // Check if it's the selected department
                                    value={tag.id}
                                    onChange={() => handleRadioChange(tag.id)}
                                  />
                                  {/*<Checkmark isChecked={values.departmentIds && values.departmentIds.includes(tag.value)} />*/}
                                  <CheckboxLabel htmlFor={`checkbox-${tag.id}`}>
                                    {tag.name}
                                  </CheckboxLabel>
                                </CheckboxContainer>
                              ))}

                            {touched.departmentIds && errors.departmentIds && (
                              <>
                                <br />
                                <p className={"text-danger d-block"}>
                                  {errors.departmentIds}
                                </p>
                              </>
                            )}
                          </SelectDepartment>
                        </div>
                      </div>
                      <div className={"text-center py-5"}>
                        <AddUpdateBtn type={"submit"}>
                          {isUpdate ? "Update" : "Create"} Permit
                        </AddUpdateBtn>
                      </div>
                    </div>
                  </form>
                </div>
              </Modal.Body>
            </>
          )}
        </div>
      </CreatePermitStyle>
    </div>
  );
}

export default CreatePermitModel;
