import React from "react";
import Drawer from "../components/Drawer";
import NavDashboard from "../components/Navbar";
import Search from "../utils/Search";
import {ReportIssuer} from "../styles/ReportIssuer.style";
import {ReactComponent as DownloadIcon} from "../assets/download_report_icon.svg";

const Report = () =>{
    return (
        <div className='d-flex'>
            <div>
                <Drawer/>
            </div>
            <div className={"w-100"} style={{background:"#F7F7F7"}}>
                <NavDashboard page={"Report"}/>

                <div className={"container pt-3"}>
                    <Search
                        report={true}
                        config={{
                            placeholder: "Search Report Permit Number",
                            color: "#969FA7",
                            backgroundColor: "#ffffff",
                            border: "2px solid #fff",
                            placeholderWeight: "normal",
                            placeholderColor: "#969FA7",
                            fontWeight: "normal",
                            searchButtonColor: "#969FA7",
                            searchButtonColorHover: "",
                            searchRadius: "15px",
                            // searchButtonContainerColor: "#ffffff",
                            // searchButtonContainerColorHover: "lightblue",
                        }}
                    />
                </div>
                <div>
                    <ReportIssuer>
                        <ul>
                            <li>
                                <div className={"single_box"}>
                                    <div>
                                        <h4>Report #A158-5849-8078</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur.</p>
                                    </div>
                                    <div>
                                        <a href=""> <DownloadIcon/> </a>
                                    </div>

                                </div>
                            </li><li>
                                <div className={"single_box"}>
                                    <div>
                                        <h4>Report #A158-5849-8078</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur.</p>
                                    </div>
                                    <div>
                                        <a href=""> <DownloadIcon/> </a>
                                    </div>

                                </div>
                            </li><li>
                                <div className={"single_box"}>
                                    <div>
                                        <h4>Report #A158-5849-8078</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur.</p>
                                    </div>
                                    <div>
                                        <a href=""> <DownloadIcon/> </a>
                                    </div>

                                </div>
                            </li><li>
                                <div className={"single_box"}>
                                    <div>
                                        <h4>Report #A158-5849-8078</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur.</p>
                                    </div>
                                    <div>
                                        <a href=""> <DownloadIcon/> </a>
                                    </div>

                                </div>
                            </li>
                        </ul>
                    </ReportIssuer>
                </div>

            </div>
        </div>
    );
}

export default Report