import React, { useEffect, useState } from "react";
import NavDashboard from "../components/Navbar";
import Drawer from "../components/Drawer";
import { Nav, Tab, Tabs } from "react-bootstrap";
import DepartmentCompo from "../components/DepartmentCompo";
import Shift from "../components/Shift";
import { DepartmentTabs } from "../styles/Department.style";
import { useDispatch, useSelector } from "react-redux";
import {
  DEPARTMENT_REQUEST,
  GET_ALL_PERMIT,
  GET_DEPARTMENT_REQUEST_FOR_ISSUER,
} from "../store/constant";
import {
  clearUserPermit,
  newPermitToggle,
} from "../store/Slice/issuerPermitSlice";
import CreatePermitModel from "../utils/models/CreatPermitModel";
import { useNavigate } from "react-router-dom";
import CoAdPermitContent from "../utils/CoAdPermitContent";

const CoOrdinatorAdPermit = () => {
  const [value, setValue] = React.useState("");
  const dispatch = useDispatch();
  const { createPermitToggle, departmentList } = useSelector(
    (state) => state.issuerPermit
  );
  const [activeTab, setActiveTab] = useState("all");

  const handleCreateIssuer = () => {
    dispatch(newPermitToggle());
    dispatch(clearUserPermit());
  };
  const handleClose = () => {
    dispatch(newPermitToggle());
  };
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (!departmentList) {
      dispatch({ type: GET_DEPARTMENT_REQUEST_FOR_ISSUER });
    }
  }, [departmentList]);

  return (
    <div className="d-flex">
      <div>
        <Drawer />
      </div>
      <div className={"w-100"} style={{ background: "#F7F7F7" }}>
        <NavDashboard page={"Permit"} />
        <div className={"w-100"}>
          <DepartmentTabs>
            <Tabs
              className={"co_ordinator_permit"}
              activeKey={activeTab}
              onSelect={handleTabChange}
              style={{ border: "none" }}
            >
              <Tab className={"mx-3"} eventKey="all" title="All">
                {activeTab && activeTab === "all" && (
                  <CoAdPermitContent filter={""} />
                )}
              </Tab>
              <Tab eventKey="second" className={"mx-3"} title="Requested">
                {activeTab && activeTab === "second" && (
                  <CoAdPermitContent filter={"Requested"} />
                )}
              </Tab>
              <Tab eventKey="third" className={"mx-3"} title="Approved">
                {activeTab && activeTab === "third" && (
                  <CoAdPermitContent filter={"Approved"} />
                )}
              </Tab>

              <Tab eventKey="fourth" className={"mx-3"} title="Rejected">
                {activeTab && activeTab === "fourth" && (
                  <CoAdPermitContent filter={"Rejected"} />
                )}
              </Tab>
            </Tabs>
          </DepartmentTabs>
          {/* <CreatePermitModel show={createPermitToggle} onHide={handleClose} handleClose={handleClose}/> */}
        </div>
      </div>
    </div>
  );
};

export default CoOrdinatorAdPermit;
