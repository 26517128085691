import Drawer from "../components/Drawer";
import NavDashboard from "../components/Navbar";
import { ReactComponent as Pencil } from "../assets/pencil.svg";
import React, { useEffect, useState } from "react";
import { IssuerDetails } from "../styles/IssuerDetails.style";
import {
  CheckboxContainer,
  CheckboxInput,
  CheckboxLabel,
  SelectDepartment,
} from "../styles/CreatePermitModel.style";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_DEPARTMENT_REQUEST_FOR_ISSUER,
  GET_PERMIT_BY_ID,
  POST_ISSUER_PERMIT_REQUEST,
} from "../store/constant";
import config from "../config";
import {
  cleanUpPermit,
  clearPermitById,
  newPermitToggle,
} from "../store/Slice/issuerPermitSlice";
import { type } from "@testing-library/user-event/dist/type";
import CreatePermitModel from "../utils/models/CreatPermitModel";
import { StatusPermit } from "../styles/Permit.style";

const validationSchema = yup.object().shape({
  // departmentIds:yup.array().min(1, 'At least select one department is required').required('At least select one department is required'),
});

const tagCollection = [
  { value: 4, label: "Mining" },
  { value: 5, label: "Enviroment" },
  { value: 6, label: "Electricity" },
  { value: 7, label: "Forest" },
  { value: 8, label: "Miscellaneous" },
];

const IssuerPermitDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { adminData } = useSelector((state) => state.signIn);
  const navigate = useNavigate();
  // const [permitData, setPermitData] = useState(values);

  const {
    getPermitByIdSuccessDataForPermitDetails,
    getPermitByIdLoading,
    getPermitByIdSuccessData,
  } = useSelector((state) => state.issuerPermit);
  const { createPermitToggle, departmentList, permitSuccess } = useSelector(
    (state) => state.issuerPermit
  );

  const initialValues = {
    departmentId: getPermitByIdSuccessData
      ? getPermitByIdSuccessData?.departmentIds?.[0] || null
      : null,
  };

  useEffect(() => {
    if (!departmentList) {
      dispatch({ type: GET_DEPARTMENT_REQUEST_FOR_ISSUER });
    }
  }, [departmentList]);
  const handleClose = () => {
    dispatch(newPermitToggle());
  };

  const {
    handleSubmit,
    handleChange,
    errors,
    isSubmitting,
    setFieldValue,
    handleReset,
    values,
    touched,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit(values) {
      const updateData = {
        ...getPermitByIdSuccessData,
        departmentIds: [values.departmentId],
      };
      dispatch({ type: POST_ISSUER_PERMIT_REQUEST, payload: updateData });
    },
  });

  useEffect(() => {
    dispatch(clearPermitById());
    if (params.permitId) {
      dispatch({ type: GET_PERMIT_BY_ID, payload: params.permitId });
    }
  }, [params.permitId]);

  // Fetch permit data and set departmentId when data becomes available
  useEffect(() => {
    if (getPermitByIdSuccessData) {
      // setPermitData(getPermitByIdSuccessDataForPermitDetails);
      if (getPermitByIdSuccessDataForPermitDetails?.departmentIds?.length > 0) {
        setFieldValue(
          "departmentId",
          getPermitByIdSuccessDataForPermitDetails.departmentIds[0]
        );
      }
    }
  }, [
    getPermitByIdSuccessData,
    getPermitByIdSuccessDataForPermitDetails,
    setFieldValue,
  ]);

  const isCustodian =
    adminData && adminData.roleId === config.role.custodianRole;

  return (
    <>
      <div className="d-flex">
        <div>
          <Drawer />
        </div>
        {getPermitByIdSuccessData ? (
          <>
            <div className={"w-100"}>
              <NavDashboard page={"Permit Details"} />
              <div className={"w-100 mt-3"}>
                <div className={"m-5"}>
                  <IssuerDetails className={""}>
                    {getPermitByIdLoading ? (
                      "loading...."
                    ) : (
                      <>
                        {!isCustodian && (
                          <div
                            className={"pencil"}
                            onClick={() => {
                              dispatch(newPermitToggle());
                            }}
                          >
                            <Pencil />
                          </div>
                        )}
                        <div className={"permit_info"}>
                          <div className={"left_side"}>
                            <div>
                              <span>Permit Number</span>
                              <p className={"fw-bold"}>
                                {getPermitByIdSuccessData &&
                                getPermitByIdSuccessData.permitNumber
                                  ? getPermitByIdSuccessData.permitNumber
                                  : "-"}
                              </p>
                            </div>
                            <div>
                              <span>Permit Raised By</span>
                              <p className={"font_size_12"}>
                                {getPermitByIdSuccessData &&
                                getPermitByIdSuccessData.lastUpdatedBy
                                  ? getPermitByIdSuccessData.lastUpdatedBy
                                  : "-"}
                                {/* Hardcoded value for role_box as of now */}
                                <div className={"role_box"}>Issuer</div>
                              </p>
                            </div>
                            <div>
                              <span>Area/Location </span>
                              <p>
                                {getPermitByIdSuccessData &&
                                getPermitByIdSuccessData.areaLocation
                                  ? getPermitByIdSuccessData.areaLocation
                                  : "-"}
                              </p>
                            </div>
                          </div>
                          <div>
                            <div>
                              <span>Status</span>
                              <p className={"font_size_12"}>
                                {/*<div className={"status_round"}></div>*/}
                                {getPermitByIdSuccessData.status &&
                                  getPermitByIdSuccessData.status ===
                                    "Approved" && (
                                    <>
                                      <StatusPermit status={"#30DBB2"} />{" "}
                                      Approved
                                    </>
                                  )}
                                {getPermitByIdSuccessData.status &&
                                  getPermitByIdSuccessData.status ===
                                    "Rejected" && (
                                    <>
                                      <StatusPermit status={"#FF0000"} />{" "}
                                      Rejected
                                    </>
                                  )}
                                {getPermitByIdSuccessData.status &&
                                  getPermitByIdSuccessData.status ===
                                    "Requested" && (
                                    <>
                                      <StatusPermit status={"#FF8946"} />{" "}
                                      Requested
                                    </>
                                  )}
                                {getPermitByIdSuccessData.status &&
                                  getPermitByIdSuccessData.status ===
                                    "IssuerCompleted" && (
                                    <>
                                      <StatusPermit status={"#FF8946"} /> Issuer
                                      Completed
                                    </>
                                  )}
                                {getPermitByIdSuccessData.status &&
                                  getPermitByIdSuccessData.status ===
                                    "CustodianApproved" && (
                                    <>
                                      <StatusPermit status={"#FF8946"} />{" "}
                                      Custodian Approved
                                    </>
                                  )}
                                {getPermitByIdSuccessData.status &&
                                  getPermitByIdSuccessData.status ===
                                    "CheckListCreated" && (
                                    <>
                                      <StatusPermit status={"#FF8946"} />{" "}
                                      CheckList Created
                                    </>
                                  )}
                                {getPermitByIdSuccessData.status &&
                                  getPermitByIdSuccessData.status ===
                                    "SubmiteForCompleted" && (
                                    <>
                                      <StatusPermit status={"#FF8946"} />{" "}
                                      Submite For Completed
                                    </>
                                  )}
                                {getPermitByIdSuccessData.status &&
                                  getPermitByIdSuccessData.status ===
                                    "In Progress" && (
                                    <>
                                      <StatusPermit status={"#FF8946"} /> In
                                      Progress
                                    </>
                                  )}
                              </p>
                            </div>
                            <div>
                              <span>Email Id</span>
                              <p>
                                {getPermitByIdSuccessData &&
                                getPermitByIdSuccessData.emailId
                                  ? getPermitByIdSuccessData.emailId
                                  : "-"}
                              </p>
                            </div>
                            <div>
                              <span>Date and Time </span>
                              <p>
                                {getPermitByIdSuccessData &&
                                getPermitByIdSuccessData.dateTime
                                  ? getPermitByIdSuccessData.dateTime
                                  : "-"}
                              </p>
                            </div>
                          </div>
                        </div>
                        <form onSubmit={handleSubmit}>
                          <div className={"department_list"}>
                            <p className={"department_title"}>Department</p>
                            <SelectDepartment>
                              {tagCollection.map((department) => (
                                <CheckboxContainer key={department.value}>
                                  <CheckboxInput
                                    // name="departmentIds"
                                    // type="checkbox"
                                    // id={`checkbox-${tag.value && tag.value}`}
                                    // checked={ getPermitByIdSuccessData?.departmentIds &&
                                    //     getPermitByIdSuccessData.departmentIds.includes(tag.value)}
                                    // onChange={() => handleCheckboxChange(tag.value)}
                                    name="departmentId"
                                    type="radio"
                                    id={`radio-${department.value}`}
                                    checked={
                                      values.departmentId === department.value
                                    }
                                    defaultValue={4}
                                    disabled={true}
                                    value={department.value}
                                    onChange={() =>
                                      setFieldValue(
                                        "departmentId",
                                        department.value
                                      )
                                    }
                                  />
                                  <CheckboxLabel
                                    htmlFor={`radio-${department.value}`}
                                  >
                                    {department.label}
                                  </CheckboxLabel>
                                </CheckboxContainer>
                              ))}

                              {touched.department && errors.department && (
                                <>
                                  <br />
                                  <p className={"text-danger d-block"}>
                                    {errors.department}
                                  </p>
                                </>
                              )}
                            </SelectDepartment>
                          </div>
                          <div className={"comment_info"}>
                            <span className={"comment_title"}>Comment</span>
                            <p>
                              {getPermitByIdSuccessData &&
                              getPermitByIdSuccessData.comment
                                ? getPermitByIdSuccessData.comment
                                : "-"}
                            </p>
                          </div>
                          {/*{!isCustodian &&*/}
                          {/*    <div className={"update_btn"}>*/}
                          {/*        <button type={"submit"}>*/}
                          {/*            Update*/}
                          {/*        </button>*/}
                          {/*    </div>*/}
                          {/*}*/}
                        </form>
                      </>
                    )}
                  </IssuerDetails>
                </div>
              </div>
            </div>
            {createPermitToggle && (
              <CreatePermitModel
                permit={getPermitByIdSuccessData}
                show={createPermitToggle}
                onHide={handleClose}
                handleClose={handleClose}
              />
            )}
          </>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </>
  );
};
export default IssuerPermitDetails;
