import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAddUpdateShift } from '../../store/Slice/departmentShiftSlice';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  ChooseColor,
  InputContainer,
  Label,
  RadioGroup,
  RadioInput,
  RadioLabel,
  Select, ShiftAddEditStyle,
  Submit
} from "../../styles/DepartmentFrom.style";
import {ADD_UPDATE_SHIFT} from "../../store/constant";
import {ReactComponent as CloseIcon} from "../../assets/close_icon.svg"

const validationSchema = Yup.object().shape({
  startTime: Yup.string().required('Start time is required'),
  endTime: Yup.string().required('End time is required'),
  shiftType: Yup.string().required('Shift type is required'),
  color: Yup.string().required('Please select a color'),
});
const colorOptions = [
  { label: '', value: '#92FDFF', color: '#92FDFF' },
  { label: '', value: '#CFFF87', color: '#CFFF87' },
  { label: '', value: '#D6DFFF', color: '#D6DFFF' },
];
function DepartmentAddEdit({ show }) {
  const { getShiftIdForUpdate, allShift } = useSelector((state) => state.department);
  const dispatch = useDispatch();
  const shiftForUpdate = allShift && allShift.filter((element) => element.id === getShiftIdForUpdate);

  // console.log(shiftForUpdate[0],"shiftForUpdate[0]")
  const isUpdater = shiftForUpdate && shiftForUpdate.length > 0;

  const initialValues = {
    startTime: !isUpdater ? '09:00 AM' : shiftForUpdate[0].startTime,
    endTime: !isUpdater ? '12:00 AM' : shiftForUpdate[0].endTime,
    shiftType: !isUpdater ? 'Morning' : shiftForUpdate[0].shiftType,
    color: !isUpdater ? '' : shiftForUpdate[0].color,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (!isUpdater) {
        dispatch({type:ADD_UPDATE_SHIFT,payload: {...values,active:true}})
      } else {
        // console.log(shiftForUpdate[0], 'shiftForUpdate[0]');
        const user = shiftForUpdate[0];
        const updateShift = {
          startTime: values.startTime,
          endTime: values.endTime,
          shiftType: values.shiftType,
          color: values.color,
          id:user.id,
          deleted:user.deleted,
          active:user.active,
        };
        dispatch({type:ADD_UPDATE_SHIFT,payload: {...updateShift}})

        // console.log(updateShift, 'updateShift');
      }
    },
  });

  const handleClose = () => {
    dispatch(toggleAddUpdateShift());
  };

  return (
      <div style={{ maxWidth: '450px' }}>
        <Modal show={show} aria-labelledby='contained-modal-title-vcenter' centered>
          {shiftForUpdate && shiftForUpdate && (
              <Modal.Body className='px-4'>
                <div>
                  <form onSubmit={formik.handleSubmit}>
                    <ShiftAddEditStyle>
                    <div className={"heading_shift d-flex justify-content-between align-items-center"}>
                        <h3>
                          {!isUpdater ? '+ Add Shift' : '+ Update Shift'}
                        </h3>

                        <button type='button' onClick={handleClose} className='d-block bg-transparent border-0'>
                          <CloseIcon/>
                        </button>
                    </div>
                      <div className={"px-2 my-3"} style={{fontSize:"16px"}}>Set Time & Shift</div>
                    <div className={"two_box comman_input"}>
                      <InputContainer>
                        <Label htmlFor='startTime'>Start Time:</Label>
                        <Select
                            as='select'
                            id='startTime'
                            name='startTime'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.startTime}
                        >
                          {[
                            "08:00 AM", "09:00 AM", "09:00 PM",
                            "12:00 AM", "12:00 PM", "06:00 AM", "06:00 PM","03:00 PM"
                          ].map(time => (
                              <option key={time} value={time} selected={formik.values.startTime === time}>{time}</option>
                          ))}
                        </Select>
                      </InputContainer>
                      {formik.touched.startTime && formik.errors.startTime && <div>{formik.errors.startTime}</div>}
                      <InputContainer>
                        <Label htmlFor='endTime'>End Time:</Label>
                        <Select as='select' name='endTime' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.endTime}>
                          {[
                            "08:00 AM", "09:00 AM", "09:00 PM",
                            "12:00 AM", "12:00 PM", "06:00 AM", "06:00 PM","03:00 PM","2:00 PM"
                          ].map(time => (
                              <option key={time} value={time} selected={formik.values.endTime === time}>{time}</option>
                          ))}
                        </Select>
                      </InputContainer>
                      {formik.touched.endTime && formik.errors.endTime && <div>{formik.errors.endTime}</div>}
                    </div>
                    <div className={"one_box comman_input"}>
                      <InputContainer>
                        <Label htmlFor='endTime'>Shift Type:</Label>
                        <Select as='select' name='shiftType' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.shiftType}>
                          {[
                            "Morning", "Evening", "Night",
                          ].map(time => (
                              <option key={time} value={time} selected={formik.values.shiftType === time}>{time}</option>
                          ))}
                        </Select>
                      </InputContainer>
                      {formik.touched.shiftType && formik.errors.shiftType && <div>{formik.errors.shiftType}</div>}
                    </div>

                    </ShiftAddEditStyle>

                    <RadioGroup>
                      <ChooseColor>Choose color</ChooseColor>
                      {colorOptions.map((option, i) => (
                          <RadioLabel key={option.value}>
                            <RadioInput
                                type='radio'
                                name='color'
                                value={option.value}
                                color={option.color}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                checked={formik.values.color === option.value}
                            />
                            {option.label}
                          </RadioLabel>
                      ))}
                    </RadioGroup>
                    {formik.touched.color && formik.errors.color && <div className={"text-danger"}>{formik.errors.color}</div>}
                    <div>
                      <Submit type='submit' className='m-auto mt-5 w-100'>
                        {!isUpdater ? 'Create' : 'Update'}
                      </Submit>
                    </div>
                  </form>
                </div>
              </Modal.Body>
          )}
        </Modal>
      </div>
  );
}

export default DepartmentAddEdit;


// import Modal from 'react-bootstrap/Modal';
// import { Avatar, UserTabelBtn } from '../../styles/UserTabel.style';
// import { AddEditTitle, ButtonDepartMent, SubTitle } from '../../styles/Department.style';
// import { Formik, Form, Field, ErrorMessage } from 'formik';
// import * as Yup from "yup";
// import {
//   Label,
//   Submit,
//   FormMain,
//   InputContainer,
//   Select,
//   Error,
//   RadioGroup,
//   RadioLabel,
//   RadioInput,
//   ChooseColor
// } from "../../styles/DepartmentFrom.style";
// import {useDispatch, useSelector} from "react-redux";
// import {addUpdateShiftRequest, toggleAddUpdateShift} from "../../store/Slice/departmentShiftSlice";
// import React from "react";
// import {ADD_UPDATE_SHIFT} from "../../store/constant";
//
//
//
// const validationSchema = Yup.object().shape({
//   fullname: Yup.string()
//     .min(2, "Your name is too short")
//     .required("Please enter your full name"),
//   email: Yup.string()
//     .email("The email is incorrect")
//     .required("Please enter your email")
// });
//
//
// function DepartmentAddEdit({show}) {
//
//   const {getShiftIdForUpdate,allShift} = useSelector(state => state.department);
//   const dispatch = useDispatch()
//
//   const shiftForUpdate = allShift && allShift.filter((element)=> element.id === getShiftIdForUpdate)
//
//    const isUpdater =  shiftForUpdate && shiftForUpdate.length > 0
//   // Color options
//   const colorOptions = [
//   { label: '', value: '#92FDFF', color: '#92FDFF' },
//   { label: '', value: '#CFFF87', color: '#CFFF87' },
//   { label: '', value: '#D6DFFF', color: '#D6DFFF' },
// ];
//
//   // Initial form values
//   const initialValues = {
//     startTime: '09:00 AM',
//     endTime: '2:00 PM',
//     shiftType: 'morning',
//     color: '',
//   };
//
//   // Validation function
//   const validateForm = (values) => {
//     const errors = {};
//
//     if (!values.startTime) {
//       errors.startTime = 'Start time is required';
//     }
//
//     if (!values.endTime) {
//       errors.endTime = 'End time is required';
//     }
//
//     if (!values.shiftType) {
//       errors.shiftType = 'Shift type is required';
//     }
//     if (!values.color) {
//       errors.color = 'Please select a color';
//     }
//     return errors;
//   };
//
//   const handleSubmit = (values) => {
//     if(!isUpdater){
//     console.log(values)
//
//     // dispatch({type:ADD_UPDATE_SHIFT,payload: {...values,active:true}})
//     }else{
//       console.log(shiftForUpdate[0],"shiftForUpdate[0]")
//       const user = shiftForUpdate[0]
//       const updateShift = {
//           startTime: values.startTime,
//           endTime:values.endTime ,
//           shiftType: values.shiftType,
//           color: values.color,
//       }
//       console.log(updateShift,"updateShift")
//       // dispatch({type:ADD_UPDATE_SHIFT,payload: {...values,active:true}})
//     }
//   };
//   const handleClose = (values) => {
//     dispatch(toggleAddUpdateShift())
//   };
//
//
//   return (
//       <div style={{maxWidth: "450px"}}>
//         <Modal show={show} aria-labelledby='contained-modal-title-vcenter' centered>
//           {shiftForUpdate && shiftForUpdate &&
//               <Modal.Body className='px-4'>
//                 <div>
//                   <AddEditTitle>
//                     <span style={{fontSize: "28px"}}>+</span>
//                     {!isUpdater ? "Create Shift" : "Edit Shift"}
//                   </AddEditTitle>
//                 </div>
//                 <div>
//                   <SubTitle>
//                     Set Time & Shift
//                   </SubTitle>
//                   <Formik initialValues={initialValues} validate={validateForm} onSubmit={handleSubmit}>
//                     {({setFieldValue,errors, touched}) => (
//                         <FormMain>
//                           <Form>
//                             <InputContainer>
//                               <Label htmlFor="startTime">Start Time:</Label>
//                               <Select as="select" id="startTime" name="startTime">
//                                 <option value="08:00 AM">08:00 AM</option>
//                                 <option value="09:00 AM" selected>09:00 AM</option>
//                                 <option value="09:00 PM">09:00 PM</option>
//                                 <option value="12:00 AM">12:00 AM</option>
//                                 <option value="12:00 PM">12:00 PM</option>
//                                 <option value="06:00 AM">06:00 AM</option>
//                                 <option value="06:00 PM">06:00 PM</option>
//                               </Select>
//                             </InputContainer>
//                             <Error name="startTime" component="div"/>
//
//                             <InputContainer>
//                               <Label htmlFor="endTime">End Time:</Label>
//                               <Select as="select" name="endTime">
//                                 <option value="08:00 AM">08:00 AM</option>
//                                 <option value="09:00 AM">09:00 AM</option>
//                                 <option value="09:00 PM">09:00 PM</option>
//                                 <option value="12:00 AM">12:00 AM</option>
//                                 <option value="12:00 PM" selected>12:00 PM</option>
//                                 <option value="06:00 AM">06:00 AM</option>
//                                 <option value="06:00 PM">06:00 PM</option>
//                               </Select>
//                             </InputContainer>
//                             {errors.endTime && touched.endTime && <div>{errors.endTime}</div>}
//
//                             <div className='shift_type'>
//                               <InputContainer>
//                                 <Label htmlFor="shiftType">Shift Type:</Label>
//                                 <Select as="select" id="shiftType" name="shiftType">
//                                   <option value="morning" selected>Morning</option>
//                                   <option value="evening">Evening</option>
//                                   <option value="night">Night</option>
//                                 </Select>
//                               </InputContainer>
//                               <Error name="shiftType" component="div"/>
//                             </div>
//                             <div>
//
//                               <RadioGroup>
//                                 <ChooseColor>
//                                   Choose color
//                                 </ChooseColor>
//                                 {colorOptions.map((option, i) => (
//                                     <RadioLabel key={option.value}>
//                                       <RadioInput
//                                           type="radio"
//                                           name="color"
//                                           value={option.value}
//                                           color={option.color}
//                                           onChange={(e) => {
//                                             setFieldValue("color", e.target.value)
//                                           }}
//                                       />
//                                       {option.label}
//                                     </RadioLabel>
//                                 ))}
//                               </RadioGroup>
//                               <ErrorMessage name="color" component="div"/>
//                               <div>
//                                 <Submit type='submit' className='m-auto mt-5'>
//                                   {!isUpdater ? 'Create' : "Update" }
//                                 </Submit>
//                                 <button onClick={handleClose} className='btn bg-black text-light mt-2'>
//                                   Close
//                                 </button>
//                               </div>
//                             </div>
//                           </Form>
//                         </FormMain>
//                     )}
//
//                   </Formik>
//                 </div>
//               </Modal.Body>
//           }
//         </Modal>
//       </div>
//   );
// }
//
// export default DepartmentAddEdit;
