import styled from "styled-components";

export const ReportIssuer = styled.div`
  padding-top: 25px;
    ul {
      
      display: flex;
      flex-wrap: wrap;

      li {
        width: calc(50% - 30px);
        margin: 15px;

        .single_box {
          background: #FFFFFF;
          box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 24px 28px;
          h4{
            font-weight: 500;
            font-size: 22px;
            color: #2D3E50;
          }
          p{
            font-weight: 400;
            font-size: 14px;
            color: rgba(45, 62, 80, 0.6);
            margin-bottom: 0 !important;
          }
        }
      }
    }
`
