import {styled} from "styled-components";

export const ParmitTable = styled("div")({
    background:" #FFFFFF",
    boxShadow:" 0px 2px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",

    "::-webkit-scrollbar-thumb": {
        backgroundColor: "#2D3E50",
        borderRadius: "10px",
        // border: "3px solid #ffffff",
    },
    "::-webkit-scrollbar-track": {
        background: "#CECECE",
        margin:"30px 0 15px 0",
        borderRadius: "10px",
    },
    "*::-webkit-scrollbar": {
        width: "4px",
        borderRadius: "10px",
    },
    "*": {
        scrollbarWidth: "auto",
        scrollbarColor: "#8f54a0 #ffffff",
    },
    ".user-table": {
        overflow: "auto",
        height: "400px",
        marginRight:"5px",
        paddingLeft:"15px",
        "table": {
            color:"#2D3E50",
            "thead": {
                "tr": {
                    "th": {
                        position: "sticky",
                        top: "0",
                        zIndex: "1",
                        backgroundColor: "#fff",
                        color:"#7C858F",
                        fontWeight:"500"
                    },
                    ":first-child": {
                        borderTopLeftRadius: "15px",
                    },
                    ":last-child": {
                        borderTopRightRadius: "15px",
                    }
                }
            },
            "tbody": {
                "tr": {
                    "td": {
                        fontFamily: 'Josefin Sans',
                        fontStyle: "normal",
                        fontWeight:" 600",
                        fontSize: "15px",
                        // textAlign:" center",
                        color: "#2D3E50",
}
                }
            }
        }
    },
    ".co_ad_tabel":{
        "table": {
            "thead": {
                "tr": {
                    ":first-child": {
                        borderTopLeftRadius: "0px",
                    },
                    ":last-child": {
                        borderTopRightRadius: "0px",
                    }
                }
            },
        }
    },
    ".issuerTable": {
        height: "50vh",
    }
})

export const DetailsBtnPermit = styled.button`
  background: #E9E9E9;
  border-radius: 5px;
  font-weight: bold;
  font-family: 'Josefin Sans';
  font-size: 12px;
  line-height: 14px;
  color: #2D3E50;
  padding: 7px 16px;
  border: 0;
  transition: 0.2s;
  
  &:hover{
  background: #2D3E50;
  color: #fff;
}
`
export const StatusPermit = styled.div`
  background: ${props => props.status};
  border-radius: 5px;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right:8px;
`


export const SearchBoxStyle = styled.div`
  position: relative;
  top: 0;
  right: 0;
  input{
    width: 100%;
    padding: 10px 20px;
    border: 0;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    line-height: 100%;
    position: relative;
    &::placeholder {
      font-size: 16px;
      line-height: 100%;
      color: #88919A;
    }
    &:focus{
      outline: 0;
    }
  }
`;
export const PermitRaised = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding-top: 25px;
`

export const NewPermit = styled.div`
  text-align: end;
  margin-top: 20px;
  margin-right: 15px;
  button{
    background: #2D3E50;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 0;
    padding: 10px 14px;
    color: #fff;
    line-height: 16px;
    span{
      display: inline-block;
      font-size: 28px;
      padding-left: 8px;
    }
    p{
      display: inline-block;
      margin-bottom: 0;
    }
  }
`



export const CreateIntiatorStyle = styled.button`
  color: #FFF;
  font-size: 12px;
  font-family: Josefin Sans;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 5px;
  background: #2D3E50;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.10);
  display: block;
  padding: 4px 8px;
  border: 0;
  margin-left: 8px;
  span{
    font-size: 24px;
    font-weight: 600;
    margin-left: 6px;
    line-height: 16px;
  }
`