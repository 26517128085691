import React, { useContext, useEffect, useState } from "react";
import Drawer from "../components/Drawer";
import NavDashboard from "../components/Navbar";
import {
  SingleBoxMain,
  SingleBoxStyle,
  TabelDashboard,
} from "../styles/Dashboard.style";
import { Avatar, UsersTabel, UserTabelBtn } from "../styles/UserTabel.style";
import { SelectSearch, UserForm } from "../styles/UsersSearchTopBar";
import ModalPerson from "../utils/models/ModalPerson";
import MyVerticallyCenteredModal from "../utils/models/AddUserModal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Pencil } from "../assets/pencil.svg";
import { ReactComponent as Delete } from "../assets/delete.svg";
import { ReactComponent as Mail } from "../assets/Dashboard/MailRedIcon.svg";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import {
  DEPARTMENT_REQUEST,
  GET_ALL_SHIFT,
  GET_ROLES,
  GET_USER_FOR_UPDATE,
  USERS_REQUEST,
} from "../store/constant";
import config from "../config";
import {
  deleteUserToggle,
  userAddUpdateToggle,
} from "../store/Slice/usersSlice";
import DeleteUserModel from "../utils/models/DeleteUserModel";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
export const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      // text: '',
    },
  },
};

const labels = [1, 5, 10, 15, 20, 25, 30];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [0, 250, 45, 12, 1000],
      borderColor: "#A6FF1F",
      backgroundColor: "#2D3E50",
    },
  ],
};

const Dashboard = () => {
  const [value, setValue] = React.useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [userId, setUserId] = React.useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { usersList, deleteToggle, userModelToggle } = useSelector(
    (state) => state.users
  );

  const { roles, allShift, department } = useSelector(
    (state) => state.department
  );

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  // console.log(value,"values-=-=-=--=")
  let filter = {
    locationId: 1,
    filterBy: "3",
    searchText: "",
  };
  // useEffect(()=>{
  //     if(!usersList){
  //         dispatch({type: USERS_REQUEST,payload:filter})
  //     }
  // },[])

  useEffect(() => {
    const filterVal = { ...filter, filterBy: value };
    dispatch({ type: USERS_REQUEST, payload: filterVal });
  }, [value]);

  // Toggle for updateuser
  // useEffect(() => {
  //     if (upateUserGet) {
  //         dispatch(userAddUpdateToggle())
  //     }
  // }, [upateUserGet]);

  // Get roles
  useEffect(() => {
    if (!roles) {
      dispatch({ type: GET_ROLES });
    }
  }, [roles]);

  // Get All Shifts
  useEffect(() => {
    if (!allShift) {
      dispatch({ type: GET_ALL_SHIFT });
    }
  }, [allShift]);

  // Get all Department
  useEffect(() => {
    if (!department) {
      dispatch({ type: DEPARTMENT_REQUEST });
    }
  }, [department]);

  const handleDelete = (id) => {
    if (id) {
      setUserId(id);
      dispatch(deleteUserToggle());
    }
  };

  return (
    <div style={{ height: "100vh" }} className="d-flex overflow-hidden">
      <div>
        <Drawer />
      </div>
      <div className={"w-100"}>
        <NavDashboard />
        <div className={""}>
          <div className={"container mt-4"}>
            <div>
              <div className={"row"}>
                <div className={"col-lg-4"}>
                  <SingleBoxMain>
                    <SingleBoxStyle color={"#19E0E4"}>
                      <h3>₹ 52653</h3>
                      <p>Checkyour email for the OTP</p>
                    </SingleBoxStyle>
                  </SingleBoxMain>
                </div>
                <div className={"col-lg-4"}>
                  <SingleBoxMain>
                    <SingleBoxStyle color={"#81E154"}>
                      <h3>₹ 52653</h3>
                      <p>Checkyour email for the OTP</p>
                    </SingleBoxStyle>
                  </SingleBoxMain>
                </div>
                <div className={"col-lg-4"}>
                  <SingleBoxMain>
                    <SingleBoxStyle color={"#395AD1"}>
                      <h3>₹ 52653</h3>
                      <p>Checkyour email for the OTP</p>
                    </SingleBoxStyle>
                  </SingleBoxMain>
                </div>
              </div>
              <div className={"py-2 my-2"}>
                <Line options={options} data={data} />
              </div>
            </div>
            <div>
              {/* {usersList && usersList.length > 0 && ( */}
              <TabelDashboard>
                <UsersTabel className={"tabel_main_dashborad"}>
                  <div className="users_head d-flex py-2 px-3">
                    <h3 className="p-2">Users</h3>
                    <div className="custodian">
                      <UserForm>
                        <SelectSearch
                          value={value}
                          onChange={handleChange}
                          name="custodian"
                          id="custodian"
                        >
                          <option value="" selected={value === ""}>
                            All User
                          </option>
                          <option
                            value="Custodian"
                            selected={value === "Custodian"}
                          >
                            Custodian{" "}
                          </option>
                          <option value="Issuer" selected={value === "Issuer"}>
                            Issuer
                          </option>
                          <option
                            value="Co-ordinator"
                            selected={value === "Co-ordinator"}
                          >
                            Co-ordinator
                          </option>
                        </SelectSearch>
                      </UserForm>
                    </div>
                  </div>
                  <div className="user-table" style={{ height: "30vh" }}>
                    <table className="table">
                      <thead>
                        <tr className={"d-none"}>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {usersList && */}
                        {usersList && usersList.length > 0 ? (
                          usersList.map((user, i) => {
                            return (
                              <tr className="content" key={i + 1}>
                                <th>
                                  <Avatar>
                                    <img src={user.photo} alt="" srcSet="" />
                                  </Avatar>
                                </th>
                                <td>
                                  <div className="main-username">
                                    <div className="main-text">
                                      <h4>{user.name}</h4>
                                    </div>
                                  </div>
                                </td>
                                <td className={"text-start"}>
                                  <div className="main-button">
                                    <UserTabelBtn className="btn">
                                      {config.role.issuerRole ===
                                        user.roleId && <>Issuer</>}
                                      {config.role.custodianRole ===
                                        user.roleId && <>Custodian</>}
                                      {config.role.adminRole ===
                                        user.roleId && <>Admin</>}
                                      {config.role.coOrdinatorRole ===
                                        user.roleId && <>Co-Ordinator</>}
                                    </UserTabelBtn>
                                  </div>
                                </td>

                                <td>
                                  <Mail /> {user.emailId}
                                </td>
                                <td>
                                  <div className="main-last-field d-flex align-items-center justify-content-between">
                                    <div className="box-details box-btn d-flex align-items-center">
                                      <div>
                                        <button
                                          className="btn-danger btn"
                                          onClick={() =>
                                            navigate(`/dashboard/${user.id}`)
                                          }
                                        >
                                          Details
                                        </button>
                                      </div>
                                      <div>
                                        <button
                                          className="btn"
                                          onClick={() => {
                                            if (user.id) {
                                              dispatch({
                                                type: GET_USER_FOR_UPDATE,
                                                payload: user.id,
                                              });
                                              dispatch(userAddUpdateToggle());
                                            }
                                          }}
                                        >
                                          <Pencil />
                                        </button>
                                        <button
                                          className="btn"
                                          type={"button"}
                                          onClick={() => {
                                            if (user.id) {
                                              handleDelete(user.id);
                                            }
                                          }}
                                        >
                                          <Delete />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="4" style={{ borderBottom: "none" }}>
                              No data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </UsersTabel>
              </TabelDashboard>
              {/* )} */}
            </div>
            <ModalPerson show={modalShow} onHide={() => setModalShow(false)} />
            {userId && <DeleteUserModel show={deleteToggle} id={userId} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
