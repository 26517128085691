import {keyframes, styled} from "styled-components";
import trueIcon from '../assets/select_true_icon.svg'
import { ReactComponent as DragAndDrop } from '../assets/camera.svg';
import {Modal} from "react-bootstrap";;

export const Dropzone = styled.div`
  text-align: center;
  border: 1px dashed #2d3e50;
  border-radius: 10px;
  padding: 25px;
  cursor: pointer;
`
export const DropzonePhoto = styled.div`
fill: #19e0e4;
`;


export const StyledSVG = styled("img")`
  padding: 15px 0;
  & path {
    fill: #19e0e4;
  }
`;

const CheckboxDisplay = styled.div`
  display: none;
`;

// export const UserFormCheck = styled("div")({
//     display: "flex",
//     justifyContent: "space-between",
//
//     alignItems: "center",
//     marginBottom: "15px",
//     "label": {
//         background: "#ffffff",
//         boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
//         borderRadius: "10px",
//         padding: "10px",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "space-between",
//         width: "100%",
//         cursor: "pointer",
//         fontSize:"13px",
//         "input":{
//             border: "1px solid #2D3E50",
//         padding:"8px",
//             "&:checked": {
//                 backgroundColor: "#2D3E50",
//                 backgroundSize: "cover",
//                 padding: "8px",
//                 // backgroundImage: 'url("https://img.icons8.com/?size=512&id=fVnC5GYxLG15&format=png")',
//                 backgroundImage: `url(${trueIcon})`,
//             },
//             "&:focus":{
//               boxShadow:"0",
//               border:"0",
//             },
//         }
//     },
//
//
//
//
// })

export const UserFormCheck = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  label {
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    font-size: 13px;

    input {
      border: 1px solid #2D3E50;
      padding: 8px;
      cursor: pointer;

      &:checked {
        background-color: #2D3E50;
        background-size: cover;
        padding: 8px;
        background-image: url(${trueIcon});
      }

      &:focus {
        box-shadow: 0;
        border: 0;
      }
    }
  }
`;


export const UserBoxCheck = styled.div`
border-radius: 5px;
width: 34px;
height: 34px;
display: flex;
align-items: center;
justify-content: center;
background: #19e0e4;
color: #2D3E50;
font-weight: 600;
margin-right: 8px;
`
export const ButtonDropzone = styled.div`
  background: #2D3E50;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: 'Josefin Sans';
  font-size: 12px;
  line-height: 28px;
  text-transform: uppercase;
  color: #FFFFFF;
  text-align: center;
  padding:6px 16px ;
  display: inline-block;
`

export const AddUpdateBtn = styled.button`
  padding: 15px 95px;
  background: linear-gradient(118.68deg, #E4385E -20.39%, #FF9C61 94.52%);
  border-radius: 35px;
  font-family: 'Josefin Sans';
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  border: 0;
`;

export const InputMain = styled("div")({
    "input": {
        border: "0 !important",
        borderBottom: " 1px solid #2D3E50 !important",
        color: "#2D3E50",
        borderRadius: "0",
        paddingLeft:"5px !important",
        "&:focus": {
            outline: "0",
            boxShadow: "0 0 0 0 !important",
            background: "transparent",
        },
        "&:focus-visible": {
            outline: 0,
        },
        "::placeholder": {
            color: "#2D3E50"
        }
    }
})
export const SelectMain = styled("div")({
    ".form-select":{
        border: "0",
        borderBottom: "1px solid #2D3E50 !important",
        borderRadius: "0",
        "&:focus": {
            boxShadow: "0 0 0 0 !important",
        },
    }
})


const slideIn = keyframes`
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  `;
export const AddAdminModel = styled(Modal)`
    .modal-dialog {
      margin-right: -50px;
      animation: ${slideIn} 0.3s forwards;
      margin-top:10px;
      margin-bottom:10px;
      .modal-content{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  ::-webkit-scrollbar-thumb{
    background-color: #2D3E50;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #CECECE;
    border-radius: 10px;
  }
  *::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
  }
  *{
    scrollbar-width: auto;
    scrollbar-color: #8f54a0 #ffffff;
  },
    .add_admin{
      
    }

    .modal-content {
      opacity: 0;
      animation: fadeIn 0.3s forwards;
    }
    &.show .modal-dialog {
      margin-right: 0px;
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `;