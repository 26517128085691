import React, { useEffect, useState } from "react";
import NavDashboard from "../components/Navbar";
import Drawer from "../components/Drawer";
import {
  NotificationTable,
  StatusNotification,
} from "../styles/Notification.style";
import {
  HelpBtn,
  HelpError,
  HelpField,
  HelpStyledForm,
  HelpTextarea,
  Message,
} from "../styles/Help.style";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { helpClear } from "../store/Slice/helpSlice";
import { POST_HELP } from "../store/constant";

// Validation Schema using Yup
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  mobileNumber: Yup.string()
    .matches(/^\d+$/, "Mobile number must be numeric")
    .required("Mobile number is required")
    .max(10, "Mobile number is too large")
    .min(10, "Mobile number is too Short"),
  // comment: Yup.string().required('Comment is required'),
  emailId: Yup.string().email("Invalid email").required("Email is required"),
});

const Help = () => {
  const dispatch = useDispatch();
  const { help, loading } = useSelector((state) => state.help);
  const navigate = useNavigate();
  const user = useSelector((state) => state.signIn.adminData);

  useEffect(() => {
    if (help) {
      dispatch(helpClear());
      navigate("/dashboard");
    }
  }, [help]);

  // Initial Form Values
  const initialValues = {
    name: "",
    mobileNumber: "",
    emailId: "contact@minemagma.com",
  };
  // Form Component
  const [comment, setComment] = useState("");
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setTimeout(() => {
      dispatch({ type: POST_HELP, payload: { ...values, content: comment } });
      resetForm();
      setSubmitting(false);
    }, 500);
  };

  return (
    <div className="d-flex">
      <div>
        <Drawer />
      </div>
      <div className={"w-100"}>
        <NavDashboard page={"Help"} />
        <div className={"container pt-3"}>
          <Message>
            {!loading ? (
              <>
                <div className={"message_main"}>
                  <div>
                    <h3>Message</h3>
                    <p className={"w-75"}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Donec a pretium nisi.{" "}
                    </p>
                  </div>
                  {/*<svg width="45" height="45" viewBox="0 0 45 45" fill="none"*/}
                  {/*     xmlns="http://www.w3.org/2000/svg">*/}
                  {/*    <circle cx="22.5" cy="22.5" r="22.5" fill="#2D3E50"/>*/}
                  {/*    <path*/}
                  {/*        d="M33 21.4316V24.5689H24.6699V33.0002H21.3301V24.5689H13V21.4316H21.3301V13.0002H24.6699V21.4316H33Z"*/}
                  {/*        fill="white"/>*/}
                  {/*</svg>*/}
                </div>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ setFieldValue, errors, isSubmitting }) => (
                    <HelpStyledForm>
                      <div className={"single_field mb-3"}>
                        <label htmlFor="emailId">To</label>
                        <HelpField
                          type="emailId"
                          id={"emailId"}
                          name="emailId"
                          placeholder="Enter your email"
                          disabled
                        />
                        <HelpError name="emailId" component="div" />
                      </div>
                      <div className={"tow_part"}>
                        <div className={"mb-3"}>
                          <label htmlFor="name">Name</label>
                          <HelpField
                            type="text"
                            id={"name"}
                            name="name"
                            placeholder="Enter your name"
                          />
                          <HelpError name="name" component="div" />
                        </div>
                        <div className={"mb-3"}>
                          <label htmlFor="phone">Phone</label>
                          <HelpField
                            className={"phone"}
                            type="text"
                            name="mobileNumber"
                            id={"phone"}
                            placeholder="Enter your number"
                          />
                          <HelpError name="mobileNumber" component="div" />
                        </div>
                      </div>

                      <div className={"single_field mb-3"}>
                        <label htmlFor="comment">Comment</label>
                        <HelpTextarea
                          rows={1}
                          onChange={(e) => setComment(e.target.value)}
                          id="comment"
                        />
                      </div>
                      <br />
                      <div className={"text-end"}>
                        <HelpBtn type="submit" disabled={isSubmitting}>
                          Submit
                        </HelpBtn>
                      </div>
                    </HelpStyledForm>
                  )}
                </Formik>
              </>
            ) : (
              "loading..."
            )}
          </Message>
        </div>
      </div>
    </div>
  );
};

export default Help;
