// Contant Requiest


// sign in
export const SIGN_IN_REQUEST = "SIGN_IN_REQUEST";
export const OTP_REQUEST = "OTP_REQUEST";

// users
export const ADD_USER = "ADD_USER"
export const UPDATE_USER = "UPDATE_USER"

// Delete User By ID
export const DELETE_USER = "DELETE_USER"

export const USERS_REQUEST = "USERS_REQUEST"
export const GET_USER_FOR_UPDATE = "GET_USER_FOR_UPDATE"
export const GET_USER_FOR_DETAILS = "GET_USER_FOR_DETAILS"

// Department
export const DEPARTMENT_REQUEST = "DEPARTMENT_REQUEST"
// Shift
export const GET_ALL_SHIFT = "GET_ALL_SHIFT";

// Roles
export const GET_ROLES = "GET_ROLES"

export const GET_DEPARTMENT_REQUEST_FOR_ISSUER = "GET_DEPARTMENT_REQUEST_FOR_ISSUER"

//Notification
export const NOTIFICATION_REQUEST = "NOTIFICATION_REQUEST"

// Permit
export const GET_ALL_PERMIT = "GET_ALL_PERMIT"

// Post issuer Permit
export const POST_ISSUER_PERMIT_REQUEST = "POST_ISSUER_PERMIT_REQUEST"
export const GET_PERMIT_BY_ID = "GET_PERMIT_BY_ID"

//Help
export const POST_HELP = "POST_HELP"
export const GET_WORK_LIST = "GET_WORK_LIST"
export const SAVE_UPDATE_INITIATOR = "SAVE_UPDATE_INITIATOR"
export const GET_INITIATOR_BYID = "GET_INITIATOR_BYID"
export const UPDATE_INITIATOR_STATUS = "UPDATE_INITIATOR_STATUS"

// Add Update Shifts
export const ADD_UPDATE_SHIFT = "ADD_UPDATE_SHIFT"

