import {
  ButtonNextCreateinitiator,
  CommentsStyle,
  CreateinitiatorModelStyle,
  WorkListStyle,
} from "../../styles/CreateInitiator.style";
import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import {
  createCheckListTogle,
  createInitiatorToggle,
  setCurrentStep,
} from "../../store/Slice/formStepperSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_INITIATOR_BYID,
  GET_WORK_LIST,
  SAVE_UPDATE_INITIATOR,
  UPDATE_INITIATOR_STATUS,
} from "../../store/constant";
import styled from "styled-components";
import { SelectMain } from "../../styles/AddUserModel.style";

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 8px;
`;

const CheckboxInput = styled.input`
  margin-right: 8px;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #969fa7;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
  cursor: ${(props) => (props.disabled ? "not-allow" : "pointer")};

  &:checked {
    background-color: ${(props) => (props.disabled ? "#969FA7" : "#2D3E50")};
    border-color: ${(props) => (props.disabled ? "#969FA7" : "#2D3E50")};
  }

  &:checked::before {
    content: "";
    display: block;
    width: 6px;
    height: 10px;
    border-right: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    transform: rotate(45deg);
    margin-left: 4px;
  }
`;

const CheckboxLabel = styled.label`
  font-weight: 500;
  cursor: pointer;
`;

const gasTestingList = [
  {
    id: 1,
    name: "Oxygen",
  },
  {
    id: 2,
    name: "Nitrogen gas",
  },
];

const CheckListModel = () => {
  const dispatch = useDispatch();
  const {
    initiatorDataForCheckList,
    workListLoading,
    showCheckListModel,
    workList,
  } = useSelector((state) => state.formStepper);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [typeOfGasTesting, setTypeOfGasTesting] = useState("");
  const { getPermitByIdSuccessData } = useSelector(
    (state) => state.issuerPermit
  );

  useEffect(() => {
    if (showCheckListModel) {
      dispatch({ type: GET_WORK_LIST, payload: 7 });
    }
  }, [showCheckListModel]);

  const handleCheckboxChange = (id, answer) => {
    if (!workListLoading) {
      const existingValueIndex = checkboxValues.findIndex(
        (val) => val.id === id
      );

      if (existingValueIndex !== -1) {
        // Toggle the checkbox value
        const existingValue = checkboxValues[existingValueIndex];
        if (existingValue.answer === answer) {
          // Uncheck the checkbox
          setCheckboxValues((prevValues) => [
            ...prevValues.slice(0, existingValueIndex),
            ...prevValues.slice(existingValueIndex + 1),
          ]);
        } else {
          // Update the checkbox value
          setCheckboxValues((prevValues) => [
            ...prevValues.slice(0, existingValueIndex),
            { id, answer },
            ...prevValues.slice(existingValueIndex + 1),
          ]);
        }
      } else {
        // Add new checkbox value
        setCheckboxValues((prevValues) => [...prevValues, { id, answer }]);
      }
    }
  };

  const handleSubmit = () => {
    if (initiatorDataForCheckList) {
      const updated = [...initiatorDataForCheckList.workDescriptionResponses];
      updated.push(...checkboxValues);
      const updatedInitiator = {
        ...initiatorDataForCheckList,
        workDescriptionRequests: updated,
        status: "CheckListCreated",
      };
      delete updatedInitiator.workDescriptionResponses;
      dispatch({ type: SAVE_UPDATE_INITIATOR, payload: updatedInitiator });

      const updateInitiator = {
        id: getPermitByIdSuccessData.id,
        status: "CheckListCreated",
      };
      dispatch({ type: UPDATE_INITIATOR_STATUS, payload: updateInitiator });
      dispatch(createCheckListTogle());
    }
  };

  return (
    <>
      <form>
        <CreateinitiatorModelStyle show={showCheckListModel}>
          <Modal.Header className={"border-0"}>
            <Modal.Title>
              <div className={"hot_worl_heading d-block w-100 border-0"}>
                Check list for Permit
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {workListLoading && workListLoading ? (
                "loading"
              ) : (
                <>
                  <WorkListStyle>
                    {workList &&
                      workList.map((item) => (
                        <div key={item.id}>
                          <div
                            className={"d-flex justify-content-between py-2"}
                          >
                            <label
                              className={"d-block"}
                              htmlFor={`checkbox-${item.id}`}
                            >
                              {item.id} {item.description}
                            </label>
                            <div className={"d-flex"}>
                              <CheckboxWrapper>
                                <CheckboxInput
                                  type="checkbox"
                                  id={`checkbox-${item.id}-yes`}
                                  checked={
                                    checkboxValues &&
                                    checkboxValues.some(
                                      (val) =>
                                        val.id === item.id &&
                                        val.answer === "yes"
                                    )
                                  }
                                  onChange={() =>
                                    handleCheckboxChange(item.id, "yes")
                                  }
                                />
                                <CheckboxLabel
                                  htmlFor={`checkbox-${item.id}-yes`}
                                >
                                  Yes
                                </CheckboxLabel>
                              </CheckboxWrapper>
                              <CheckboxWrapper>
                                <CheckboxInput
                                  type="checkbox"
                                  id={`checkbox-${item.id}-no`}
                                  checked={
                                    checkboxValues &&
                                    checkboxValues.some(
                                      (val) =>
                                        val.id === item.id &&
                                        val.answer === "no"
                                    )
                                  }
                                  onChange={() =>
                                    handleCheckboxChange(item.id, "no")
                                  }
                                />
                                <CheckboxLabel
                                  htmlFor={`checkbox-${item.id}-no`}
                                >
                                  No
                                </CheckboxLabel>
                              </CheckboxWrapper>
                              <CheckboxWrapper>
                                <CheckboxInput
                                  type="checkbox"
                                  id={`checkbox-${item.id}-na`}
                                  checked={
                                    checkboxValues &&
                                    checkboxValues.some(
                                      (val) =>
                                        val.id === item.id &&
                                        val.answer === "na"
                                    )
                                  }
                                  onChange={() =>
                                    handleCheckboxChange(item.id, "na")
                                  }
                                />
                                <CheckboxLabel
                                  htmlFor={`checkbox-${item.id}-na`}
                                >
                                  NA
                                </CheckboxLabel>
                              </CheckboxWrapper>
                            </div>
                          </div>
                        </div>
                      ))}

                    <div className={"input_main my-3"}>
                      <label htmlFor="department">Gas Testing</label>
                      <SelectMain className="col-md-6 select_text w-25">
                        <select
                          className="form-select p-0"
                          name={"typeOfGasTesting"}
                          onChange={(e) => setTypeOfGasTesting(e.target.value)}
                          value={typeOfGasTesting}
                          aria-label="Default select example"
                        >
                          {gasTestingList &&
                            gasTestingList.map((el) => {
                              return (
                                <option key={el.id} value={el.name}>
                                  {el.name}
                                </option>
                              );
                            })}
                        </select>
                      </SelectMain>
                    </div>

                    <CommentsStyle>
                      <div>
                        <label htmlFor="comment">Comments</label>
                        <input
                          name={"comment"}
                          // disabled={true}
                          // value={values.comment}
                          // onChange={handleChange}
                          id={"comment"}
                          type="text"
                          placeholder={"Enter your comments"}
                        />
                      </div>
                    </CommentsStyle>
                  </WorkListStyle>
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className={"border-0"}>
            <Button
              variant="secondary"
              onClick={() => {
                dispatch(createCheckListTogle());
              }}
            >
              close
            </Button>
            {/*<ButtonNextCreateinitiator type={"button"} onClick={handleSubmit}>*/}
            {/*    Approval in Progress*/}
            {/*</ButtonNextCreateinitiator>*/}
            <ButtonNextCreateinitiator type={"button"} onClick={handleSubmit}>
              Submit for Approval
            </ButtonNextCreateinitiator>
          </Modal.Footer>
        </CreateinitiatorModelStyle>
      </form>
    </>
  );
};
export default CheckListModel;
