import {styled} from "styled-components";


export const AlertMessage = styled.h3`
  font-size: 24px;
  text-align: center;
  color: #2D3E50;
  padding: 15px 0;
  padding-top: 35px;
  width: 300px;
  margin: auto;
`

export const AlertCancelBtn = styled.button`
  background: #F7F7F7;
  border-radius: 26.5px;
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  width: 182px;
  height: 53px;
  color: #2D3E50;
  border: 0;
`

export const AlertSubmitBTN = styled.button`
  background: linear-gradient(118.68deg, #E4385E -20.39%, #FF9C61 94.52%);
  border-radius: 26.5px;
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  width: 182px;
  height: 53px;
  border: 0;
`