import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  CreateUserButton,
  SearchBar,
  SelectSearch,
  UserForm,
} from "../styles/UsersSearchTopBar";
import Search from "./Search";
import MyVerticallyCenteredModal from "./models/AddUserModal";
import { useDispatch, useSelector } from "react-redux";
import { clearSetUser, userAddUpdateToggle } from "../store/Slice/usersSlice";
import { GET_ALL_PERMIT, USERS_REQUEST } from "../store/constant";

function UserSearchBar({ isNotBut, permit, user }) {
  const [value, setValue] = React.useState("");
  const [valuePermit, setValuePermit] = React.useState("Requested");
  const [searchPermit, setSearchPermit] = React.useState("");
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  let body = {
    locationId: 1,
    filterBy: "",
    searchText: "",
  };
  let petmitBody = {
    filterBy: "",
    searchText: "",
  };

  useEffect(() => {
    body = {
      locationId: 1,
      filterBy: value,
      searchText: "",
    };
    if (user) {
      dispatch({ type: USERS_REQUEST, payload: body });
    }
  }, [value]);

  useEffect(() => {
    petmitBody = {
      filterBy: valuePermit,
      searchText: searchPermit,
    };
    if (permit) {
      dispatch({ type: GET_ALL_PERMIT, payload: petmitBody });
    }
  }, [valuePermit, searchPermit]);

  const handleChangePermit = (e) => {
    setValuePermit(e.target.value);
  };

  return (
    <Container>
      <SearchBar>
        <Row className="">
          <Col xs={2}>
            <UserForm>
              {user && (
                <SelectSearch
                  value={value}
                  onChange={handleChange}
                  name="roles"
                  id="roles"
                >
                  <option value="">Filter By</option>
                  <option value="Issuer">Issuer</option>
                  <option value="Co-ordinator">Co-ordinator</option>
                  <option value="Custodian">Custodian</option>
                </SelectSearch>
              )}
              {permit && (
                <SelectSearch
                  value={valuePermit}
                  onChange={handleChangePermit}
                  name="permit"
                  id="permit"
                >
                  <option value="">Filter By</option>
                  <option value="Approved">Approved</option>
                  <option value="Rejected">Rejected</option>
                  <option value="Requested">Requested</option>
                  <option value="In Progress">In Progress</option>
                </SelectSearch>
              )}
            </UserForm>
          </Col>
          <Col xs={!isNotBut ? 8 : 10}>
            <Search
              searchPermit={searchPermit}
              setSearchPermit={setSearchPermit}
              user={user}
              permit={permit}
              config={{
                placeholder: "Search",
                color: "#2D3E50",
                backgroundColor: "#ffffff",
                border: "2px solid #fff",
                placeholderWeight: "500",
                placeholderColor: "#000000",
                fontWeight: "500",
                searchButtonColor: "#000",
                searchButtonColorHover: "",
                // searchButtonContainerColor: "#ffffff",
                // searchButtonContainerColorHover: "lightblue",
              }}
            />
          </Col>
          {!isNotBut ? (
            <Col>
              <CreateUserButton
                onClick={() => {
                  dispatch(userAddUpdateToggle());
                  dispatch(clearSetUser());
                }}
              >
                {/* <button onClick={() => setModalShow(true)}> */}
                Add User{" "}
                <b style={{ fontSize: "36px", fontWeight: "normal" }}>+</b>
                {/* </button>  */}
              </CreateUserButton>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </SearchBar>
    </Container>

    // <div className='row'>
    //     <div className='col-md-2'>
    //         <Form.Select aria-label='Default select example'>
    //             <option>Open this select menu</option>
    //             <option value='1'>One</option>
    //             <option value='2'>Two</option>
    //             <option value='3'>Three</option>
    //         </Form.Select>
    //     </div>
    //     <div className='col-md-7'>
    //         <div className='filter-input-main'>
    //             <div className='form-main'>
    //                 <Form.Control
    //                     type='text'
    //                     placeholder='Disabled input'
    //                     aria-label='Disabled input example'
    //                     disabled
    //                     readOnly
    //                 />
    //             </div>
    //             <div className='search-main'>
    //                 <Button variant='primary'>
    //                     <svg
    //                         xmlns='http://www.w3.org/2000/svg'
    //                         width={11}
    //                         height={11}
    //                         viewBox='0 0 11 11'
    //                         fill='none'
    //                     >
    //                         <path
    //                             d='M10.6174 10.2869L8.01409 7.68416C8.71242 6.88151 9.13521 5.83426 9.13521 4.68964C9.13521 2.17006 7.08537 0.123047 4.56761 0.123047C2.04747 0.123047 0 2.17243 0 4.68964C0 7.20685 2.04984 9.25623 4.56761 9.25623C5.71248 9.25623 6.75996 8.83353 7.5628 8.13536L10.1661 10.7381C10.2278 10.7998 10.311 10.833 10.3917 10.833C10.4725 10.833 10.5556 10.8022 10.6174 10.7381C10.7409 10.6146 10.7409 10.4103 10.6174 10.2869ZM0.638942 4.68964C0.638942 2.52389 2.40138 0.764222 4.56523 0.764222C6.73146 0.764222 8.49152 2.52627 8.49152 4.68964C8.49152 6.85301 6.73146 8.61743 4.56523 8.61743C2.40138 8.61743 0.638942 6.85539 0.638942 4.68964Z'
    //                             fill='white'
    //                         />
    //                     </svg>
    //                 </Button>
    //             </div>
    //         </div>
    //     </div>
    //     <div className='col-md-3'>
    //         <Button variant='primary' onClick={() => setModalShow(true)}>
    //             Create Admin +
    //         </Button>
    //     </div>
    //     {/* <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} /> */}
    // </div>
  );
}

export default UserSearchBar;
