import styled, {keyframes} from "styled-components";
import {ButtonGroup, Modal, ToggleButton} from "react-bootstrap";
import {useState} from "react";
import {Field} from "formik";

const slideIn = keyframes`
//     from {
//       transform: translateX(100%);
//       opacity: 0;
//     }
//     to {
//       transform: translateX(0);
//       opacity: 1;
//     }
  `;
export const CreateinitiatorModelStyle = styled(Modal)`

  .modal-dialog {
    max-width: 868px;
    //margin-right: -50px;
    //animation: ${slideIn} 0.3s forwards;
    margin-top:10px;
    margin-bottom:10px;
    .modal-content{
      //border-top-right-radius: 0;
      //border-bottom-right-radius: 0;
      .modal-body{
        height: 75vh;
        overflow-y: scroll;
      }
    }
  }
  ::-webkit-scrollbar-thumb{
    background-color: #2D3E50;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #CECECE;
    border-radius: 10px;
  }
  *::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
  }
  *{
    scrollbar-width: auto;
    scrollbar-color: #8f54a0 #ffffff;
  },


  .modal-content {
    opacity: 0;
    animation: fadeIn 0.3s forwards;
  }
  &.show .modal-dialog {
    //margin-right: 0px;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  // HOT Work  
  .hot_worl_heading {
    background: #F7F7F7;
    color: #2D3E50;
    font-size: 24px;
    font-family: Josefin Sans;
    font-weight: 500;
    width: 100%;
    padding: 6px;
    padding-left: 25px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-top: 12px;
  }
  
`

export const CreateInitiatorBodyStyle = styled.div`
  
`;

export const CreateInitiatorScreen = styled.div`

`;
export const CreateInitiatorFormStyle = styled.div`
  .input_FIeld_main {
    .input_main {
      margin: 8px 0;

      label {
        color: #2D3E50;
        font-size: 14px;
        font-family: Josefin Sans;
        font-weight: 600;
      }

      input {
        width: 100%;
        border: 0;
        border-bottom: 1px solid #2D3E50;
      }

      input::placeholder {
        color: #6E7A86;
        font-size: 12px;
        font-family: Josefin Sans;
      }

      input:focus {
        outline: 0;
      }
    }
  }

  .cold_work_option {
    display: flex;
    align-self: center;
    flex-wrap: wrap;

    .select {
      position: relative;

      label {
        border: 1px solid #2D3E50;
      }

      input:checked + label {
        background-color: #2D3E50;
        color: #fff;

        &:hover,
        &:focus,
        &:active {
          background-color: #2D3E50;
        }
      }

      input {
        opacity: 0;
        position: absolute;
      }

      .button_select {
        margin: 0 10px 10px 0;
        display: flex;
        background-color: transparent;

        &:hover,
        &:focus,
        &:active {
          background-color: transparent;
        }
      }
    }
  }

  .initiatorDetails{
    background-color: white;
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.10);
    margin: 25px 20px;
    border-radius: 10px;
    height: 75vh;
    overflow-y:scroll;
    .hot_worl_heading {
      background-color: #F7F7F7;
      padding:4px 15px;
      font-size: 18px;
    }
    .initiatorButtonSubmit{
      border-radius: 26.5px;
      background: linear-gradient(129deg, #E4385E 0%, #FF9C61 100%);
      padding: 14px 35px;
      display: block;
      border: 0;
      color: #FFF;
      text-align: center;
      font-size: 20px;
      font-family: Josefin Sans;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 0 0 0 auto;
    }
    .createCheckList{
      border-radius: 100px;
      background: #2D3E50;
      box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.10);
      padding: 14px 30px;
      display: block;
      border: 0;
      color: #FFF;
      text-align: center;
      font-size: 16px;
      font-family: Josefin Sans;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  };
  
`;
export const ToggleButtonStyle = styled(ToggleButton)`
  border-radius: 5px !important;
  background: ${(props)=>props.checked ? "#2D3E50" :"transparent"} !important;
  color:  ${(props)=>props.checked ? "#fff" : "#2D3E50"} !important;
  text-align: center;
  font-size: 20px;
  font-family: Josefin Sans;
  font-weight: 500;
  text-transform: capitalize;
  border-color: #2D3E50 !important;
  margin: 6px;
  padding:5px 25px;
  :hover{
    border:solid 1px #2D3E50 !important;
  }
`
export const ColdWorkOptionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`;


export const ToggleButtonCould = styled.button`
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 8px 16px;
  background-color: ${(props) => (props.selected ? '#2D3E50' : '#fff')} !important;
  color: ${(props) => (props.selected ? '#fff' : '#2D3E50')} !important;
  border: 1px solid #2D3E50 !important;
  outline: none;
  cursor: pointer;
`


export const PermitValidity = styled.div`
  label{
    color: #2D3E50;
    font-size: 14px;
    font-family: Josefin Sans;
    font-weight: 600;   
  }
  select{
    border: 0;
    border-bottom: 1px solid #2D3E50;
    color: #6E7A86;
    font-size: 14px;
    font-family: Josefin Sans;
    font-weight: 600;
  }
`

export const ButtonNextCreateinitiator = styled.button`
  padding: 10px 35px;
  border-radius: 26.5px;
  background: linear-gradient(129deg, #E4385E 0%, #FF9C61 100%);
  display: block;
  border: 0;
  color: #FFF;
  text-align: center;
  font-size: 18px;
  font-family: Josefin Sans;
  font-weight: 600;
`;


// Lifting and Shifting

export const LiftingShifting = styled.div`
  .form_main_lifting{
    padding-top: 15px;
    .single_select{
      padding:10px 0;
      h5 {
        color: #2D3E50;
        font-size: 14px;
        font-family: Josefin Sans;
        font-weight: 600;
      }
      select{
        border: 0;
        border-bottom: 1px solid #2D3E50;
        width: 100%;
        max-width: 300px!important;
      }
      select:focus-visible{
        outline: none;
      }
    }
    .comment{
      input{
        border: 0;
        border-bottom: 1px solid #2D3E50;
        width: 100%;
      }
      input::placeholder{
        color: #2D3E50;
      }
      input:focus-visible{
        outline: none;
      }
      
    }
    .update_request{
      display: flex;
      align-items: center;
      padding-top: 18px;
      button {
        padding: 8px 25px;
        display: block;
        cursor: pointer;
        border-radius: 26.5px;
        background: linear-gradient(129deg, #E4385E 0%, #FF9C61 100%);
        border:0 ;
        color: #FFF;
        text-align: center;
        font-size: 12px;
        font-family: Josefin Sans;
        font-weight: 500;
        text-transform: uppercase;
        margin-right: 15px;
      }
      
    }
  }
  
`;

export const CheckboxWrapperYesNo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

`;
export const CheckboxInputLifting = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  div{
    display: flex;
    align-items: center;
    margin: 8px;
    label{
      padding-left: 8px;
    }
  }
`;


export const CheckListForPermitStyle = styled.div`
  ul{
    color: #2D3E50;
  }
`

export const WorkListStyle = styled.div`
  color: #2D3E50;
  font-size: 14px;
  ul{
    li{
      padding:6px 0;
      .yes_no_main{
        div{
          label{
            padding-left: 15px;
          }
        }
      }
    }
  }
`

export const CommentsStyle = styled.div`
  div{
    label{
      display: block;
    }
    input{
      width: 100%;
      background-color: transparent;
      border: 0;
      border-bottom: 1px solid #2D3E50;
    }
      :focus-visible{
        outline: 0;
        border-bottom: 1px solid #2D3E50;
      }
  }
`