import React, {useEffect} from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { DepartmentMain } from '../styles/Department.style';
import { ShiftboxMain } from '../styles/Shift.style';
import {useDispatch, useSelector} from "react-redux";
import {DEPARTMENT_REQUEST} from "../store/constant";

const DepartmentCompo = () => {

  const dispatch = useDispatch();
  const {department} = useSelector(state => state.department);
  useEffect(()=>{
    dispatch({type:DEPARTMENT_REQUEST})
  },[])
  return (
      <div className='mt-4'>
        <ShiftboxMain>
          <Container>
            <div className={"row"}>
                {department && department.length > 0 ?
                    department.map((item) => {
                      return (
                          <div className={"col-xl-3"} key={item.id}>
                            <DepartmentMain> {item.name}</DepartmentMain>
                          </div>
                      );
                    }) : 'no data found'
                }
            </div>
          </Container>
        </ShiftboxMain>
      </div>
  );
}

export default DepartmentCompo;
