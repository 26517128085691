import React, { useEffect, useState } from "react";
import Drawer from "../components/Drawer";
import NavDashboard from "../components/Navbar";
import { ReactComponent as ApprovedBtn } from "../assets/approved.svg";
import { ReactComponent as PermitApprovedBtn } from "../assets/PermitApproveLabel.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  GET_INITIATOR_BYID,
  GET_PERMIT_BY_ID,
  UPDATE_INITIATOR_STATUS,
} from "../store/constant";
import { useFormik } from "formik";
import {
  clearGetInitiatorByIdSuccess,
  clearInitiatorStatusData,
  createCheckListTogle,
  initiatorForCheckList,
  saveInitiatorData,
  setCurrentStep,
} from "../store/Slice/formStepperSlice";
import {
  CheckboxInputLifting,
  CheckListForPermitStyle,
  ColdWorkOptionContainer,
  CommentsStyle,
  CreateInitiatorBodyStyle,
  CreateInitiatorFormStyle,
  CreateInitiatorScreen,
  LiftingShifting,
  PermitValidity,
  ToggleButtonCould,
  ToggleButtonStyle,
  WorkListStyle,
} from "../styles/CreateInitiator.style";
import { SelectMain } from "../styles/AddUserModel.style";
import { ButtonGroup } from "react-bootstrap";
import * as yup from "yup";
import styled from "styled-components";
import config from "../config";
import CheckListModel from "../utils/models/CheckListModel";

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 8px;
`;

const CheckboxInput = styled.input`
  margin-right: 8px;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #969fa7;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
  cursor: ${(props) => (props.disabled ? "not-allow" : "pointer")};

  &:checked {
    background-color: ${(props) => (props.disabled ? "#969FA7" : "#2D3E50")};
    border-color: ${(props) => (props.disabled ? "#969FA7" : "#2D3E50")};
  }

  &:checked::before {
    content: "";
    display: block;
    width: 6px;
    height: 10px;
    border-right: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    transform: rotate(45deg);
    margin-left: 4px;
  }
`;

const CheckboxLabel = styled.label`
  font-weight: 500;
  cursor: pointer;
`;

const validationSchema = yup.object().shape({
  departmentIds: yup
    .array()
    .min(1, "At least select one department is required")
    .required("At least select one department is required"),
});
const radios = [
  { name: "Hot work", value: 1 },
  { name: "Cold work", value: 2 },
];
const coldWorkOptions = [
  { name: "Confined Space Entry (CSE)", value: "Confined Space Entry (CSE)" },
  { name: "Work at height (WAH)", value: "Work at height (WAH)" },
  { name: "Electrical Permit (EP)", value: "Electrical Permit (EP)" },
  { name: "Lifting and shifting (L&S)", value: "Lifting and shifting (L&S)" },
  { name: "Excavation Certificate (EC)", value: "Excavation Certificate (EC)" },
];

const InitiatorDetails = () => {
  const { getInitiatorLoading, getInitiatorDataSuccess, updateInitiatorData } =
    useSelector((state) => state.formStepper);
  const { getPermitByIdSuccessData } = useSelector(
    (state) => state.issuerPermit
  );
  const { departmentList } = useSelector((state) => state.issuerPermit);
  const { adminData } = useSelector((state) => state.signIn);
  //   console.log(getPermitByIdSuccessData, departmentList, adminData);
  console.log(getInitiatorDataSuccess, getPermitByIdSuccessData);
  const [checkboxValues, setCheckboxValues] = useState(
    getInitiatorLoading
      ? []
      : getInitiatorDataSuccess &&
        getInitiatorDataSuccess.workDescriptionResponses
      ? getInitiatorDataSuccess.workDescriptionResponses
      : []
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  if (!getInitiatorLoading) {
    console.log(
      getInitiatorDataSuccess,
      "getInitiatorDataSuccess.workDescriptionResponses"
    );
    // setCheckboxValues(getInitiatorDataSuccess.workDescriptionResponses && getInitiatorDataSuccess.workDescriptionResponses)
  }

  useEffect(() => {
    dispatch(clearGetInitiatorByIdSuccess());
  }, []);
  useEffect(() => {
    if (!getInitiatorDataSuccess) {
      dispatch({ type: GET_INITIATOR_BYID, payload: params.initiatorPermitId });
      // dispatch({type: GET_PERMIT_BY_ID, payload: params.initiatorPermitId})
    }
  }, [params.initiatorPermitId]);

  const {
    handleSubmit,
    handleChange,
    errors,
    isSubmitting,
    setFieldValue,
    handleReset,
    values,
    touched,
  } = useFormik({
    initialValues: {
      ...getInitiatorDataSuccess,
      permitId: getInitiatorDataSuccess && getInitiatorDataSuccess.permitNumber,
      selectedTexts:
        getInitiatorDataSuccess && getInitiatorDataSuccess.selectedTexts,
    },
    validationSchema,
    onSubmit(values) {
      if (values) {
        // dispatch({type: POST_ISSUER_PERMIT_REQUEST, payload: values})
        const saveInitiatorFullData = {
          ...values,
          manPower: parseInt(values.manPower),
          departmentId: parseInt(values.departmentId),
          typeOfWork: parseInt(values.typeOfWork),
          workDescriptionRequests: [],
          permitId: getPermitByIdSuccessData.id,
          userId: adminData && adminData.id ? adminData.id : "",
        };
        dispatch(saveInitiatorData(saveInitiatorFullData));
        // dispatch(setCurrentStep(currentStep + 1))
      }
    },
  });

  const handleToggle = (value) => {
    if (getInitiatorDataSuccess && getInitiatorDataSuccess) {
      const { selectedTexts } = values;
      if (selectedTexts && selectedTexts.includes(value)) {
        setFieldValue(
          "selectedTexts",
          selectedTexts.filter((selected) => selected !== value)
        );
      } else {
        setFieldValue("selectedTexts", [...selectedTexts, value]);
      }
    }
  };

  // Work handle change
  const handleCheckboxChange = (id, answer) => {
    if (!getInitiatorLoading) {
      const existingValueIndex = checkboxValues.findIndex(
        (val) => val.id === id
      );

      if (existingValueIndex !== -1) {
        // Toggle the checkbox value
        const existingValue = checkboxValues[existingValueIndex];
        if (existingValue.answer === answer) {
          // Uncheck the checkbox
          setCheckboxValues((prevValues) => [
            ...prevValues.slice(0, existingValueIndex),
            ...prevValues.slice(existingValueIndex + 1),
          ]);
        } else {
          // Update the checkbox value
          setCheckboxValues((prevValues) => [
            ...prevValues.slice(0, existingValueIndex),
            { id, answer },
            ...prevValues.slice(existingValueIndex + 1),
          ]);
        }
      } else {
        // Add new checkbox value
        setCheckboxValues((prevValues) => [...prevValues, { id, answer }]);
      }
    }
  };
  const initiator = getInitiatorDataSuccess && getInitiatorDataSuccess;
  const isLifting =
    initiator &&
    initiator.selectedTexts.find((el) => el === "Lifting and shifting (L&S)");

  console.log(getInitiatorLoading, "getInitiatorLoading");
  const submitForApprove = () => {
    const updateInitiator = {
      id: getPermitByIdSuccessData.id,
      status: "SubmiteForCompleted",
    };
    dispatch({ type: UPDATE_INITIATOR_STATUS, payload: updateInitiator });
  };
  console.log(getPermitByIdSuccessData);
  const handleRequestCompleted = () => {
    const updateIssuerInitiator = {
      id: getInitiatorDataSuccess.permitId,
      //   id: getPermitByIdSuccessData.id,
      status: "IssuerCompleted",
    };
    dispatch({ type: UPDATE_INITIATOR_STATUS, payload: updateIssuerInitiator });
  };
  const handleCustodian = (status) => {
    const updateIssuerInitiator = {
      id: getInitiatorDataSuccess.permitId,
      // id: getPermitByIdSuccessData.id,
      status: status,
    };
    dispatch({ type: UPDATE_INITIATOR_STATUS, payload: updateIssuerInitiator });
  };

  useEffect(() => {
    if (updateInitiatorData) {
      dispatch(clearInitiatorStatusData());
      navigate("/dashboard");
    }
  }, [updateInitiatorData]);

  let type1Responses = [];
  let type2Responses = [];
  let type3Responses = [];
  let type4Responses = [];
  let type6Responses = [];
  let type7Responses = [];
  // type response
  if (initiator) {
    type1Responses = initiator.workDescriptionResponses.filter(
      (item) => item.type === 1
    );
  }
  if (initiator) {
    type2Responses = initiator.workDescriptionResponses.filter(
      (item) => item.type === 2
    );
  }
  if (initiator) {
    type3Responses = initiator.workDescriptionResponses.filter(
      (item) => item.type === 3
    );
  }
  if (initiator) {
    type4Responses = initiator.workDescriptionResponses.filter(
      (item) => item.type === 4
    );
  }
  if (initiator) {
    type7Responses = initiator.workDescriptionResponses.filter(
      (item) => item.type === 7
    );
  }

  if (initiator) {
    type6Responses = initiator.workDescriptionResponses.filter(
      (item) => item.type === 6
    );
  }
  return (
    <>
      <div className="d-flex">
        <div>
          <Drawer />
        </div>
        <div className={"w-100"} style={{ background: "#F7F7F7" }}>
          <NavDashboard page={"Intiator Details"} />
          <div>
            <CreateInitiatorBodyStyle>
              <CreateInitiatorScreen>
                <CreateInitiatorFormStyle>
                  <div className={"initiatorDetails"}>
                    {getInitiatorLoading ? (
                      "loading"
                    ) : (
                      <>
                        <div className={"container"}>
                          <div className={"row input_FIeld_main"}>
                            <div className={"col-6"}>
                              <div className={"input_main"}>
                                <label htmlFor="permit_number">
                                  Permit Number
                                </label>
                                <input
                                  onChange={handleChange}
                                  name={"permitId"}
                                  value={values.permitId}
                                  type="text"
                                  placeholder={"A158-5849-8078"}
                                  id={"permit_number"}
                                  disabled={true}
                                />
                                {touched.permitId && errors.permitId && (
                                  <>
                                    <br />
                                    <p className={"text-danger d-block"}>
                                      {errors.permitId}
                                    </p>
                                  </>
                                )}
                              </div>
                            </div>
                            <div className={"col-6"}>
                              <div className={"input_main"}>
                                <label htmlFor="coordinatorName">
                                  Coordinator (Ancillary) Name
                                </label>
                                <input
                                  name={"coordinatorName"}
                                  onChange={handleChange}
                                  value={values.coordinatorName}
                                  type="text"
                                  placeholder={"Coordinator (Ancillary) Name"}
                                  id={"coordinatorName"}
                                  disabled={true}
                                />
                                {touched.coordinatorName &&
                                  errors.coordinatorName && (
                                    <>
                                      <br />
                                      <p className={"text-danger d-block"}>
                                        {errors.coordinatorName}
                                      </p>
                                    </>
                                  )}
                              </div>
                            </div>
                            <div className={"col-6"}>
                              <div className={"input_main"}>
                                <label htmlFor="date_time">Date and time</label>
                                <input
                                  type="text"
                                  placeholder={"04 April, 2022   11:29:55 PM"}
                                  id={"date_time"}
                                  name={"dateTime"}
                                  value={values.dateTime}
                                  onChange={handleChange}
                                  disabled={true}
                                />
                                {touched.dateTime && errors.dateTime && (
                                  <>
                                    <br />
                                    <p className={"text-danger d-block"}>
                                      {errors.dateTime}
                                    </p>
                                  </>
                                )}
                              </div>
                            </div>
                            <div className={"col-6"}>
                              <div className={"input_main"}>
                                <label htmlFor="manPower">Manpower</label>
                                <input
                                  type="text"
                                  placeholder={"40"}
                                  id={"manPower"}
                                  name={"manPower"}
                                  value={values.manPower}
                                  onChange={handleChange}
                                  disabled={true}
                                />
                                {touched.manPower && errors.manPower && (
                                  <>
                                    <br />
                                    <p className={"text-danger d-block"}>
                                      {errors.manPower}
                                    </p>
                                  </>
                                )}
                              </div>
                            </div>
                            <div className={"col-6"}>
                              <div className={"input_main"}>
                                <label htmlFor="area_local">
                                  Area . Location of work
                                </label>
                                <input
                                  type="text"
                                  placeholder={"Bidhan Nagar, Kolkata"}
                                  id={"area_local"}
                                  name={"areaLocation"}
                                  value={values.areaLocation}
                                  onChange={handleChange}
                                  disabled={true}
                                />
                                {touched.areaLocation &&
                                  errors.areaLocation && (
                                    <>
                                      <br />
                                      <p className={"text-danger d-block"}>
                                        {errors.areaLocation}
                                      </p>
                                    </>
                                  )}
                              </div>
                            </div>
                            <div className={"col-6"}>
                              <div className={"input_main"}>
                                <label htmlFor="department">Department</label>
                                <SelectMain className="col-md-6 select_text w-100">
                                  <select
                                    className="form-select p-0"
                                    name={"departmentId"}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "departmentId",
                                        e.target.value
                                      )
                                    }
                                    value={values.departmentId}
                                    aria-label="Default select example"
                                  >
                                    {departmentList &&
                                      departmentList.map((el) => {
                                        return (
                                          <option
                                            key={el.id}
                                            value={el.id}
                                            disabled
                                          >
                                            {el.name}
                                          </option>
                                        );
                                      })}
                                    {/* <option value="5">Enviroment</option>
                                    <option value="6">Electricity</option> */}
                                  </select>
                                  {touched.departmentId &&
                                    errors.departmentId && (
                                      <p className={"text-danger d-block"}>
                                        {errors.departmentId}
                                      </p>
                                    )}
                                </SelectMain>
                              </div>
                            </div>
                            <div className={"col-12"}>
                              <div className={"input_main"}>
                                <label htmlFor="descriptionOfWork">
                                  Comments
                                </label>
                                <input
                                  type="text"
                                  placeholder={"Bidhan Nagar, Kolkata"}
                                  id={"descriptionOfWork"}
                                  name={"descriptionOfWork"}
                                  value={values.descriptionOfWork}
                                  onChange={handleChange}
                                  disabled={true}
                                />
                                {touched.descriptionOfWork &&
                                  errors.descriptionOfWork && (
                                    <>
                                      <br />
                                      <p className={"text-danger d-block"}>
                                        {errors.descriptionOfWork}
                                      </p>
                                    </>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={"container my-3"}>
                          <div className={"col-12"}>
                            <div className={"d-flex align-items-baseline"}>
                              <div>
                                <h4 className={"m-0"}>
                                  Does the work involve?
                                </h4>
                              </div>
                              <ButtonGroup className={"ms-4"}>
                                {radios &&
                                  radios.map((radio, idx) => (
                                    <ToggleButtonStyle
                                      key={idx}
                                      id={`radio-${idx}`}
                                      disabled={true}
                                      type="radio"
                                      name="typeOfWork"
                                      value={radio.value}
                                      checked={
                                        initiator &&
                                        initiator.typeOfWork === radio.value
                                      }
                                      onChange={(e) =>
                                        setFieldValue(
                                          "typeOfWork",
                                          e.currentTarget.value
                                        )
                                      }
                                    >
                                      {radio.name}
                                    </ToggleButtonStyle>
                                  ))}
                              </ButtonGroup>
                            </div>
                          </div>
                          {initiator && initiator.typeOfWork === 2 && (
                            <div className={"col-12 mt-3"}>
                              <ColdWorkOptionContainer>
                                {coldWorkOptions.map((option, index) => (
                                  <ToggleButtonCould
                                    className={"btn"}
                                    type={"button"}
                                    key={index}
                                    selected={
                                      getInitiatorDataSuccess &&
                                      getInitiatorDataSuccess.selectedTexts &&
                                      getInitiatorDataSuccess.selectedTexts.includes(
                                        option.value
                                      )
                                    }
                                    onClick={() => handleToggle(option.value)}
                                    disabled={true}
                                  >
                                    {option.name}
                                  </ToggleButtonCould>
                                ))}
                              </ColdWorkOptionContainer>
                              {touched.coldWorkList && errors.coldWorkList && (
                                <p className={"text-danger d-block"}>
                                  {errors.coldWorkList}
                                </p>
                              )}
                            </div>
                          )}
                          <div className={"col-12"}>
                            <div className="col-12 mt-3">
                              <PermitValidity>
                                <label htmlFor="permitValidityHr">
                                  Permit Validity (Hrs.)
                                </label>
                                <select
                                  id="permitValidityHr"
                                  name="permitValidityHr"
                                  onChange={handleChange}
                                  value={values.permitValidityHr}
                                  disabled={true}
                                >
                                  <option value="0">0</option>
                                  <option value="6">6</option>
                                  <option value="12">12</option>
                                  <option value="25" selected={true}>
                                    25
                                  </option>
                                </select>
                              </PermitValidity>
                              {touched.permitValidityHr &&
                              errors.permitValidityHr ? (
                                <div>{errors.permitValidityHr}</div>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        {isLifting && (
                          <>
                            <LiftingShifting>
                              <div className={"form_main_lifting"}>
                                <div
                                  className={
                                    "hot_worl_heading d-block w-100 border-0"
                                  }
                                >
                                  Lifting and shifting (L&S)
                                </div>
                                <div className={"container px-4 py-3"}>
                                  <div className={"single_select"}>
                                    <h5>1. Type of lifiting</h5>
                                    <select
                                      name="typeOfLifting"
                                      onChange={handleChange}
                                      value={
                                        initiator && initiator.typeOfLifting
                                      }
                                      id="typeOfLifting"
                                      disabled={true}
                                    >
                                      <option value="1">select lifting</option>
                                      <option value="2">Lifting 1</option>
                                    </select>
                                  </div>
                                  <div className={"single_select comment"}>
                                    <h5>2. Maximum Operating Radius</h5>
                                    <input
                                      type="text"
                                      name={"operatingComments"}
                                      value={values.operatingComments}
                                      onChange={handleChange}
                                      placeholder={"Enter your comments"}
                                      disabled={true}
                                    />
                                  </div>
                                  <div className={"single_select comment mt-3"}>
                                    <h5>3. Need more permit time?</h5>
                                    <CheckboxInputLifting
                                      className={"hb-colour-options"}
                                    >
                                      <div>
                                        <input
                                          type="radio"
                                          onChange={handleChange}
                                          name="needMorePermit"
                                          id="yes"
                                          value="Yes"
                                          disabled={true}
                                        />
                                        <label htmlFor="yes">Yes</label>
                                      </div>
                                      <div>
                                        <input
                                          type="radio"
                                          onChange={handleChange}
                                          name="needMorePermit"
                                          id="no"
                                          value="No"
                                          disabled={true}
                                        />
                                        <label htmlFor="no">No</label>
                                      </div>
                                    </CheckboxInputLifting>
                                    <input
                                      type="text"
                                      name={"comment"}
                                      value={values.comment}
                                      onChange={handleChange}
                                      placeholder={"Enter your comments"}
                                      disabled={true}
                                    />
                                  </div>
                                </div>
                                <div className={"container d-none"}>
                                  <div className={"update_request"}>
                                    <button>Update Request</button>
                                    <button>Permit increase request</button>
                                  </div>
                                </div>
                              </div>
                            </LiftingShifting>
                          </>
                        )}
                        <div className={"container my-3 px-0"}>
                          <div>
                            <WorkListStyle>
                              {type1Responses.length > 0 && (
                                <div>
                                  <div
                                    className={
                                      "hot_worl_heading d-block w-100 border-0"
                                    }
                                  >
                                    Hot work
                                  </div>
                                  <div className={"py-3"}>
                                    {type1Responses.map((item, index) => (
                                      <div className={"p-1"}>
                                        <div
                                          className={
                                            "d-flex justify-content-between py-2"
                                          }
                                          key={index + 1}
                                        >
                                          <label
                                            className={"d-block"}
                                            htmlFor={`checkbox-${item.id}`}
                                          >
                                            {index + 1}) {item.label}
                                          </label>
                                          <div className={"d-flex"}>
                                            <CheckboxWrapper>
                                              <CheckboxInput
                                                disabled={true}
                                                type="checkbox"
                                                id={`checkbox-${item.id}-yes`}
                                                checked={checkboxValues.some(
                                                  (val) =>
                                                    val.id === item.id &&
                                                    val.answer === "yes"
                                                )}
                                                onChange={() =>
                                                  handleCheckboxChange(
                                                    item.id,
                                                    "yes"
                                                  )
                                                }
                                              />
                                              <CheckboxLabel
                                                htmlFor={`checkbox-${item.id}-yes`}
                                              >
                                                Yes
                                              </CheckboxLabel>
                                            </CheckboxWrapper>
                                            <CheckboxWrapper>
                                              <CheckboxInput
                                                disabled={true}
                                                type="checkbox"
                                                id={`checkbox-${item.id}-no`}
                                                checked={checkboxValues.some(
                                                  (val) =>
                                                    val.id === item.id &&
                                                    val.answer === "no"
                                                )}
                                                onChange={() =>
                                                  handleCheckboxChange(
                                                    item.id,
                                                    "no"
                                                  )
                                                }
                                              />
                                              <CheckboxLabel
                                                htmlFor={`checkbox-${item.id}-no`}
                                              >
                                                No
                                              </CheckboxLabel>
                                            </CheckboxWrapper>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                              {type2Responses.length > 0 && (
                                <div className={"py-3"}>
                                  <div>
                                    <div
                                      className={
                                        "hot_worl_heading d-block w-100 border-0"
                                      }
                                    >
                                      Confined Space Entry (CSE)
                                    </div>
                                    {type2Responses.map((item, index) => (
                                      <div className={"px-4"}>
                                        <div
                                          className={
                                            "d-flex justify-content-between py-2"
                                          }
                                          key={index + 1}
                                        >
                                          <label
                                            className={"d-block"}
                                            htmlFor={`checkbox-${item.id}`}
                                          >
                                            {index + 1}) {item.label}
                                          </label>
                                          <div className={"d-flex"}>
                                            <CheckboxWrapper>
                                              <CheckboxInput
                                                disabled={true}
                                                type="checkbox"
                                                id={`checkbox-${item.id}-yes`}
                                                checked={checkboxValues.some(
                                                  (val) =>
                                                    val.id === item.id &&
                                                    val.answer === "yes"
                                                )}
                                                onChange={() =>
                                                  handleCheckboxChange(
                                                    item.id,
                                                    "yes"
                                                  )
                                                }
                                              />
                                              <CheckboxLabel
                                                htmlFor={`checkbox-${item.id}-yes`}
                                              >
                                                Yes
                                              </CheckboxLabel>
                                            </CheckboxWrapper>
                                            <CheckboxWrapper>
                                              <CheckboxInput
                                                disabled={true}
                                                type="checkbox"
                                                id={`checkbox-${item.id}-no`}
                                                checked={checkboxValues.some(
                                                  (val) =>
                                                    val.id === item.id &&
                                                    val.answer === "no"
                                                )}
                                                onChange={() =>
                                                  handleCheckboxChange(
                                                    item.id,
                                                    "no"
                                                  )
                                                }
                                              />
                                              <CheckboxLabel
                                                htmlFor={`checkbox-${item.id}-no`}
                                              >
                                                No
                                              </CheckboxLabel>
                                            </CheckboxWrapper>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}

                              {type3Responses.length > 0 && (
                                <div>
                                  <div
                                    className={
                                      "hot_worl_heading d-block w-100 border-0"
                                    }
                                  >
                                    Work at height (WAH)
                                  </div>
                                  {type3Responses.map((item, index) => (
                                    <div className={"px-3"}>
                                      <div
                                        className={
                                          "d-flex justify-content-between py-2"
                                        }
                                        key={index + 1}
                                      >
                                        <label
                                          className={"d-block"}
                                          htmlFor={`checkbox-${item.id}`}
                                        >
                                          {index + 1}) {item.label}
                                        </label>
                                        <div className={"d-flex"}>
                                          <CheckboxWrapper>
                                            <CheckboxInput
                                              disabled={true}
                                              type="checkbox"
                                              id={`checkbox-${item.id}-yes`}
                                              checked={checkboxValues.some(
                                                (val) =>
                                                  val.id === item.id &&
                                                  val.answer === "yes"
                                              )}
                                              onChange={() =>
                                                handleCheckboxChange(
                                                  item.id,
                                                  "yes"
                                                )
                                              }
                                            />
                                            <CheckboxLabel
                                              htmlFor={`checkbox-${item.id}-yes`}
                                            >
                                              Yes
                                            </CheckboxLabel>
                                          </CheckboxWrapper>
                                          <CheckboxWrapper>
                                            <CheckboxInput
                                              disabled={true}
                                              type="checkbox"
                                              id={`checkbox-${item.id}-no`}
                                              checked={checkboxValues.some(
                                                (val) =>
                                                  val.id === item.id &&
                                                  val.answer === "no"
                                              )}
                                              onChange={() =>
                                                handleCheckboxChange(
                                                  item.id,
                                                  "no"
                                                )
                                              }
                                            />
                                            <CheckboxLabel
                                              htmlFor={`checkbox-${item.id}-no`}
                                            >
                                              No
                                            </CheckboxLabel>
                                          </CheckboxWrapper>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                              {type4Responses.length > 0 && (
                                <div>
                                  <div
                                    className={
                                      "hot_worl_heading d-block w-100 border-0"
                                    }
                                  >
                                    Electrical Permit (EP)
                                  </div>
                                  {type4Responses.map((item, index) => (
                                    <div
                                      className={
                                        "d-flex justify-content-between py-2"
                                      }
                                      key={index + 1}
                                    >
                                      <label
                                        className={"d-block"}
                                        htmlFor={`checkbox-${item.id}`}
                                      >
                                        {index + 1}) {item.label}
                                      </label>
                                      <div className={"d-flex"}>
                                        <CheckboxWrapper>
                                          <CheckboxInput
                                            disabled={true}
                                            type="checkbox"
                                            id={`checkbox-${item.id}-yes`}
                                            checked={checkboxValues.some(
                                              (val) =>
                                                val.id === item.id &&
                                                val.answer === "yes"
                                            )}
                                            onChange={() =>
                                              handleCheckboxChange(
                                                item.id,
                                                "yes"
                                              )
                                            }
                                          />
                                          <CheckboxLabel
                                            htmlFor={`checkbox-${item.id}-yes`}
                                          >
                                            Yes
                                          </CheckboxLabel>
                                        </CheckboxWrapper>
                                        <CheckboxWrapper>
                                          <CheckboxInput
                                            disabled={true}
                                            type="checkbox"
                                            id={`checkbox-${item.id}-no`}
                                            checked={checkboxValues.some(
                                              (val) =>
                                                val.id === item.id &&
                                                val.answer === "no"
                                            )}
                                            onChange={() =>
                                              handleCheckboxChange(
                                                item.id,
                                                "no"
                                              )
                                            }
                                          />
                                          <CheckboxLabel
                                            htmlFor={`checkbox-${item.id}-no`}
                                          >
                                            No
                                          </CheckboxLabel>
                                        </CheckboxWrapper>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}

                              {type6Responses.length > 0 && (
                                <div>
                                  <div
                                    className={
                                      "hot_worl_heading d-block w-100 border-0"
                                    }
                                  >
                                    Excavation Certificate (EC)
                                  </div>
                                  {type6Responses.map((item, index) => (
                                    <div className={"px-4"}>
                                      <div
                                        className={
                                          "d-flex justify-content-between py-2"
                                        }
                                        key={index + 1}
                                      >
                                        <label
                                          className={"d-block"}
                                          htmlFor={`checkbox-${item.id}`}
                                        >
                                          {index + 1}) {item.label}
                                        </label>
                                        <div className={"d-flex"}>
                                          <CheckboxWrapper>
                                            <CheckboxInput
                                              disabled={true}
                                              type="checkbox"
                                              id={`checkbox-${item.id}-yes`}
                                              checked={checkboxValues.some(
                                                (val) =>
                                                  val.id === item.id &&
                                                  val.answer === "yes"
                                              )}
                                              onChange={() =>
                                                handleCheckboxChange(
                                                  item.id,
                                                  "yes"
                                                )
                                              }
                                            />
                                            <CheckboxLabel
                                              htmlFor={`checkbox-${item.id}-yes`}
                                            >
                                              Yes
                                            </CheckboxLabel>
                                          </CheckboxWrapper>
                                          <CheckboxWrapper>
                                            <CheckboxInput
                                              disabled={true}
                                              type="checkbox"
                                              id={`checkbox-${item.id}-no`}
                                              checked={checkboxValues.some(
                                                (val) =>
                                                  val.id === item.id &&
                                                  val.answer === "no"
                                              )}
                                              onChange={() =>
                                                handleCheckboxChange(
                                                  item.id,
                                                  "no"
                                                )
                                              }
                                            />
                                            <CheckboxLabel
                                              htmlFor={`checkbox-${item.id}-no`}
                                            >
                                              No
                                            </CheckboxLabel>
                                          </CheckboxWrapper>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}

                              {type7Responses.length > 0 && (
                                <div>
                                  <div
                                    className={
                                      "hot_worl_heading d-block w-100 border-0"
                                    }
                                  >
                                    Check list for Coordinator (AD)
                                  </div>
                                  {type7Responses.map((item, index) => (
                                    <div className={"px-4"} s>
                                      <div
                                        className={
                                          "d-flex justify-content-between py-2"
                                        }
                                        key={index + 1}
                                      >
                                        <label
                                          className={"d-block"}
                                          htmlFor={`checkbox-${item.id}`}
                                        >
                                          {index + 1}) {item.label}
                                        </label>
                                        <div className={"d-flex"}>
                                          <CheckboxWrapper>
                                            <CheckboxInput
                                              disabled={true}
                                              type="checkbox"
                                              id={`checkbox-${item.id}-yes`}
                                              checked={checkboxValues.some(
                                                (val) =>
                                                  val.id === item.id &&
                                                  val.answer === "yes"
                                              )}
                                              onChange={() =>
                                                handleCheckboxChange(
                                                  item.id,
                                                  "yes"
                                                )
                                              }
                                            />
                                            <CheckboxLabel
                                              htmlFor={`checkbox-${item.id}-yes`}
                                            >
                                              Yes
                                            </CheckboxLabel>
                                          </CheckboxWrapper>
                                          <CheckboxWrapper>
                                            <CheckboxInput
                                              disabled={true}
                                              type="checkbox"
                                              id={`checkbox-${item.id}-no`}
                                              checked={checkboxValues.some(
                                                (val) =>
                                                  val.id === item.id &&
                                                  val.answer === "no"
                                              )}
                                              onChange={() =>
                                                handleCheckboxChange(
                                                  item.id,
                                                  "no"
                                                )
                                              }
                                            />
                                            <CheckboxLabel
                                              htmlFor={`checkbox-${item.id}-no`}
                                            >
                                              No
                                            </CheckboxLabel>
                                          </CheckboxWrapper>
                                          <CheckboxWrapper>
                                            <CheckboxInput
                                              disabled={true}
                                              type="checkbox"
                                              id={`checkbox-${item.id}-na`}
                                              checked={
                                                checkboxValues &&
                                                checkboxValues.some(
                                                  (val) =>
                                                    val.id === item.id &&
                                                    val.answer === "na"
                                                )
                                              }
                                              onChange={() =>
                                                handleCheckboxChange(
                                                  item.id,
                                                  "na"
                                                )
                                              }
                                            />
                                            <CheckboxLabel
                                              htmlFor={`checkbox-${item.id}-na`}
                                            >
                                              NA
                                            </CheckboxLabel>
                                          </CheckboxWrapper>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                              <CommentsStyle>
                                <div className={"px-4"}>
                                  <label htmlFor="comment">Comments</label>
                                  <input
                                    name={"comment"}
                                    disabled={true}
                                    value={values.comment}
                                    onChange={handleChange}
                                    id={"comment"}
                                    type="text"
                                    placeholder={"Enter your comments"}
                                  />
                                </div>
                              </CommentsStyle>
                            </WorkListStyle>
                          </div>
                        </div>
                        <div className={"container my-4"}>
                          <div className={"row"}>
                            {adminData.roleId ===
                            config.role.coOrdinatorRole ? (
                              <div className={"col-xl-12"}>
                                {initiator &&
                                  initiator?.status === "CustodianApproved" && (
                                    <div className={"text-center"}>
                                      <PermitApprovedBtn />
                                    </div>
                                  )}
                              </div>
                            ) : (
                              ""
                            )}
                            <div className={"col-xl-6"}>
                              {initiator &&
                                initiator?.status === "CustodianApproved" && (
                                  <>
                                    {type7Responses.length > 0 ? (
                                      ""
                                    ) : (
                                      <>
                                        {adminData.roleId ===
                                        config.role.coOrdinatorRole ? (
                                          <>
                                            <button
                                              className={"createCheckList m-0"}
                                              onClick={() => {
                                                dispatch(
                                                  createCheckListTogle()
                                                );
                                                dispatch(
                                                  initiatorForCheckList(
                                                    initiator
                                                  )
                                                );
                                              }}
                                            >
                                              Create CheckList +
                                            </button>
                                          </>
                                        ) : (
                                          " "
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                            </div>

                            <div className={"col-xl-12"}>
                              {/* <div className={"col-xl-6"}> */}
                              <>
                                {adminData.roleId ===
                                config.role.coOrdinatorRole ? (
                                  <>
                                    <div
                                      className={"d-flex justify-content-end"}
                                    >
                                      {initiator &&
                                        initiator?.status === "Requested" && (
                                          <button
                                            className={`initiatorButtonSubmit m-0 opacity-100`}
                                            type="button"
                                          >
                                            Approval in Progress
                                          </button>
                                        )}
                                      {initiator &&
                                        initiator?.status === "Requested" && (
                                          <button
                                            className={`initiatorButtonSubmit m-0 opacity-50`}
                                            type="button"
                                            onClick={submitForApprove}
                                            disabled={true}
                                            // disabled={initiator && initiator?.status === "CustodianApproved" || "Requested"  ? true : false}
                                          >
                                            Submit for Approval
                                          </button>
                                        )}
                                      {/* {initiator &&
                                        initiator?.status ===
                                          "CustodianApproved" && (
                                          <button
                                            className={`initiatorButtonSubmit m-0 opacity-100`}
                                            type="button"
                                            onClick={submitForApprove}
                                          >
                                            Submit for Approved
                                          </button>
                                        )} */}
                                      {initiator &&
                                        initiator?.status ===
                                          "CheckListCreated" && (
                                          <button
                                            className={`initiatorButtonSubmit m-0 opacity-100`}
                                            type="button"
                                          >
                                            Work in Progress
                                          </button>
                                        )}
                                      {initiator &&
                                        initiator?.status ===
                                          "CheckListCreated" && (
                                          <button
                                            className={`initiatorButtonSubmit m-0 opacity-50`}
                                            type="button"
                                            onClick={submitForApprove}
                                            disabled={true}
                                          >
                                            Complete
                                          </button>
                                        )}
                                      {initiator &&
                                        initiator?.status ===
                                          "IssuerCompleted" && (
                                          <button
                                            className={`initiatorButtonSubmit m-0 opacity-100`}
                                            type="button"
                                            onClick={submitForApprove}
                                          >
                                            Complete
                                          </button>
                                        )}
                                      {initiator &&
                                        initiator?.status ===
                                          "SubmiteForCompleted" && (
                                          <button
                                            className={`initiatorButtonSubmit m-0 opacity-100`}
                                            type="button"
                                            disabled={true}
                                          >
                                            In Review
                                          </button>
                                        )}
                                      {initiator &&
                                        initiator?.status ===
                                          "SubmiteForCompleted" && (
                                          <button
                                            className={`initiatorButtonSubmit m-0 opacity-50`}
                                            type="button"
                                            disabled={true}
                                          >
                                            Complete
                                          </button>
                                        )}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            </div>
                            <div className={"col-xl-12"}>
                              <div
                                className={"d-flex justify-content-end py-3"}
                              >
                                {adminData.roleId ===
                                  config.role.issuerRole && (
                                  <>
                                    {initiator &&
                                      initiator?.status === "Requested" && (
                                        <button
                                          className={`initiatorButtonSubmit m-0 opacity-100`}
                                          type="button"
                                        >
                                          Approval in Progress
                                        </button>
                                      )}
                                    {initiator &&
                                      initiator?.status ===
                                        "CheckListCreated" && (
                                        <button
                                          className={`initiatorButtonSubmit m-0 opacity-100`}
                                          type="button"
                                          onClick={handleRequestCompleted}
                                        >
                                          Request for Completed
                                        </button>
                                      )}
                                    {initiator &&
                                      initiator?.status ===
                                        "SubmiteForCompleted" && (
                                        <button
                                          className={`initiatorButtonSubmit m-0 opacity-100`}
                                          // type="button"
                                          // onClick={handleRequestCompleted}
                                        >
                                          In Review
                                        </button>
                                      )}
                                    {initiator &&
                                      initiator?.status ===
                                        "IssuerCompleted" && (
                                        <button
                                          className={`initiatorButtonSubmit m-0 opacity-100`}
                                          type="button"
                                          // onClick={submitForApprove}
                                          disabled={true}
                                        >
                                          In Review
                                        </button>
                                      )}
                                    {initiator &&
                                      initiator?.status ===
                                        "IssuerCompleted" && (
                                        <button
                                          className={`initiatorButtonSubmit m-0 opacity-50`}
                                          type="button"
                                          onClick={submitForApprove}
                                          disabled={true}
                                        >
                                          Submit for Approved
                                        </button>
                                      )}

                                    {initiator &&
                                      initiator?.status ===
                                        "SubmiteForCompleted" && (
                                        <button
                                          className={`initiatorButtonSubmit m-0 opacity-50`}
                                          type="button"
                                          disabled={true}
                                        >
                                          Submit for Approved
                                        </button>
                                      )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className={"col-xl-12"}>
                              {adminData.roleId ===
                              config.role.custodianRole ? (
                                <div className={"d-flex justify-content-end"}>
                                  {initiator &&
                                    initiator?.status === "Requested" && (
                                      <div className={"d-flex"}>
                                        <button
                                          className={`initiatorButtonSubmit m-2 opacity-100`}
                                          type="button"
                                          onClick={() =>
                                            handleCustodian("Rejected")
                                          }
                                        >
                                          {/* Custodian Close */}
                                          Close Permit
                                        </button>
                                        <button
                                          className={`initiatorButtonSubmit m-2 opacity-100`}
                                          type="button"
                                          onClick={() =>
                                            handleCustodian("CustodianApproved")
                                          }
                                        >
                                          Custodian Approve
                                        </button>
                                      </div>
                                    )}
                                  {/*Disable button show*/}
                                  {initiator &&
                                    initiator?.status ===
                                      "CustodianApproved" && (
                                      <div className={"d-flex"}>
                                        <button
                                          className={`initiatorButtonSubmit m-2 opacity-50`}
                                          type="button"
                                          disabled={true}
                                        >
                                          Close
                                        </button>
                                        <button
                                          className={`initiatorButtonSubmit m-2 opacity-50`}
                                          type="button"
                                          disabled={true}
                                        >
                                          Submit for Approve
                                        </button>
                                      </div>
                                    )}

                                  {
                                    initiator &&
                                      initiator?.status ===
                                        "SubmiteForCompleted" && (
                                        //     ) ||
                                        // initiator?.status === "IssuerCompleted" ||
                                        // (initiator &&
                                        //   initiator?.status ===
                                        //     "CheckListCreated") ?
                                        <div className={"d-flex"}>
                                          {/* <button
                                            className={`initiatorButtonSubmit m-2 opacity-100`}
                                            type="button"
                                            onClick={() =>
                                              handleCustodian("Rejected")
                                            }
                                          >
                                            Close
                                          </button> */}
                                          <button
                                            className={`initiatorButtonSubmit m-2 opacity-100`}
                                            type="button"
                                            onClick={() =>
                                              handleCustodian("Approved")
                                            }
                                          >
                                            Close
                                          </button>
                                        </div>
                                      )
                                    //  : (
                                    //   ""
                                  }
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div>
                              {initiator &&
                                initiator?.status === "Approved" && (
                                  <div className={"text-center"}>
                                    <ApprovedBtn />
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </CreateInitiatorFormStyle>
              </CreateInitiatorScreen>
            </CreateInitiatorBodyStyle>
          </div>
          <CheckListModel />
        </div>
      </div>
    </>
  );
};
export default InitiatorDetails;
