import {createSlice} from "@reduxjs/toolkit";

export const departmentShiftSlice = createSlice({
    name:"department",
    initialState:{
        loading:false,
        error:null,
        department:null,
        allShift:null,
        loadingShift:false,
        loadingRoles:false,
        roles:null,
        addUpdateShift:false,
        getShiftIdForUpdate:null,
    },
    reducers:{
        // users request
        departmentRequest:(state) => {
            state.loading = !state.loading;
        },
        getDepartmentSuccess:(state,action) => {
            state.loading = !state.loading;
            state.department = action.payload;
        },
        getDepartmentFailure:(state,message)=>{
            state.loading = !state.loading;
            state.error = message
        },
        // users request
        getAllShiftRequest:(state) => {
            state.loadingShift = !state.loadingShift;
        },
        getAllShiftSuccess:(state,action) => {
            state.loadingShift = !state.loadingShift;
            state.allShift = action.payload;
        },
        getAllShiftFailure:(state,message)=>{
            state.loadingShift = !state.loadingShift;
            state.error = message
        },

        // get roles request
        getRolesRequest:(state) => {
            state.loadingRoles = !state.loadingRoles;
        },
        getRolesSuccess:(state,action) => {
            state.loadingRoles = !state.loadingRoles;
            state.roles = action.payload;
        },
        getRolesFailure:(state,message)=>{
            state.loadingRoles = !state.loadingRoles;
            state.error = message
        },

        // Save and Update Shift
        addUpdateShiftRequest:(state) => {
            state.loadingRoles = !state.loadingRoles;
        },
        addUpdateShiftSuccess:(state,action) => {
            state.loadingRoles = !state.loadingRoles;
            state.roles = action.payload;
            state.addUpdateShift = !state.addUpdateShift
        },
        addUpdateShiftFailure:(state,message)=>{
            state.loadingRoles = !state.loadingRoles;
            state.error = message
            state.addUpdateShift = !state.addUpdateShift;
        },
        getShiftId:(state, action)=>{
          state.getShiftIdForUpdate = action.payload
        },
        clearShiftId:(state,action)=>{
          state.getShiftIdForUpdate = null
        },
        toggleAddUpdateShift:(state)=>{
            state.addUpdateShift = !state.addUpdateShift;
        },

    }
})

export const {
    getAllShiftRequest,
    getAllShiftSuccess,
    getAllShiftFailure,
    departmentRequest,
    getDepartmentSuccess,
    getDepartmentFailure,
    getRolesRequest,
    getRolesSuccess,
    getRolesFailure ,
    addUpdateShiftRequest,
    addUpdateShiftSuccess,
    addUpdateShiftFailure,
    toggleAddUpdateShift,
    getShiftId,
    clearShiftId,
} =  departmentShiftSlice.actions;

export default departmentShiftSlice.reducer