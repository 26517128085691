import styled from "styled-components"

export const DepartmentMain = styled.div`
  //width: 240px;
  //height:60px;
  border: 1px solid #2D3E50;
  border-radius: 5px;
  background: #fff;
  color: black;
  padding: 17px;
  color: #2D3E50;
  width: 100%;
  margin: 6px 0;
`

export const DepartmentTabs = styled('div')({
  height: "100",
  ul: {
    paddingTop: "16px",
    border: "none",
    marginLeft:"36px",
    ".nav-item > .nav-link": {
      border:"none",
      borderRadius:"0",
      color:"#2D3E50",
      fontFamily: 'Josefin Sans',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "16px",
      opacity: 0.5,
    },
    ".nav-item > .nav-link.active": {
      border: "none",
      opacity:1,
      textDecorationLine: "underline",
    },
    li: {
      border: "none",
      color:"#000 !important",
      button: {
        border: "none",
        ":focus": {
          outline: "none",
          boxShadow: "none",
          border:"none0"
        },
        "&:hover":{
          outline: "none",
          boxShadow: "none",
          border:"none"
        }
      }
    }
  },
  ".issuerTab": {
    paddingTop: "8px",
    border: "none",
    marginLeft:"36px",
    ".nav-item > .nav-link": {
      border:"none",
      borderRadius:"0",
      color:"#2D3E50",
      fontFamily: 'Josefin Sans',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "25px",
      opacity: 0.5,
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",

    },
    ".nav-item > .nav-link.active": {
      border: "none",
      opacity:1,
      textDecorationLine: "underline",
    },
    li: {
      border: "none",
      color:"#000 !important",
      button: {
        border: "none",
        ":focus": {
          outline: "none",
          boxShadow: "none",
          border:"none0"
        },
        "&:hover":{
          outline: "none",
          boxShadow: "none",
          border:"none"
        }
      }
    }
  },
  ".co_ordinator_permit":{
    li: {
      padding: "12px",
      button: {
        backgroundColor: "transparent !important",
      },
    },
  },

});

// Department Add Edit
export const AddEditTitle = styled.div`
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;  
  color: #2D3E50;
  padding-top:25px;
`

export const ButtonDepartMent = styled.button`
  background: linear-gradient(118.68deg, #E4385E -20.39%, #FF9C61 94.52%);
  border-radius: 26.5px;
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  border:none;
  padding:12px 48px;
  display:block;
`
export const SubTitle = styled.h3`
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #2D3E50;
  padding-top:90px;
  padding-bottom:30px;
`