import { createSlice } from "@reduxjs/toolkit";

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    loading: false,
    error: null,
    usersList: null,
    upateUserGet: null,
    addUser: null,
    updateLoading: false,
    singleUserGetLoading: false,
    userModelToggle: false,

    // User Details
    userLoading: false,
    userDetails: null,
    userError: null,

    // Delete user by ID
    deleteToggle: false,
    deleteUserLoading: false,
    deleteUserDetails: null,
    deleteUserError: null,
  },
  reducers: {
    // users request
    usersRequest: (state) => {
      state.loading = !state.loading;
    },
    usersSuccess: (state, action) => {
      state.loading = !state.loading;
      state.usersList = action.payload;
    },
    usersFaield: (state, message) => {
      state.loading = false;
      state.error = message;
    },
    setUsersRequest: (state, action) => {
      state.singleUserGetLoading = !state.singleUserGetLoading;
      state.upateUserGet = action.payload;
    },
    setUserSuccess: (state, action) => {
      state.upateUserGet = action.payload;
      state.singleUserGetLoading = !state.singleUserGetLoading;
    },
    setUsersFaield: (state, message) => {
      state.singleUserGetLoading = false;
      state.error = message;
    },
    clearSetUser: (state, action) => {
      state.upateUserGet = null;
    },
    clearAddUser: (state, action) => {
      state.addUser = null;
    },

    getUserRequest: (state, action) => {
      state.userLoading = !state.userLoading;
    },
    getUserSuccess: (state, action) => {
      state.userDetails = action.payload;
      state.userLoading = !state.userLoading;
      state.userError = null;
    },
    getUserFailed: (state, message) => {
      state.userLoading = false;
      state.userDetails = null;
      state.userError = message;
    },

    //Upate Users
    updateUserRequest: (state, action) => {
      state.updateLoading = !state.updateLoading;
    },
    updateUserSuccess: (state, action) => {
      state.updateLoading = !state.updateLoading;
      state.addUser = action.payload;
      state.userModelToggle = false;
      state.upateUserGet = null;
    },
    updateUserFaield: (state, message) => {
      state.updateLoading = false;
      state.error = message;
      state.userModelToggle = !state.userModelToggle;
    },

    // Delete User By ID
    deleteUserToggle: (state, action) => {
      state.deleteToggle = !state.deleteToggle;
    },
    deleteUserRequest: (state, action) => {
      state.deleteUserLoading = !state.deleteUserLoading;
    },
    deleteUserSuccess: (state, action) => {
      state.deleteUserDetails = action.payload;
      state.deleteToggle = !state.deleteToggle;
    },
    deleteUserFailed: (state, message) => {
      state.deleteUserLoading = false;
      state.deleteUserError = message;
      state.userModelToggle = !state.userModelToggle;
    },
    // Add Update User model Toggle
    userAddUpdateToggle: (state) => {
      state.userModelToggle = !state.userModelToggle;
    },
  },
});

export const {
  usersRequest,
  usersSuccess,
  usersFaield,
  setUsersRequest,
  setUsersFaield,
  setUserSuccess,
  clearSetUser,
  updateUserFaield,
  updateUserSuccess,
  updateUserRequest,
  userAddUpdateToggle,
  getUserRequest,
  getUserSuccess,
  deleteUserRequest,
  deleteUserSuccess,
  deleteUserFailed,
  getUserFailed,
  deleteUserToggle,
} = usersSlice.actions;

export default usersSlice.reducer;
