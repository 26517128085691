import {
  DELETE_USER,
  GET_USER_FOR_DETAILS,
  GET_USER_FOR_UPDATE,
  UPDATE_USER,
  USERS_REQUEST,
} from "../constant";
import { takeLatest, call, put } from "redux-saga/effects";
import Api from "../../api/api";
import {
  deleteUserFailed,
  deleteUserRequest,
  deleteUserSuccess,
  getUserFailed,
  getUserRequest,
  getUserSuccess,
  setUsersFaield,
  setUsersRequest,
  setUserSuccess,
  updateUserFaield,
  updateUserRequest,
  updateUserSuccess,
  userAddUpdateToggle,
  usersFaield,
  usersRequest,
  usersSuccess,
} from "../Slice/usersSlice";
import axios from "axios";
import config from "../../config";
import { json } from "react-router-dom";
import toast from "react-hot-toast";

function* getUsersRequest(action) {
  try {
    yield put(usersRequest());
    const response = yield call(
      Api.post,
      "/api/user/getAllUsers",
      action.payload
    );
    // console.log(response.data,"/api/user/getAllUsers <== response")
    yield put(usersSuccess(response?.data));
  } catch (error) {
    console.log(error);
    yield put(usersFaield(error));
  }
}

function* getSingleUser(action) {
  try {
    yield put(setUsersRequest());
    const response = yield call(
      Api.get,
      `/api/user/getUserDetail/${action.payload}`
    );
    // console.log(response.data,"/api/user/getUserDetail <== response")
    yield put(setUserSuccess(response?.data));
  } catch (error) {
    console.log(error);
    yield put(setUsersFaield(error));
  }
}
function* getSingleUserDetails(action) {
  try {
    yield put(getUserRequest());
    const response = yield call(
      Api.get,
      `/api/user/getUserDetail/${action.payload}`
    );
    // console.log(response.data,"/api/user/getUserDetail <== response")
    yield put(getUserSuccess(response?.data));
  } catch (error) {
    console.log(error);
    yield put(getUserFailed(error));
  }
}

function* updateUser(action) {
  const token = localStorage.getItem("access_token");
  let response;
  try {
    yield put(updateUserRequest());
    yield (response = fetch(`${config.apiUrl}/api/user/saveOrUpdate`, {
      method: "POST",
      headers: {
        //     // Accept: 'application/json',
        //     'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      body: action.payload,
    })
      .then((response) => response.json())
      .then((json) => json));
    response.then((data) => {
      if (data.status === 200) {
        toast.success("User saved successfully");
      }
      if (data.status === 500) {
        toast.error(`${data.message}`);
      }
      return data;
    });
    yield put(updateUserSuccess(response));
    const filter = {
      locationId: 1,
      filterBy: "",
      searchText: "",
    };
    yield put({ type: USERS_REQUEST, payload: filter });
  } catch (error) {
    yield put(updateUserFaield(error));
    yield call(userAddUpdateToggle());
    toast.error(error.message ? error.message : "Faield to save User");
  }
}

// Delete User By ID
function* deleteUserById(action) {
  const filter = {
    locationId: 1,
    filterBy: "",
    searchText: "",
  };
  try {
    yield put(deleteUserRequest());
    const response = yield call(
      Api.delete,
      `/api/user/delete/${action.payload}`
    );
    // console.log(response,"/api/user/delete/ <==== Response")
    yield put(deleteUserSuccess(response));
    // if(response.status.status == 200){
    yield put({ type: USERS_REQUEST, payload: filter });
    // }
  } catch (error) {
    console.log(error);
    yield put(deleteUserFailed(error));
  }
}

function* usersSaga() {
  yield takeLatest(USERS_REQUEST, getUsersRequest);
  yield takeLatest(GET_USER_FOR_UPDATE, getSingleUser);
  yield takeLatest(GET_USER_FOR_DETAILS, getSingleUserDetails);
  yield takeLatest(UPDATE_USER, updateUser);
  yield takeLatest(DELETE_USER, deleteUserById);
}
export default usersSaga;
