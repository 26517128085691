import {styled} from "styled-components";

export const NotificationTable = styled("div")({
    background:" #FFFFFF",
    boxShadow:" 0px 2px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",

    "::-webkit-scrollbar-thumb": {
        backgroundColor: "#2D3E50",
        borderRadius: "10px",
    },
    "::-webkit-scrollbar-track": {
        background: "#CECECE",
        margin:"30px 0 15px 0",
        borderRadius: "10px",
    },
    "*::-webkit-scrollbar": {
        width: "4px",
        borderRadius: "10px",
    },
    "*": {
        scrollbarWidth: "auto",
        scrollbarColor: "#8f54a0 #ffffff",
    },
    ".notification-table": {
        overflow: "auto",
        height: "78vh",
        marginRight:"5px",
        paddingLeft:"15px",
        "table": {
            color:"#2D3E50",
            // "thead": {
            //     "tr": {
            //         "th": {
            //             position: "sticky",
            //             top: "0",
            //             zIndex: "1",
            //             backgroundColor: "#fff",
            //             color:"#7C858F",
            //             fontWeight:"500"
            //         },
            //         ":first-child": {
            //             borderTopLeftRadius: "15px",
            //         },
            //         ":last-child": {
            //             borderTopRightRadius: "15px",
            //         }
            //     }
            // },
            "tbody": {
                "tr": {
                    borderBottom:"1px solid #ECEBEB",
                    padding:"15px 0",
                    display:"block",
                    fontSize: "15px",
                    color: "#2D3E50",
                    ".main_notification":{
                      paddingRight:"45px"
                    },
                    // "td": {
                    //     fontFamily: 'Josefin Sans',
                    //     fontStyle: "normal",
                    //     fontWeight:" 600",
                    //     fontSize: "15px",
                    //     // textAlign:" center",
                    //     color: "#2D3E50",
                    // }
                }
            }
        }
    }
})

export const StatusNotification = styled.div`
  background: #FF8946;
  border-radius: 25px;
  width: 24px;
  height: 24px;
  display: inline-block;
  margin: 10px 25px 10px 0;
`