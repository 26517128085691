import React, { useEffect, useState } from "react";
import NavDashboard from "../components/Navbar";
import Drawer from "../components/Drawer";
import { Nav, Tab, Tabs } from "react-bootstrap";
import DepartmentCompo from "../components/DepartmentCompo";
import Shift from "../components/Shift";
import { DepartmentTabs } from "../styles/Department.style";
import { useDispatch, useSelector } from "react-redux";
import {
  DEPARTMENT_REQUEST,
  GET_ALL_PERMIT,
  GET_DEPARTMENT_REQUEST_FOR_ISSUER,
  GET_INITIATOR_BYID,
  GET_PERMIT_BY_ID,
} from "../store/constant";
import {
  DetailsBtnPermit,
  NewPermit,
  ParmitTable,
  PermitRaised,
  SearchBoxStyle,
  StatusPermit,
} from "../styles/Permit.style";
import {
  SelectIssuer,
  SelectSearch,
  UserForm,
} from "../styles/UsersSearchTopBar";
import { ReactComponent as SearchIcon } from "../assets/search_icon.svg";
import {
  clearPermitById,
  clearUserPermit,
  newPermitToggle,
} from "../store/Slice/issuerPermitSlice";
import CreatePermitModel from "../utils/models/CreatPermitModel";
import { useNavigate } from "react-router-dom";
import config from "../config";

const IssuerPermit = () => {
  const [value, setValue] = React.useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { permitList, isShiftPerson } = useSelector((state) => state.permits);
  const {
    getPermitByIdSuccessData,
    createPermitToggle,
    departmentList,
    permitSuccess,
  } = useSelector((state) => state.issuerPermit);
  const user = useSelector((state) => state.signIn.adminData);
  const { getInitiatorDataSuccessForDashboard } = useSelector(
    (state) => state.formStepper
  );
  const [filterData, setFilterData] = useState("");
  const [searchText, setSearchText] = useState("");
  console.log(permitList, "permit");
  console.log(filterData, "..filter");

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleCreateIssuer = () => {
    dispatch(newPermitToggle());
  };
  const handleClose = () => {
    dispatch(newPermitToggle());
  };

  useEffect(() => {
    const filter = {
      filterBy: filterData,
      searchText: searchText,
    };
    dispatch({ type: GET_ALL_PERMIT, payload: filter });
  }, [filterData, searchText]);

  useEffect(() => {
    if (!departmentList) {
      dispatch({ type: GET_DEPARTMENT_REQUEST_FOR_ISSUER });
    }
  }, [departmentList]);

  useEffect(() => {
    if (getInitiatorDataSuccessForDashboard) {
      navigate(`/initiator/${getInitiatorDataSuccessForDashboard.id}`);
    }
  }, [getInitiatorDataSuccessForDashboard]);

  return (
    <div className="d-flex">
      <div>
        <Drawer />
      </div>
      <div className={"w-100"} style={{ background: "#F7F7F7" }}>
        <NavDashboard page={"Permit"} />
        <div className={"w-100"}>
          {user && user.roleId === config.role.issuerRole && (
            <div className={"container"}>
              {isShiftPerson && isShiftPerson && (
                <NewPermit>
                  <button onClick={handleCreateIssuer}>
                    <p>New Permit</p>
                    <span>+</span>
                  </button>
                </NewPermit>
              )}
            </div>
          )}
          <div className={"container"}>
            <DepartmentTabs
              className={`${
                user && user.roleId !== config.role.coOrdinatorAD ? "mt-2" : ""
              }`}
            >
              <Tabs
                className={"issuerTab"}
                defaultActiveKey="first"
                style={{ border: "none" }}
                onSelect={() => {
                  setFilterData("");
                  setSearchText("");
                }}
              >
                <Tab className={"mx-3"} eventKey="first" title="Permit Raised">
                  <PermitRaised className={"container"}>
                    <div className="row pb-3">
                      <div className="col-xl-3">
                        <form className="sc-llBfkT cJTYn">
                          <div>
                            <h4 className="me-3">Filter By</h4>
                          </div>
                          <div className="sc-ghUGWh bChEBR">
                            <select
                              className="sc-iIXitQ fKVQlV issuerPd"
                              name="permit"
                              id="cars"
                              value={filterData}
                              onChange={(e) => setFilterData(e.target.value)}
                            >
                              <option value="">All</option>
                              <option value="Requested">Requested</option>
                              {/* <option value="Approved">Approved</option> */}
                              {/* <option value="Rejected">Rejected</option> */}
                              <option value="In Progress">In Progress</option>
                            </select>
                          </div>
                        </form>
                      </div>
                      <div className="col-xl-8">
                        <div className="sc-HaPHK bWYIPa">
                          <input
                            type="text"
                            value={searchText}
                            placeholder="Search by Permit Number"
                            onChange={(e) => setSearchText(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <ParmitTable className={"row"}>
                      <div className="col-12 issuerTable user-table">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Permit Number</th>
                              <th scope="col">Status</th>
                              <th scope="col">
                                Authorised person name & Designation
                              </th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {permitList && permitList.length > 0 ? (
                              permitList.map((i) => {
                                if (
                                  i.status === "In Progress" ||
                                  i.status === "Requested"
                                ) {
                                  return (
                                    <tr
                                      className="content"
                                      key={i.id}
                                      id={i + 1}
                                    >
                                      <td>
                                        {i.permitNumber && i.permitNumber
                                          ? i.permitNumber
                                          : "-"}
                                      </td>
                                      <td>
                                        {i.status &&
                                          i.status === "Approved" && (
                                            <>
                                              <StatusPermit
                                                status={"#30DBB2"}
                                              />{" "}
                                              Approved
                                            </>
                                          )}
                                        {i.status &&
                                          i.status === "Rejected" && (
                                            <>
                                              <StatusPermit
                                                status={"#FF0000"}
                                              />{" "}
                                              Rejected
                                            </>
                                          )}
                                        {i.status &&
                                          i.status === "Requested" && (
                                            <>
                                              <StatusPermit
                                                status={"#FF8946"}
                                              />{" "}
                                              Requested
                                            </>
                                          )}
                                        {i.status &&
                                          i.status === "IssuerCompleted" && (
                                            <>
                                              <StatusPermit
                                                status={"#FF8946"}
                                              />{" "}
                                              Issuer Completed
                                            </>
                                          )}
                                        {i.status &&
                                          i.status === "CustodianApproved" && (
                                            <>
                                              <StatusPermit
                                                status={"#FF8946"}
                                              />{" "}
                                              Custodian Approved
                                            </>
                                          )}
                                        {i.status &&
                                          i.status === "CheckListCreated" && (
                                            <>
                                              <StatusPermit
                                                status={"#FF8946"}
                                              />{" "}
                                              CheckList Created
                                            </>
                                          )}
                                        {i.status &&
                                          i.status ===
                                            "SubmiteForCompleted" && (
                                            <>
                                              <StatusPermit
                                                status={"#FF8946"}
                                              />{" "}
                                              Submite For Completed
                                            </>
                                          )}
                                        {i.status &&
                                          i.status === "In Progress" && (
                                            <>
                                              <StatusPermit
                                                status={"#FF8946"}
                                              />{" "}
                                              In Progress
                                            </>
                                          )}
                                      </td>
                                      <td>
                                        {i.lastUpdatedBy && i.lastUpdatedBy
                                          ? i.lastUpdatedBy
                                          : "-"}
                                      </td>
                                      <td>
                                        <DetailsBtnPermit
                                          type="button"
                                          onClick={() => {
                                            // navigate(`/permit/${i.id}`)
                                            if (i.initiatorCreated) {
                                              dispatch({
                                                type: GET_INITIATOR_BYID,
                                                payload: i.id,
                                              });
                                              // dispatch({type: GET_PERMIT_BY_ID, payload: i.id})
                                            } else {
                                              dispatch({
                                                type: GET_PERMIT_BY_ID,
                                                payload: i.id,
                                              });
                                              navigate(`/permit/${i.id}`);
                                              // dispatch(clearPermitById());
                                              // if (!getPermitByIdSuccessData) {
                                              //     dispatch({
                                              //         type: GET_PERMIT_BY_ID,
                                              //         payload: i.id
                                              //     })
                                              // }
                                              // navigate(`/permit/${i.id}`)
                                            }
                                          }}
                                        >
                                          Details
                                        </DetailsBtnPermit>
                                      </td>
                                    </tr>
                                  );
                                }
                              })
                            ) : (
                              <tr>
                                <td
                                  colSpan="4"
                                  style={{ borderBottom: "none" }}
                                >
                                  No data Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </ParmitTable>
                  </PermitRaised>
                </Tab>
                <Tab eventKey="second" className={"mx-3"} title="Permit Worked">
                  <PermitRaised className={"container"}>
                    <div className={"row pb-3"}>
                      <div className={"col-xl-3"}>
                        <UserForm>
                          <div>
                            <h4 className={"me-3"}>Filter By</h4>
                          </div>
                          <SelectIssuer>
                            <SelectSearch
                              className={"issuerPd"}
                              value={filterData}
                              onChange={(e) => setFilterData(e.target.value)}
                              name="permit"
                              id="cars"
                            >
                              <option value="">All</option>
                              {/* <option value="Requested">Requested</option> */}
                              <option value="Approved">Approved</option>
                              <option value="Rejected">Rejected</option>
                              {/* <option value="In Progress">In Progress</option> */}
                            </SelectSearch>
                          </SelectIssuer>
                        </UserForm>
                      </div>
                      <div className={"col-xl-8"}>
                        <SearchBoxStyle>
                          <input
                            type="text"
                            value={searchText}
                            placeholder={"Search by Permit Number"}
                            onChange={(e) => setSearchText(e.target.value)}
                          />
                          {/*<div className={"search_icon_main"}>*/}
                          {/*    <SearchIcon/>*/}
                          {/*</div>*/}
                        </SearchBoxStyle>
                      </div>
                    </div>
                    <ParmitTable className={"row"}>
                      <div className="col-12 issuerTable user-table">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Permit Number</th>
                              <th scope="col">Status</th>
                              <th scope="col">
                                Authorised person name & Designation
                              </th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {permitList && permitList.length > 0 ? (
                              permitList.map((i) => {
                                if (
                                  i.status === "Approved" ||
                                  i.status === "Rejected"
                                ) {
                                  return (
                                    <tr
                                      key={i.id}
                                      className="content"
                                      id={i + 1}
                                    >
                                      <td>
                                        {i.permitNumber && i.permitNumber
                                          ? i.permitNumber
                                          : "-"}
                                      </td>
                                      <td>
                                        {i.status &&
                                          i.status === "Approved" && (
                                            <>
                                              <StatusPermit
                                                status={"#30DBB2"}
                                              />{" "}
                                              Approved
                                            </>
                                          )}
                                        {i.status &&
                                          i.status === "Rejected" && (
                                            <>
                                              <StatusPermit
                                                status={"#FF0000"}
                                              />{" "}
                                              Rejected
                                            </>
                                          )}
                                        {i.status &&
                                          i.status === "Requested" && (
                                            <>
                                              <StatusPermit
                                                status={"#FF8946"}
                                              />{" "}
                                              Requested
                                            </>
                                          )}
                                        {i.status &&
                                          i.status === "IssuerCompleted" && (
                                            <>
                                              <StatusPermit
                                                status={"#FF8946"}
                                              />{" "}
                                              Issuer Completed
                                            </>
                                          )}
                                        {i.status &&
                                          i.status === "CustodianApproved" && (
                                            <>
                                              <StatusPermit
                                                status={"#FF8946"}
                                              />{" "}
                                              Custodian Approved
                                            </>
                                          )}
                                        {i.status &&
                                          i.status === "CheckListCreated" && (
                                            <>
                                              <StatusPermit
                                                status={"#FF8946"}
                                              />{" "}
                                              CheckList Created
                                            </>
                                          )}
                                        {i.status &&
                                          i.status ===
                                            "SubmiteForCompleted" && (
                                            <>
                                              <StatusPermit
                                                status={"#FF8946"}
                                              />{" "}
                                              Submite For Completed
                                            </>
                                          )}
                                        {i.status &&
                                          i.status === "In Progress" && (
                                            <>
                                              <StatusPermit
                                                status={"#FF8946"}
                                              />{" "}
                                              In Progress
                                            </>
                                          )}
                                      </td>
                                      {/*<td>Mining</td>*/}
                                      {/*<td>{i.dateTime && i.dateTime ? i.dateTime : '-'}</td>*/}
                                      <td>
                                        {i.lastUpdatedBy && i.lastUpdatedBy
                                          ? i.lastUpdatedBy
                                          : "-"}
                                      </td>
                                      <td>
                                        <DetailsBtnPermit
                                          type="button"
                                          onClick={() => {
                                            dispatch({
                                              type: GET_PERMIT_BY_ID,
                                              payload: i.id,
                                            });
                                            navigate(`/permit/${i.id}`);
                                          }}
                                        >
                                          Details
                                        </DetailsBtnPermit>
                                      </td>
                                    </tr>
                                  );
                                }
                              })
                            ) : (
                              <tr>
                                <td
                                  colSpan="4"
                                  style={{ borderBottom: "none" }}
                                >
                                  No data Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </ParmitTable>
                  </PermitRaised>
                </Tab>
              </Tabs>
            </DepartmentTabs>
          </div>

          {createPermitToggle && (
            <CreatePermitModel
              show={createPermitToggle}
              onHide={handleClose}
              handleClose={handleClose}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default IssuerPermit;
